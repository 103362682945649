<template>    
  <div 
    class="select-wrapper relative flex"
    :class="{'dropdown-open': open}" 
    :style="`z-index: ${computedZIndex}`" 
  >
    <button
      @click="toggleDropdown(!open)"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      :class="[
        `${grow ? 'px-2 py-1 rounded-md' : 'w-8 h-8 rounded-full'} bg-transparent focus:outline-none transition ease-in-out duration-150`,
        {'text-purple-m-main hover:text-purple-m-secondary': theme === 'light'},
        {'hover:bg-tan-m': theme === 'light' && !noBgIcon},
        {'text-white hover:text-purple-m-secondary': theme === 'dark'},
        {'hover:bg-tan-m': theme === 'dark' && !noBgIcon},
        {'text-purple-m-main-light hover:text-purple-m-secondary': theme === 'cancel'},
        {'hover:bg-tan-m': theme === 'cancel' && !noBgIcon},
        {'text-purple-m-secondary': open},
        {'bg-tan-m': open && !noBgIcon}
      ]"
    >
      <div class="w-full h-full flex justify-center items-center">
          <template v-if="$slots.icon">
              <slot name="icon" />
              <base-icon 
                v-if="noBgIcon && !hideSecondaryIcon" 
                :name="noBgIcon" 
                :class="[
                  'text-white',
                  {'text-green-m-main': open || hovered}
                ]" 
              />
          </template>
          <template v-else>
              <base-icon name="dots-vertical" :size="4" />                
          </template>
      </div>
    </button>     
    <div 
      v-if="open"
      :class="[
        `${customOpenZIndex === 'unset' ? 'z-full' : 'z-skip-2'}`,
        'select-menu absolute rounded-lg bg-white shadow-lg',
        {'top-full': !top},
        {'right-6': !right}, 
        {'left-0': right},
        {'top-0': !top},
        {'bottom-full': top},
        customDropDown
      ]"
    >
      <div class="bg-white z-skip-2">   
          <template v-if="$slots.header">            
            <div class="block px-4 py-2 text-sm">
              <slot name="header" />
            </div>  
            <separator/>              
          </template>     

          <template v-if="options.length">
            <div v-for="(option, i) in options" :key="i" class="">
              <base-button 
                @action="selectOption(option)" 
                type="label" 
                theme="neutral" 
                rounded="none" 
                justify="start"
                class="w-full px-4 py-2 hover:bg-tan-m"
                :label="option.label"
                :disabled="!!option.disabled"
                v-tooltip="!!option.disabled ? (option.disabledTooltip ? option.disabledTooltip : null): null"
              />
              <!-- <separator v-if="option.separator"/> -->
            </div>
          </template>
          <template v-else>
            <div class="px-6 leading-10 py-4 text-sm text-gray-m-disable cursor-not-allowed text-center">
              There are no options available.
            </div>
          </template>          
      </div>      
    </div>      
  </div>    
</template>

<script>
export default {
  props:{
    actions: {
      type: [Object, Function],
      default: () => ({}),
    },
    options:{
      type: Array,
      default: function () {
        return [];
      }
    },
    right:{
        type: Boolean,
        default: false
    },
    top:{
        type: Boolean,
        default: false
    },
    grow: {
      type: Boolean,
      default: false
    },
    hideSecondaryIcon: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    customDropDown: {
      type: String,
    },
    customZIndex: {
      type: Number,
      default: 70
    },
    customOpenZIndex: {
      type: [Number, String]
    },
    noBgIcon: {
      type: String,
      default: ''
    },
    externalTrigger: {
      type: Boolean,
      default: false
    },
    externalStatus: {},
  },

  data() {
    return {
      open: false,
      hovered: false,
    };
  },

  computed: {
    computedZIndex() {
      return this.open ? this.customOpenZIndex || this.customZIndex : this.customZIndex;
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },

  watch: {
    open: function (val) {
        this.$emit('dropdown-status', val);
    },
    externalStatus: function (val) {
        if (val !== undefined) {
            this.open = val;
        }
    },
    externalTrigger: function (val) {
        this.open = val;
    }
  },

  methods: {
    toggleDropdown(state) {
      if (this.externalStatus !== undefined) {
          this.$emit('toggle-status');
      } else {
          this.open = state;
      }
    },

    close(e) {
      if (!this.$el.contains(e.target) && this.open) {
          this.toggleDropdown(false);
      }
    },

    selectOption(option) {
      this.open = false;
      if (this.actions && this.actions[option.event]) {
        this.actions[option.event]();
      } else {
        this.$emit(option.event);
      }
    }


  }
};
</script>

<style lang="scss">
  .select-wrapper {
    .select-menu {
      max-height: 14em;
      overflow-y: auto;
    }
  }

  .right--4 {
    right: -1rem;
  }
</style>