<template>
    <div class="lightbox-wrapper">
        <div 
            v-if="visible" 
            class="lightbox-overlay"
            @click.stop="$emit('close')"
        >
            <div class="lightbox-container">
                <div class="header">
                    <div class="space" />
                    <div class="title">{{ file.name }}</div>
                    <div 
                        class="close-icon"
                        @click.stop="$emit('close')"
                    >
                        <base-icon
                            :size="10" 
                            class="text-white" 
                            name="close" 
                        />
                    </div>
                </div>
                <div class="content-container">
                    <div @click.stop class="content">
                        <div class="empty-state" v-if="!file">File not found</div>
                        <div class="empty-state" v-else-if="!isValidString(file.url)">File src not found</div>
                        <img class="image" v-else :src="file.url" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { isValidString } from '@/common';
export default {
    props: ['visible', 'file'],
    data(){
        return {
            isValidString
        }
    }
}
</script>
<style lang="scss" scoped>
    .lightbox-wrapper{
        //
        .lightbox-overlay{
            @apply fixed inset-0;
            background-color: rgb(0 0 0 / 80%);
            z-index: 10;
            > .lightbox-container{
                @apply flex flex-col w-full h-full;
                > .header{
                    @apply flex-shrink-0 flex-grow-0;
                    @apply w-full p-6;
                    @apply flex justify-end items-center;
                    > .space{
                        height: 40px;
                        width: 40px;
                    }
                    > .title{
                        @apply w-full flex justify-center items-center;
                        @apply text-white;
                    }
                    > .close-icon{
                        @apply cursor-pointer transition-opacity duration-300;
                        &:hover{
                            @apply opacity-80;
                        }
                    }
                }
                > .content-container{
                    @apply flex-shrink flex-grow;
                    @apply w-full h-full;
                    @apply flex justify-center items-center; 
                    > .content{
                        @apply bg-white rounded-lg; 
                        // Header height = 93.6px
                        height: calc(80vh - 93.6px);
                        width: calc(80vh - 93.6px); // width will also be 80 viewport height as we want it to be a square
                        > .empty-state{
                            @apply w-full h-full;
                            @apply flex justify-center items-center;
                            @apply font-bold;
                        }
                        > .image{
                            @apply w-full h-full object-contain rounded-lg shadow;
                        }
                    }
                }
            }
        }
    }
</style>