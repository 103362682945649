import axios from "axios";

export default class ApiLogger {
  constructor() {
    this.logs = [];
  }

  addLog(log) {
    if (typeof log !== "string" && typeof log !== "object") {
      throw new Error("Logs must be either a string or an object.");
    }
    this.logs.push(log);
  }

  async sendLogs() {
    if (this.logs.length === 0) {
      console.warn("No logs to send.");
      return;
    }

    try {
      const { data } = await axios.post("/api/log", { logs: this.logs });

      if (data) {
        console.log("Logs sent successfully:", data);
      }

      // Clear logs after sending
      this.logs = [];
    } catch (error) {
      console.error("Error sending logs:", error);
    }
  }
}
  
// Usage example
// import ApiLogger from "./ApiLogger.js";

// const logger = new ApiLogger();

// // Add string logs
// logger.addLog("User logged in");
// logger.addLog("Error: Invalid input");

// // Add object logs
// logger.addLog({ event: "LOGIN", userId: 123 });
// logger.addLog({ error: "Validation failed", details: { field: "email" } });

// logger.sendLogs();