<template>
    <base-modal 
        title="Audience collection" 
        :visible="visible"
        @close="$emit('close')"
        styles="max-height: 80vh; overflow-y: auto; min-width: 60vw; width: 60vw;"
    >
        <div class="view-extracted-text">
            <div v-if="!isValidObject(media)">Media not found</div>
            <div v-else class="content">
                <div class="column">
                    <div class="title">Extracted text</div>
                    <div class="empty-state" v-if="!isValidString(media.ocr_text)">No extracted text yet</div>
                    <FormulateInput
                        placeholder="Extracted text..."
                        v-else
                        type="textarea"
                        :value="media.ocr_text"
                        :readonly="true"
                        :disabled="true"
                        class="w-full"
                        input-class="w-full m-aspect-square focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent"
                        :rows="5"
                    />
                </div>
                <div class="column">
                    <div class="title">Audience Insights</div>
                    <div class="empty-state" v-if="!isValidObject(media.parsed_data)">No audience insights yet.</div>
                    <div v-else>
                        {{ media.parsed_data }}
                    </div>
                </div>
            </div>

            <div class="footer">
                <div class="content">
                    <base-button
                        label="Close"
                        type="secondary"
                        @action="$emit('close')"
                    />
                </div>
            </div>

        
        </div>
    </base-modal>
</template>
<script>
    import { isValidString, isValidObject } from '@/common';
    export default {
        name: 'AudienceCollectionInvitationModal',
        props: ['visible', 'media'],
        data() {
            return {
                isValidObject,
                isValidString
            }
        }
    }
</script>
<style lang="scss" scoped>
    .view-extracted-text{
        @apply relative;
        > .content{
            @apply flex gap-x-4 justify-center;
            > .column{
                @apply w-1/2 flex flex-col gap-y-4;
                > .title{
                    @apply w-full text-center text-h4 font-bold;
                }
                > .empty-state{
                    @apply text-center;
                }
            }
        }
        > .footer{
            @apply sticky bottom-0;
            > .content{
                @apply w-full bg-tan-m;
                @apply py-6 mt-20;
                @apply flex justify-between items-center;
            }
        }
    }
</style>
