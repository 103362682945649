<template>
    <div id="app">
        <link href="https://unpkg.com/vue-select@latest/dist/vue-select.css" rel="stylesheet">
        <link href="https://cdn.jsdelivr.net/npm/vue-loading-overlay@4/dist/vue-loading.css" rel="stylesheet">
        <link href="https://cdn.jsdelivr.net/npm/sweetalert2@11.0.18/dist/sweetalert2.min.css" rel="stylesheet">
        
        <component :is="layout">
            
            <div class="global-loader" v-if="loading" @click="loading = false;">
                <img src="./assets/images/logo.svg" />
                <base-icon class="text-white" size="6" name="loader"/>
            </div>

            <template v-if="ready">

                <template 
                    v-if="tokenAccess.enabled && tokenAccess.accessGranted == false"
                >
                    <password-modal
                        :visible="tokenAccess.modalVisible"
                        @success="tokenAccess.accessGranted = true"
                    />
                </template>

                <router-view v-else />

            </template>

            <alert-dialog
                :isOpen="dialogProps.show"
                :title="dialogProps.title"
                :description="dialogProps.description"
                :onProceed="dialogProps.action"
            />

            <notifications 
                position="top center"
                width="420px"
            >
                <template slot="body" slot-scope="props">
                    <div 
                        :class='`mt-6 notification ${props.item.type} ${(props.item.data && props.item.data.onClick) ? `clickable` : ``}`'
                        @click="(props.item.data && props.item.data.onClick) ? props.item.data.onClick() : null"
                    >

                        <base-icon :size="6" :name="props.item.type === 'success' ? 'check-circle' : (props.item.type === 'error' ? 'x-circle-outline' : 'exclamation-circle')" />
                        
                        <div class="content">
                            <a class="title">
                                {{props.item.title}}
                            </a>
                            
                            <div class="text enriched-format" v-html="props.item.text"/>
                        </div>

                        <a class="close" @click.stop="(props.item.data && props.item.data.fn ? () => {props.close(); props.item.data.fn(0)} : props.close)" v-if="props.item.data && props.item.data.undo">
                            {{ props.item.data && props.item.data.undoLabel ? props.item.data.undoLabel : 'UNDO' }}
                        </a>

                        <a class="close" @click.stop="props.close" v-else>
                            <base-icon :size="4" name="x" />
                        </a>

                    </div>
                </template>
            </notifications>

        </component>

    </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import AlertDialog from './components/Dialog';
import "@fontsource/plus-jakarta-sans";
import BaseIcon from './components/BaseIcon.vue';
const defaultLayout = "default";

export default {
    components: {
        AlertDialog,
        BaseIcon
    },

    data() {
        return {
            ready: false,
            loading: false,
            tokenAccess: {
                enabled: false,
                accessGranted: false,
                modalVisible: false
            }
        }
    },

    beforeMount() {
        this.mountGDrive();
    },

    mounted(){

        // only on report-view and landing pages we wont check "/me"
        if (!['report-view', 'landing'].includes(this.$route.name)) {

            this.loading = true;
            // measure request time so we make sure that the global loader is 
            // seen correctly and does not look glitchy/stuttery if request is fast 
            let timeStart = new Date();
            this.$store.dispatch('auth/getMe').then(async () => {

                let user = localStorage.getItem('user')
                if (user !== null) {
                    try {

                        this.$store.dispatch('colors/getColors');
                        // "forceUpdate" parameter set to false so we dont load the statuses if they already exist
                        this.$store.dispatch('statuses/getStatuses', false);
                        this.$store.dispatch('boards/getBoards');
                        this.$store.dispatch('fb/getLoginStatus');

                        if(this.$route.meta.tokenAccess && this.isClient){
                            this.tokenAccess = {
                                enabled: true,
                                accessGranted: false,
                                modalVisible: true
                            }
                        }

                        // if route has requiresAuth, by default clients should not be able to see it
                        if(this.$route.meta.requiresAuth && this.isClient){
                            this.$router.push('/deliverables')
                        }
                    } catch (e) {
                        console.error('Failing getting core data', e);
                    }
                } else{

                    if(this.$route.meta.checkGuestToken){
                        await this.$store.dispatch('auth/getGuestMe')
                    }
                    
                    // if user is not logged in, and the route has tokenAccess, we show the password modal
                    if(this.$route.meta.tokenAccess){
                        await this.$store.dispatch('auth/getGuestMe')
                        this.tokenAccess = {
                            enabled: true,
                            accessGranted: false,
                            modalVisible: true
                        }
                    }

                    // if route has requiresAuth, by default guests should not be able to see it
                    if(this.$route.meta.requiresAuth && this.isGuest){
                        this.$router.push('/deliverables')
                    }

                }
                this.ready = true
            
            }).catch(err => {
                console.log('error while dispatching auth/getMe', err);
            }).finally(() => {
                // if request is slow (800ms+), hide the loader immediately
                // if request is fast (800ms-), wait 800ms to hide the loader
                let timeEnd = new Date();
                const diffTime = Math.abs(timeEnd - timeStart); 
                if (diffTime <= 800) {
                    setTimeout(() => {
                        this.loading = false;
                    }, 800);
                }else{
                    this.loading = false;
                }
            });
            this.$store.dispatch('company/getCompany');
        }else{
            this.ready = true
        }
    },

    created() {
        this.$http.interceptors.response.use(undefined, (err) => {
            return new Promise((/*resolve, reject*/) => {
                if (err && err.response && err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
                    if(err.response.data && err.response.data.logout) {
                        console.log('forcing logout...');
                        this.$notify({ title: 'Unauthorized', text: 'Invalid or expired access token, logging out...', type: 'warn' });
                        this.$store.dispatch('auth/setRedirect', this.$route.path);
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
                
                if (err && err.response && err.response.status === 403) {
                    console.log('Access forbidden');
                    if (this.$route.name === 'new-company') {
                        this.$router.push('/new-company/register');
                    }
                }

                if(err && err.response && err.response.status === 404 && err.response.data && err.response.data.error && err.response.data.error == 'company-not-found'){
                    console.error(err.response.data.message, this.$route.name)
                    if (!['login', 'new-company'].includes(this.$route.name)) {
                        this.$router.push(`/company-not-found`);
                    }
                }
                throw err;
            });
        });
    },

    computed: {
        ...mapGetters(['me', 'isGuest', 'isClient', 'dialogProps']),
        layout() {
            return (this.$route.meta.layout || defaultLayout) + "-layout";
        }
    },

    methods: {
        mountScripts(scripts = [], isAsync = false) {
          scripts.forEach(script => {
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
              tag = document.createElement('script');
              tag.setAttribute('src', script);
              tag.setAttribute('type', 'text/javascript');
              if (isAsync) {
                tag.setAttribute('async', true);
              }
              document.head.appendChild(tag);
            }
          });
        },
        mountGDrive() {
            const scripts = [
              'https://apis.google.com/js/api.js',
              'https://accounts.google.com/gsi/client'
            ];

            this.mountScripts(scripts);

            // let gDrive = document.createElement('script');
            // gDrive.setAttribute('type', 'text/javascript');
            // gDrive.setAttribute('src', 'https://apis.google.com/js/api.js');
            // document.head.appendChild(gDrive);
        }
    }
}
</script>

<style lang="scss">

* {
    font-family: "Plus Jakarta Sans";
}

.landing-font-size {
    font-size: 62.5%;
}

.landing-body {
    min-width: 320px;
    background: #0E092C;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.6;
    color: #fff;
    letter-spacing: 0.02em;
    overflow-y: hidden;
}

.breadcrumb a {
    @apply text-gray-500;

    &:hover {
        @apply text-gray-800;
    }
}

.formulate-input[data-classification=box] .formulate-input-wrapper{
    display:flex;align-items:center
}
.formulate-input[data-classification=box] .formulate-input-element{
    overflow:hidden;display:flex;align-items:center
}
.formulate-input[data-classification=box] .formulate-input-element input{
    position:absolute;left:-999px;opacity:0;pointer-events:none
}
.formulate-input[data-classification=box] .formulate-input-element-decorator{
    display:block;width:1em;height:1em;border-radius:.25em;border:1px solid #cecece;position:relative
}
.formulate-input[data-classification=box] .formulate-input-element-decorator:before{
    content:"";display:block;background-size:contain;background-position:100%;width:calc(100% - .125em);height:calc(100% - .125em);box-sizing:border-box;position:absolute;top:.0625em;left:.0625em
}
.formulate-input[data-classification=box] .formulate-input-element[data-type=radio] .formulate-input-element-decorator{
    border-radius:1em
}
.formulate-input[data-classification=box] .formulate-input-element[data-type=radio] .formulate-input-element-decorator:before{
    border-radius:1em;width:calc(100% - .25em);height:calc(100% - .25em);top:.125em;left:.125em
}
.formulate-input[data-classification=box] .formulate-input-element input[type=checkbox]:checked~.formulate-input-element-decorator{
    border-color:#1D173F;
    background-color:#1D173F
}
.formulate-input[data-classification=box] .formulate-input-element input[type=checkbox]:checked~.formulate-input-element-decorator:before{
    background-color:#41b883;-webkit-mask-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');mask-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>')
}
.formulate-input[data-classification=box] .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator{
    border-color:#1D173F
}
.formulate-input[data-classification=box] .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before{
    background-color:#1D173F
}
.formulate-input[data-classification=box] .formulate-input-element input:focus~.formulate-input-element-decorator{
    
}
.formulate-input[data-classification=box] .formulate-input-label--after{
    margin-left:1em
}
.formulate-input[data-classification=box] .formulate-input-label--before{
    margin-right:1em
}

.formulate-input[data-classification=file] .formulate-input-upload-area {
    width: 100%;
    position: relative;
    padding: 2em 0;
}

.formulate-input[data-classification=file] .formulate-input-upload-area input {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.formulate-input[data-classification=file] .formulate-input-upload-area-mask {
    border-radius: .4em;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px dashed #a8a8a8;
    z-index: 2;
}

.formulate-input[data-classification=file] .formulate-input-upload-area-mask:before {
    content: "";
    background-color: #a8a8a8;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    width: 2em;
    height: 2em;
    position: absolute;
    pointer-events: none;
}

.formulate-input[data-classification=file] .formulate-input-upload-area[data-has-files] input {
    display: none;
}

.formulate-input[data-classification=file] .formulate-files {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.formulate-input[data-classification=file] .formulate-files li {
    @apply block mb-2;
}

.formulate-input[data-classification=file] .formulate-files .formulate-file {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .3em;
    border: 1px solid #cecece;
    box-sizing: border-box;
    background-color: transparent;
    font-size: .9em;
    font-family: 'Roboto', sans-serif;    
    padding: .75em;
    display: block;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-name {
    padding-left: 1.5em;
    padding-right: 2em;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: auto;
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-remove {
    width: 1.25em;
    height: 1.25em;
    border-radius: 1em;
    border: 1px solid #a8a8a8;
    background-color: #a8a8a8;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
    -webkit-mask-size: .6em;
    mask-size: .6em;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    cursor: pointer;
    position: absolute;
    right: .75em;
    z-index: 1;
    transition: transform .25s;
}

.formulate-input label.required.label-inside:before {
    color: #c81e1e;
    content: '* ';
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-name:before {
    position: absolute;
    left: .7em;
    top: 50%;
    margin-top: -.7em;
    background-color: #a8a8a8;
    content: "";
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 1.25em;
    height: 1.25em;
    display: inline-block;
    margin-right: .5em;
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-name {
    padding-left: 1.5em;
    padding-right: 2em;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: auto;
}

.formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-group-repeatable-remove {
    position: absolute;
    display: block;
    top: 50px;
    width: 1.3em;
    height: 1.3em;
    background-color: #cecece;
    right: .85em;
    border-radius: 1.3em;
    cursor: pointer;
    transition: background-color .2s;
    overflow: hidden;
    text-indent: -1000px;
}

.formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-group-repeatable {
    padding: 1em 3em 0em 1em;
    position: relative;
}

.formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-grouping {
    margin: -1em -1em 0;
}

.formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-group-repeatable-remove:after, .formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-group-repeatable-remove:before {
    content: "";
    position: absolute;
    top: calc(50% - .1em);
    left: .325em;
    display: block;
    width: .65em;
    height: .2em;
    background-color: #fff;
    transform-origin: center center;
    transition: transform .25s;
}

.formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-group-repeatable-remove:after, .formulate-input[data-classification=group] [data-is-repeatable] .formulate-input-group-repeatable-remove:before {
    content: "";
    position: absolute;
    top: calc(50% - .1em);
    left: .325em;
    display: block;
    width: .65em;
    height: .2em;
    background-color: #fff;
    transform-origin: center center;
    transition: transform .25s;
}


.formulate-input[data-classification=select] div[data-type=select] {
    @apply relative;
}

.formulate-input[data-classification=select] div[data-type=select]:before {
    content: "";
    width: 0;
    height: 0;
    border-color: #cecece transparent transparent;
    border-style: solid;
    border-width: .3em .3em 0;
    top: 50%;
    margin-top: -.1em;
    right: 1em;
    position: absolute;
}

.formulate-input-element-prefixed::before {
    position: absolute; 
    top: 0px; 
    left: 2px; 
    content: '$';
}

.formulate-input.formulate-input input:not([type=email]):not(.not-uppercased-placeholder)::-webkit-input-placeholder,
input.uppercased-placeholder::-webkit-input-placeholder{
//   text-transform: capitalize;
}

.swal2-popup {
    font-size: 0.8rem !important;
    display: block !important;
}

.notification{

  padding: 10px 14px;
  margin: 0 36px 28px 0;
 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 14px;

  font-size: 12px;

  color: #ffffff;
  background: #44A4FC;
  border-left: 7px solid #187FE7;
  border-radius: 4px;

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);

  & .content {
    flex-grow: 1;
    flex-shrink: 1;
  }

  & .title {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  & .text {
     /*color: #717171;*/
     color: #000000;
      font-size: 16px;
     font-weight: 500;
  }

  & .close {
    cursor: pointer;
  }
 
  &.warn {
    background: #f7f4bb;
    border-left-color: #e6db1d;
    color: #e6db1d;
  }
 
  &.error {
    background: #f9c6cc;
    border-left-color: #e61d35;
    color: #e61d35;
  }
 
  &.success {
    background: #daf5e1;
    border-left-color: #35c958;
    color: #35c958;
  }

  &.info {
    .title, .text {
        color: white !important;
    }
  }

  &.clickable {
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover{
        opacity: 0.85;  
      }
  }


}

// override sweet alert styles
.swal2-popup{
    padding: 2.5rem 3.5rem !important;
    width: 32rem !important;
    max-width: 32rem !important;
}
.swal2-icon{
    margin-top: 0px !important;
}
.swal2-title{
    --text-opacity: 1 !important;
    color: #000000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
    line-height: 1.5rem !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
}
.swal2-html-container{
    font-weight: 500 !important;
    font-size: 16px !important;
    font-family: "Roboto", sans-serif !important;
    color: black !important;
}
.swal2-actions{
    margin-top: 32px !important;
    justify-content: space-between !important;
    &.justify-center {
        justify-content: center !important;
    }
}
.swal2-confirm{
    transition-duration: 150ms !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
    width: 9rem !important;
    --text-opacity: 1 !important;
    color: #ffffff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
    padding: 0.5rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    border-width: 1px !important;
    border-radius: 0.375rem !important;
    border-color: transparent !important;
    --bg-opacity: 1 !important;
    background-color: #000000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
    &:hover{
        --bg-opacity: 1 !important;
        background-color: #3c3c3c !important;
        background-color: rgba(60, 60, 60, var(--bg-opacity))!important;
    }
    &:focus{
        outline: none !important;
        box-shadow: none !important;
         --bg-opacity: 1 !important;
        background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
    }
}
.swal2-cancel{
    transition-duration: 150ms !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
    width: 6rem !important;
    --text-opacity: 1 !important;
    color: #000000 !important;
    color: rgba(0, 0, 0, var(--text-opacity))!important;
    padding: 0.5rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    border-width: 1px !important;
    border-radius: 0.375rem !important;
    --border-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
    --bg-opacity: 1 !important;
    background-color: #ffffff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
    &:hover{
        --bg-opacity: 1 !important;
        background-color: #e6e6e6 !important;
        background-color: rgba(230, 230, 230, var(--bg-opacity)) !important;
    }

    &.hidden {
        display: none !important;
    }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: white !important;
}

.filter.grayscale {
    filter: grayscale(100%);
}

input[type="date"] {
    position: relative;
}

input[type="date"]:after {
    content: " ";
    padding: 0 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

input[type="date"]::-webkit-clear-button {
    z-index: 1;
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
}

.swal-wide{
    width: 600px !important;
    min-width: 600px !important;
}

.swal2-popup.swal2-show.swal2-modal {
    @apply bg-tan-m rounded-2xl;
}

.swal2-title {
    font-weight: 900 !important;
}

.swal2-confirm {
    border-color: transparent !important;
    background-color: #70F880 !important;
    border-radius: 9999px !important;
    font-weight: 700 !important;
    justify-content: center !important;
    align-items: center !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
    border-radius: 9999px !important;
    font-size: 15px !important;
    line-height: 19px !important;
    border-width: 1px !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
    transition-duration: 150ms !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    display: flex !important;
    padding: 1rem !important;
    border-color: transparent !important;
    --text-opacity: 1 !important;
    color: #0E092C !important;
    color: rgba(14, 9, 44, var(--text-opacity)) !important;
    --bg-opacity: 1 !important;

    &:focus {
        outline: none !important;
        @apply shadow-outline-gray;
        background-color: #55c762 !important;
    }

    &:hover {
        background-color: #55c762 !important;
    }
}

.swal2-html-container {
    font-family: inherit !important;
}

.swal2-cancel {
    font-weight: 600 !important;
    justify-content: center !important;
    align-items: center !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
    border-radius: 9999px !important;
    font-size: 15px !important;
    line-height: 19px !important;
    border-width: 1px !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
    transition-duration: 150ms !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    display: flex !important;
    width: 9rem !important;
    padding: 1rem !important;
    --text-opacity: 1 !important;
    color: #0E092C !important;
    color: rgba(14, 9, 44, var(--text-opacity)) !important;
    --bg-opacity: 1 !important;
    background-color: #ffffff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
    border-color: transparent !important;

    &:focus {
        outline: none !important;
        @apply shadow-outline-gray;
        background-color: #55c762 !important;
    }

    &:hover {
        background-color: #55c762 !important;
    }
}

.swal2-container.swal2-center.swal2-backdrop-show {
    background-color: rgba(14, 9, 44, 0.9) !important;
}

.icon-bold svg path{
    stroke-width: 4px;
}

.vue-star-rating{
    .vue-star-rating{
        .vue-star-rating-star{
            display: flex;
        }
    }
}

.v-tooltip-black{
   @apply bg-black text-white rounded py-1 px-2 text-xs;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.transparent-track::-webkit-scrollbar-track {
    background: transparent !important;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scroll-dark {
    &::-webkit-scrollbar-track {
        background: #2a2a2a !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #555;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
}

.ql-container.ql-snow {
    height: 100px;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    background-color: white;
}

.min-ql-h .ql-container.ql-snow {
    min-height: 100px;
    height: 100%;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    background: #F8F7F1;
}

.ql-align-center {
    text-align: center;
}
.ql-align-left {
    text-align: left;
}
.ql-align-right {
    text-align: right;
}

.ql-editor {
    min-height: 100px !important;
}

.line-clamp-none {
    -webkit-line-clamp: unset;
}

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.text-shadow {
    text-shadow: 1px 1px 2px black;
}

.drop-shadow {
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));
}

.box-shadow {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 0.4);
}

.post-card .content.relative {
    @apply h-full;

    .media-viewer {
        @apply h-full;
    }
}

.post-card .footer.footer-gradient {
    background: linear-gradient(0deg, #00000070, transparent)
}

.vc-chrome {
    box-shadow: unset !important;
    border: 1px solid #d2d6dc;
}

.enriched-format {
    a, a:visited, a:hover {
        @apply font-bold underline;
        color: #42339F;
    }
}

@keyframes heartbeat
{
    0% {
        transform: scale(.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(.75);
    }
    100% {
        transform: scale(.75);
    }
}
.animated.heartbeat{
    animation: heartbeat 1s 4;
}

.global-loader{
    @apply fixed inset-0 bg-purple-m-main;
    @apply flex flex-col justify-center items-center gap-y-4;
    z-index: 11000;
    > img{
        width: 225px;
    }
}

.creatorsaurus-checkbox{
    // remove previous appearance to create a new one
    appearance: none;
    -webkit-appearance: none;

    // container's new appearance
    display: flex;
    align-content: center;
    justify-content: center;
    // font-size: 10px;
    // padding: 8px;
    border: 1px solid gray;
    border-radius: 5px;
    width: 18px;
    height: 18px;
}

// the checkmark
.creatorsaurus-checkbox::before {
    content: "";
    width: 12px;
    height: 12px;
    margin: 3px;
    clip-path: polygon(21% 40%, 21% 40%, 8% 54%, 25% 68%, 36% 77%, 47% 65%, 48% 64%, 64% 47%, 75% 35%, 93% 13%, 81% 4%, 36% 54%);
    // color of the check icon    
    background-color: #41b883;
    transform: scale(0);
}

// checked state for container
.creatorsaurus-checkbox:checked {
    background-color: #1D173F;
}

// checked state for checkmark
.creatorsaurus-checkbox:checked::before {
    transform: scale(1);
}

// hover state for container
.creatorsaurus-checkbox:hover {
    cursor: pointer;
}

// These classes are classes that were not found
// by tailwind so we build our custom classes
.m-aspect-square{
    aspect-ratio: 1 / 1;
}
</style>
