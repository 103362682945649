<template>
<div class="login-container">

    <div class="header">
        
        <img class="logo" src="../../assets/images/logo.svg">

        <template v-if="invitation && invitation.company">
            <h2 class="title">
                You were invited to join <span class="text-green-m-main">{{invitation.company.name}}</span> as {{invitation.role.display_name}}
            </h2>
            <h3 class="description">
                Sign in to accept the invitation
            </h3>
        </template>

        <h2 v-else class="title">
            <img v-if="companyLoading" class="w-5" src="../../assets/images/spinner.gif" />
            <template v-else>
                Sign in to <span class="text-green-m-main" v-if="!companyNotFound">{{ company.name }}</span><span v-else>your account</span>
            </template>
        </h2>

        <div class="host">
            <p>{{host}}</p>
        </div>

    </div>

    <div class="loader-container">
        <base-icon v-if="loading" name="loader" class="text-white" />
    </div>

    <div class="form-wrapper">
        <div style="form-container">

            <alert class="mb-8" type="danger" v-if="error">
                <template v-slot:icon>
                    <base-icon name="exclamation-2" />
                </template>
                {{ error }}
            </alert>

            <FormulateForm 
                class="flex flex-col items-center"
                name="login-form" 
                @submit="login"
                v-model="formValues"
            >
                <div class="flex w-full mb-6 justify-start" v-if="error && networkId !== null">
                    <base-button type="label" label="Try with another platform" icon="arrow-left" @action="networkId = null"/>
                </div>

                <FormulateInput
                    name="email"
                    type="email"
                    :readonly="loading || networkId !== null"
                    label="Email address"
                    validation="required|email"
                    validation-name="Email"
                    class="w-full"
                />

                <FormulateInput
                    v-if="networkId == null"
                    :readonly="loading"
                    name="password"
                    type="password"
                    label="Password"
                    validation="required"
                    validation-name="Password"
                    class="w-full mt-6"
                />


                <div class="password-options">

                    <div class="remember-me">
                        <input id="remember_me" type="checkbox" class="form-checkbox">
                        <label for="remember_me" class="label">
                            Remember me
                        </label>
                    </div>

                    <a class="forgot-password" @click.prevent="$router.push('/forgot-password')" href="#">
                        Forgot your password?
                    </a>
                
                </div>

                <base-button
                    @action="$formulate.submit('login-form')" 
                    :disabled="loading || companyLoading"
                    class="mt-6 w-full"
                    bold
                    submit
                    label="Sign in"
                />

                <facebook-login
                    v-if="!networkId"
                    label="Login with Facebook"
                    :disabled="loading"
                    @success="onFacebookLoginSuccess"
                />

                <google-login
                    v-if="!networkId"
                    label="Login with Google"
                    :disabled="loading"
                    @success="onGoogleLoginSuccess"
                    @gettingToken="changeGettingTokenStatus"
                />

                <!-- [deprecated] -->
                <!-- twitter login not being used right now -->
                <!-- 
                    <twitter-login
                        v-if="!networkId"
                        label="Login with Twitter"
                        :disabled="loading"
                        @success="onTwitterLoginSuccess"
                    /> 
                -->

                <tiktok-login
                    v-if="!networkId"
                    label="Login with Tiktok"
                    :disabled="loading"
                    @loading="setLoader"
                    @success="onTikTokLoginSuccess"
                    @error="handleError"
                />

            </FormulateForm>

        </div>
    </div>
    <company-select-modal 
        :visible="companySelectModalVisible && !gettingToken"
        @close="companySelectModalVisible = false"
        :userCompanies="userCompanies"
        :userCompaniesLoading="userCompaniesLoading"
        @onCompanySelect="onCompanySelect"
    /> 
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from '../../components/Alert';
import CompanySelectModal from './CompanySelectModal.vue'
import FacebookLogin from '../../components/FacebookLogin'
import GoogleLogin from '../../components/GoogleLogin.vue'
import TiktokLogin from '../../components/TiktokLogin.vue'
// [deprecated]
// twitter login not being used right now
// import TwitterLogin from '../../components/TwitterLogin.vue'
export default {
    components: {
        Alert,
        CompanySelectModal,
        FacebookLogin,
        GoogleLogin,
        TiktokLogin,
        // [deprecated]
        // twitter login not being used right now
        // TwitterLogin
    },
    data() {
        return {
            invitation: {},

            formValues:{
                email: '',
                password: ''
            },

            error: null,
            loading: false,
            gettingToken: false,

            networkId: null,
            profileId: null,
            accessToken: null,
            socialEmail: null,

            companySelectModalVisible: false,
            passphrase: 'google',
            developerKey: '',
            clientId: '',
            userCompanies: [],
            userCompaniesLoading: false
        }
    },

    beforeMount(){
        this.$store.dispatch('auth/logout');
        this.$setTitle('Login')
    },

    mounted(){       
        let token = this.$route.query.t
        if(token){
            this.getInvitation()
        }
    },

    computed: {
        ...mapGetters(['redirect', 'company', 'companyLoading', 'companyNotFound']),
        host(){
            return window.location.host
        }
    },

    methods: {
        changeGettingTokenStatus(status) {
            this.gettingToken = status;
        },
        
        setLoader(loading) {
            this.loading = loading;
        },

        onFacebookLoginSuccess(data){
            this.setSocialLogin(6, data, true)
        },

        onGoogleLoginSuccess(data) {
            this.setSocialLogin(10, data, true)
        },

        onTikTokLoginSuccess(data) {
            this.setSocialLogin(14, data, true)
        },
        
        // [deprecated]
        // twitter login not being used right now
        // onTwitterLoginSuccess(data) {
        //     this.setSocialLogin(7, data, true)
        // },

        handleError(e) {
            console.log(e);
            if (e.response && e.response.data) {
                this.error = e.response.data.message;
            } else {
                this.error = 'An unknown error occurred. Please try again.';
            }
        },

        setSocialLogin(networkId, data, login = false) {
            this.networkId = networkId;
            this.accessToken = data.accessToken
            this.profileId = data.userId;
            this.socialEmail = data.email;
            this.formValues.email = data.email;
            if (login) {
                this.login();
            }
        },

        async getInvitation() {
            try {
                const { data } = await this.$http.get(`/api/invite/${this.$route.query.t}`)
                if(data && data.company){
                    this.invitation = data
                }
            }
            catch(e) {
                console.log(e);
                this.networkId = null;
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            }
        },
        async getUserCompanies() {
            const { data } = await this.$http.get('/api/me/companies')
            this.userCompanies = data
        },
        async processLoginResponse(data) {
            if (data && data.access_token) {
                await this.$store.dispatch('auth/login', data);

                if(data.has_company_access === false){
                    this.getUserCompanies();
                    this.companySelectModalVisible = true;
                    return this.$store.dispatch('auth/logout');
                }

                // accept the invitation (if it exists) once the user is logged in
                // and do the login again to save the new company on the jwt
                if (this.invitation && this.invitation.company) {
                    await this.acceptInvitation();
                    this.invitation = null;
                    return this.login();
                }

                await this.$store.dispatch('auth/getMe');
                await this.$store.dispatch('colors/getColors');
                // "forceUpdate" parameter set to false so we dont load the statuses if they already exist
                await this.$store.dispatch('statuses/getStatuses', false);
                await this.$store.dispatch('boards/getBoards');
                await this.$store.dispatch('company/getCompany');
                await this.$store.dispatch('fb/getLoginStatus');

                // Here we could also dispatch the "fb/generateAudienceData" action for creators if they
                // have logged in with a social platform, however its better to just do that on the 
                // SocialLogin view and the LinkedAccounts section.
                // If we want to implement that we could do it like this
                // // Validate data.id and networkId, if they are set then dispatch the action
                // this.$store.dispatch('fb/generateAudienceData', { userId: data.id, networkId: this.networkId });
                
                if (this.redirect) {
                    const redirect = this.redirect;
                    if (this.redirect !== '') {
                        if (['/login', 'login'].includes(this.redirect)) {
                            // clients get redirected to deliverables
                            if (data.roles && data.roles.includes('client')) {
                                this.$router.push('/deliverables');
                            } else {
                                this.$router.push('/home');
                            }
                            this.$store.dispatch('auth/setRedirect', null);
                        } else {
                            this.$router.push(redirect);
                            this.$store.dispatch('auth/setRedirect', null);
                        }
                    }
                } else {
                    if (data.roles && data.roles.includes('client')) {
                        this.$router.push('/deliverables');
                    } else {
                        this.$router.push('/home');
                    }

                }
            }
        },
        async login() {
            try {
                this.loading = true
                this.error = null
                const { email, password } = this.formValues
                const { data } = await this.$http.post(`/api/auth/login`, { 
                    email, 
                    password,
                    ...(this.networkId ? { 
                        network_id: this.networkId, 
                        profile_id: this.profileId,
                        social_email: this.socialEmail,
                        access_token: this.accessToken
                    } : {})
                })
                await this.processLoginResponse(data);
            }
            catch(e) {
                this.networkId = null;
                if (e.response && e.response.data) {
                    if(e.response.data.message){
                        return this.error = e.response.data.message;
                    }
                }
                this.error = 'An unknown error occurred. Please try again.';
            } finally {
                this.loading = false
            }
        },
        async acceptInvitation(){
            try {
                const { data } = await this.$http.post(`/api/invite/${this.$route.query.t}/accept`)
                console.log(data)
            }
            catch(e){
                console.log(e);
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            }
        },
        onCompanySelect(slug){
            if (process.env.NODE_ENV === 'production') {
                if(window.location.host.includes("development")){
                    window.location.replace(`https://${slug}-dot-development-dot-glassy-tube-622.appspot.com`)
                }else{
                    window.location.replace(`https://${slug}.creatorsaurus.com`)
                }
            }else{
                window.location.replace(`http://${slug}.app.com:8080`)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .login-container{
        @apply min-h-screen bg-purple-m-main flex flex-col justify-center py-12;
        @media (min-width: 640px) {
            @apply px-6;
        }
        @media (min-width: 1024px) {
            @apply px-8;
        }
        > .header{
            @media (min-width: 640px) {
                @apply mx-auto w-full max-w-md;
            }
            .logo{
                @apply mx-auto h-12 w-auto;
            }
            .title{
                @apply mt-6 text-center text-2xl leading-9 font-extrabold text-white;
            }
            .description{
                @apply mt-1 text-center text-lg leading-9 font-extrabold text-white;
            }
            .host{
                @apply flex justify-center mt-2;
                > p{
                    @apply text-sm text-gray-m;
                }
            }
        }
    }
    .loader-container{
        @apply h-8 w-full flex justify-center items-center;
        min-height: 2rem;
    }
    .form-wrapper{
        @apply bg-white shadow py-8 px-4;
        @media (min-width: 640px) {
            @apply rounded-lg mx-auto w-full max-w-md px-10;
        }
        .form-container{
            width: 368px;
            min-width: 368px;
            max-width: 368px;
        }
    }
    
    .password-options{
        @apply mt-6 w-full flex items-center justify-between;
        .remember-me{
            @apply flex items-center;
            .form-checkbox{
                @apply h-4 w-4 text-gray-m-dark transition duration-150 ease-in-out;
            }
            .label{
                @apply ml-2 block text-sm leading-5 text-gray-900;
            }
        }
        .forgot-password{
            @apply text-sm leading-5 font-medium text-gray-m-dark transition-opacity; 
            &:hover{
                @apply opacity-75;
            }
            &:focus{
                @apply outline-none underline;
            }
        }
    }
</style>
