import axios from 'axios';
import { facebookLogin } from '../lib/facebook'
import { isValidObject, isValidString } from '../common';

export const fb = {
    namespaced: true,
    state: {
        status: [], // refers to the current connection to Facebook
        initialized: false, // refers to the current connection to Facebook
        login: {}, // checks db for social login records and validates the tokens
        loading: false,
        sdkError: false
    },
    mutations: {
        setStatus(state, val) {
            state.status = val;
        },
        setInitialized(state, val){
            state.initialized = val
        },
        setLogin(state, val){
            state.login = val
        },
        setLoading(state, val){
            state.loading = val
        },
        setSdkError(state, val){
            state.sdkError = val
        }
    },
    actions: {
        async setStatus({commit}, status){
            await commit('setStatus', status)
        },
        async setInitialized({commit}, value){
            await commit('setInitialized', value)
        },
        async setSdkError({commit}, value){
            await commit('setSdkError', value)
        },
        async getLoginStatus({commit} /*, value*/){
            await commit('setLoading', true)
            const { data } = await axios.get('/api/me/fb?validation=true&ig_account=true');
            if(data){
                const { is_valid, ig_account } = data
                data.error = !is_valid || !ig_account
                data.message = !is_valid ? 'You are not logged in via Facebook' : !ig_account ? 'No Instagram account connected' : ''
                data.action = !is_valid ? `In order to perform this action, you need to link your Facebook account` : !ig_account ? `Your connected Facebook account must be linked to an instagram business account in order to perform this action` : ''
                data.computedMessage = (action) => !is_valid ? `In order to ${action}, you need to link your Facebook account` : !ig_account ? `Your connected Facebook account must be linked to an instagram business account in order to ${action}` : ''
                await commit('setLogin', data)
            }else{
                await commit('setLogin', {
                    error: true,
                    message: 'You are not logged in via Facebook',
                    action: 'In order to perform this action, you need to link your Facebook account',
                    computedMessage: (action) => `In order to ${action}, you need to link your Facebook account`
                })
            }
            await commit('setLoading', false)
        },
        async simpleLogin(context, notify){

            await facebookLogin().then(async res => {
                const { userId, id, email, longLivedAccessToken } = res
                const identifier = userId || id
                await axios.post(`/api/auth/register/link-account`, {
                    network_id: 6, // fb
                    profile_id: identifier,
                    email,
                    access_token: longLivedAccessToken,
                }).then((/*response*/) => {
                    notify({ title: 'Success', text: `Account Linked Successfully`, type: 'success' });
                    context.dispatch("getLoginStatus");
                }).catch((err) => {
                    notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'error' });
                    console.log('link account error', err)
                })
       
            }).catch(err => {
                console.log('Facebook login error', err)
            })

        },
        // Will generate an audience data, if only the userId is set
        // it will generate all of the user's networks audience datas
        // But if the networkId is set it will only generate the
        // audiece data for that network
        async generateAudienceData(context, info){
            try{

                if(!isValidObject(info)) throw new Error('Invalid info object');

                // Be default we always get the top posts
                const { userId, networkId, forceUpdate = true, getTopPosts = true } = info;
                
                if(!userId) throw new Error('Invalid user ID');

                // default
                let route = '';
                let body = {};

                // if "networkId" is set we use the v2 which will
                // only generate the audience data for the 
                // selected "network"
                if(networkId){
                    route = '/users/generate-audience-data-v2';
                    body = {
                        userId,
                        networkId,
                        forceUpdate,
                        getTopPosts
                    }
                }else{
                    // No "networkId"
                    route = '/users/generate-audience-data';
                    body = {
                        userId,
                        // networkId not defined on endpoint
                        force_update: forceUpdate,
                        // getTopPosts not defined on endpoint
                    }
                }

                if(!isValidString(route)) throw new Error('Invalid route!');
                if(!isValidObject(body)) throw new Error('Invalid body!');
                
                const { data } = await axios.post(route, body);

                if(!data) throw new Error('Unable to generate audience data.');

                console.log('Audience data generated', data);

                return data;
            
            }catch(err){
                console.log('Dispatch "fb/generateAudienceData" error:', err);
                return null;
            }
        }
    }
};