<template>
    <base-modal 
        title="Create audience collection invitation" 
        :visible="visible"
        @close="close()"
        styles="max-height: 90vh; overflow-y: auto; min-width: 50vw; width: 50vw;"
    >
        <div class="create-audience-collection-invitation-modal-container">

            <div class="content">
                <div v-if="!dataReady">
                    User ID not found
                </div>
                <template v-else>
                    <tab :visible="step === 1">
                        <div class="step-1">

                            <div class="title">Send an invitation to the creator so that they can send a screenshot of their audience data.</div>

                            <div class="creator">
                                <avatar :user="user" />
                                <div class="name"> {{ user.first_name }} {{ user.last_name }} </div>
                            </div>

                            <base-icon name="loader" v-if="loading" />
                            <template v-else>
                                <div class="empty-state" v-if="!isValidArray(availableNetworks)">
                                    No available platforms
                                </div>
                                <template v-else>
                                    <div class="network-list-title">Select the platform for which you want the creator to send a screenshot of their audience data.</div>
                                    <div class="network-list">
                                        <div
                                            class="network-item" 
                                            v-for="(network, index) in availableNetworks" 
                                            :key="index"
                                            :class="{ 'selected': isSelected(network) }"
                                            @click="toggle(network)"
                                        >
                                            <div class="box">
                                                <div 
                                                    class="status"
                                                    :class="{ 'selected': isSelected(network) }"
                                                >
                                                    <base-icon v-if="isSelected(network)" name="check" />
                                                    <base-icon v-else size="4" name="plus" />
                                                </div>
                                                <base-icon size="8" :name="network.name"/>
                                            </div>
                                            <div class="name">
                                                {{ network.display_name }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </tab>
                    <tab :visible="step === 2">
                        <div class="step-2">

                            <div class="empty-state" v-if="!isValidObject(createdInvitation)">
                                Invitation not found
                            </div>
                            <template v-else>

                                <div class="title">The invitation has been created successfully, you can copy the link, send it by email or send an in-app notification to the creator.</div>

                                <div class="copy-link">
                                    <FormulateInput 
                                        :value="createdInvitation.url" 
                                        class="w-full"
                                        :disabled="loading"
                                        readonly
                                    />
                                    <base-button 
                                        label="Copy to clipboard"
                                        :disabled="!isValidString(createdInvitation.url) || loading"
                                        @action="copyToClipboard()"
                                        size="xl"
                                        icon="clipboard-copy"
                                        bold
                                    />
                                </div>
                                
                                <hr class="mb-6"/>

                                <FormulateInput
                                    type="checkbox"
                                    label="Send invite by email"
                                    v-model="sendEmail"
                                    :disabled="loading"
                                    class="w-full"
                                />
                                <FormulateInput
                                    type="checkbox"
                                    label="Send invite by in-app notification"
                                    v-model="sendNotification"
                                    :disabled="loading"
                                    class="w-full"
                                />

                                <FormulateInput
                                    label="Creator's email"
                                    v-model="user.email"
                                    :disabled="loading || !sendEmail"
                                    class="w-full mt-2"
                                    placeholder="example@mail.com"
                                    validation="email"
                                    :readonly="isValidString(user.email)"
                                />

                                <FormulateInput
                                    placeholder="Enter message..."
                                    type="textarea"
                                    v-model="message"
                                    :disabled="loading"
                                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                                    class="w-full mt-2"
                                    :rows="1"
                                />

                            </template>
                        </div>
                    </tab>
                    <tab :visible="step === 3">
                        <div class="step-3">
                            <base-icon class="text-green-m-main" :size="20" name="check-circle" />
                            <div class="title">Success!</div>
                            <div class="description">The invitation has been sent successfully, we will notify you when the creator has finished the submission process.</div>
                        </div>
                    </tab>
                </template>
            </div>

            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    :disabled="loading"
                    @action="close()"
                />
                <base-button
                    v-if="step == 1"
                    label="Create invite"
                    :disabled="!dataReady || !isValidObject(selectedNetwork) || loading"
                    @action="createInvite()"
                />
                <base-button
                    v-if="step == 2"
                    label="Send invite"
                    :disabled="loading || (!sendEmail && !sendNotification)"
                    @action="sendInvite()"
                />
                <base-button
                    v-if="step == 3"
                    label="Done"
                    :disabled="loading"
                    @action="close()"
                />
            </div>
           
        </div>
    </base-modal>
</template>

<script>
    import { isValidArray, isValidObject, isValidString } from '../common';
    import { getNetwork } from '../lib/network';
    import { notifyCatchError } from '@/common';
    export default {
        name: 'CreateAudienceCollectionInvitationModal',
        props: ['visible', 'user'],
        data() {
            return {
                step: 1,
                networkUsers: [],
                selectedNetwork: null,
                createdInvitation: null,
                sendEmail: true,
                sendNotification: true,
                message: '',
                
                loading: false,

                isValidArray,
                isValidObject,
                isValidString
            }
        },
        watch: {
            visible(val, old){
                if(val == true && val !== old){
                    this.getNetworks();
                }
            }
        },
        computed: {
            dataReady(){
                return this.user && this.user.id;
            },
            availableNetworks(){
                
                // remove networks where network_id is not defined or if we are not able to find the network object
                const networks = this.networkUsers.filter(element => {
                    return element.network_id && isValidObject(getNetwork(element.network_id)); 
                }).map(element => {
                    return getNetwork(element.network_id);
                });

                // prevent duplicates
                let _networks = [];
                for (const network of networks) {
                    const found = _networks.find(element => element.id == network.id);
                    if(!found){
                        _networks.push(network);
                    } 
                }

                return _networks;
            }
        },
        methods: {
            createInvite(){
                this.loading = true;
                this.$http.post('/api/audience-collection-invitations', {
                    user_id: this.user.id,  
                    network_id: this.selectedNetwork.id
                }).then(({ data }) => {
                    if(isValidObject(data) && isValidString(data.token)){
                        this.createdInvitation = data;
                        this.createdInvitation.url = `${window.location.origin}/audience-collection?t=${data.token}`;
                        this.step = 2;
                        this.$notify({ title: 'Success', text: 'Invitation created successfully', type: 'success' });
                        this.$emit('created');
                    }else{
                        throw new Error("An error ocurred while creating the invitation, please try again later");
                    }
                }).catch((err) => {
                    notifyCatchError(err, this.$notify, 'An error ocurred while creating the invitation, please try again later');
                }).finally(() => {
                    this.loading = false;
                });
            },
            sendInvite(){
                this.loading = true;
                this.$http.post('/api/audience-collection-invitations/send', {
                    invitation_id: this.createdInvitation.id,
                    send_email: this.sendEmail,
                    send_notification: this.sendNotification,
                    message: this.message
                }).then(({ data }) => {
                    if(data){
                        this.$notify({ title: 'Success', text: 'Invitation sent successfully', type: 'success' });
                        this.step = 3;
                    }
                }).catch((err) => {
                    notifyCatchError(err, this.$notify, 'An error ocurred while sending the invitation, please try again later');
                }).finally(() => {
                    this.loading = false;
                });
            },
            getNetworks(){
                this.loading = true;
                this.$http.get(`/api/network-user/user/${this.user.id}`).then(({ data }) => {
                    this.networkUsers = data;
                }).catch((err) => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            },
            toggle(network){
                if(this.isSelected(network)){
                    return this.selectedNetwork = null;
                }
                return this.selectedNetwork = network; 
            },
            isSelected(network){
                return this.selectedNetwork ? this.selectedNetwork.id == network.id : false;
            },
            copyToClipboard(){
                this.$copyToClipboard(this.$notify, this.createdInvitation.url);
            },
            close(){
                this.step = 1;
                this.networkUsers = [];
                this.selectedNetwork = null;
                this.createdInvitation = null;
                this.sendEmail = true;
                this.sendNotification = true;
                this.message = '';
                
                this.loading = false;

                this.$emit('close');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .create-audience-collection-invitation-modal-container{
        > .content{
            .step-1{
                @apply flex flex-col gap-y-4;
                .title{
                    @apply font-bold text-h5;
                }
                .creator{
                    @apply flex gap-x-2 items-center;
                    > .name{
                        @apply font-bold;
                    }
                }
                .empty-state{
                    @apply font-bold;
                }
                .network-list-title{
                    @apply font-bold mb-4;
                }
                .network-list{
                    @apply flex flex-row flex-wrap justify-start gap-x-6;
                    .network-item{
                        @apply flex flex-col gap-y-4 justify-start items-center;
                        @apply cursor-pointer;
                        .box{
                            @apply relative;
                            @apply flex justify-center items-center;
                            @apply border border-2 border-gray-300 rounded-lg;
                            @apply transition-colors duration-200;
                            width: 100px;
                            height: 100px;
                            .status{
                                @apply absolute -top-4 -right-4;
                                @apply w-8 h-8 rounded-full border border-gray-400 bg-white;
                                @apply flex justify-center items-center;
                                @apply transition-colors duration-200;
                                &.selected{
                                    @apply bg-green-m-main;
                                }
                            }
                        }
                        &:hover{
                            .box{
                                @apply bg-gray-100;
                            }
                        }
                        &.selected{
                            .box{
                                @apply bg-purple-m-main text-white;
                            }
                        }
                        .name{
                            @apply flex justify-center items-center;
                            @apply font-bold;
                        }
                    }
                }
            }
            .step-2{
                > .empty-state{
                    @apply text-h5;
                }
                > .title{
                    @apply font-bold text-h5;
                }
                > .copy-link{
                    @apply flex justify-between gap-x-5 my-5;
                }
            }
            .step-3{
                @apply flex flex-col gap-y-4 items-center;
                > .title{
                    @apply text-h3 font-bold text-center;
                }
                > .description{
                    @apply text-center;
                }
            }
        }
    }
    .footer{
        @apply w-full mt-20 flex justify-between items-center;
    }
</style>
