<template>
    <base-modal 
        title="Audience collection" 
        :visible="visible"
        @close="$emit('close')"
        styles="max-height: 90vh; overflow-y: auto; min-width: 60vw; width: 60vw;"
    >
        <div class="audience-collection">

            <div v-if="!isValidObject(invitation)">Invitation not found</div>
            <div v-else-if="!isValidArray(invitation.media)">This invitation has no media</div>
            <div v-else class="media-list">
                <div
                    class="media-item"
                    v-for="(media, index) in invitation.media"
                    :key="index" 
                >
                    
                    <div class="empty-state" v-if="!isValidObject(media.attachment)">Attachment not found !</div>

                    <template v-else>

                        <div 
                            class="content"
                            @click="toggleAttachment(media.attachment)"
                        >

                            <!-- {{  media.id  }} -->
                            
                            <!-- icon -->
                            <div class="left">
                                <!-- <base-icon v-if="loading" name="loader" :size="5" /> -->
                                <!-- v-else -->
                                <base-icon class="text-purple-m-main" name="document" :size="5" />
                            </div>
                            <!-- right -->
                            <div class="right">
                                <div class="info">
                                    <div class="name">{{media.attachment.name}}</div>
                                    <div class="size" v-if="media.attachment.size">{{formatBytes(media.attachment.size)}}</div>
                                </div>
                                <base-icon
                                    :name="isSelected(media.attachment) ? 'chevron-up' : 'chevron-down'"
                                />
                            </div>
                            
                        </div>
                        
                        <div class="preview" v-if="isSelected(media.attachment)">
                            <div class="header">
                                <base-button
                                    label="View extracted text"
                                    bold
                                    size="lg"
                                    @action="viewExtractedText(media)"
                                />
                                <base-button
                                    label="Open in new tab"
                                    bold
                                    size="lg"
                                    @action="openInNewTab(media.attachment)"
                                />
                            </div>
                            <div class="content">

                                <div class="column">
                                    <!-- <div class="title">Image</div> -->
                                    <div class="empty-state" v-if="!media || !media.attachment || !isValidString(media.attachment.signed_url)">Image not found</div>
                                    <img 
                                        class="image"
                                        @click="onImageClick(media.attachment)" 
                                        v-else 
                                        :src="media.attachment.signed_url" 
                                    />
                                </div>
                            </div>
                        </div>

                        <lightbox
                            :visible="lightboxVisible"
                            :file="lightboxFile"
                            @close="onLightboxClose()"
                        />

                    </template>
                </div>
            </div>

            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    @action="$emit('close')"
                />
            </div>

            <view-extracted-text-modal
                :visible="viewExtractedTextModalVisible"
                :media="viewExtractedTextMedia"
                @close="viewExtractedTextModalVisible = false"
            />

        </div>
    </base-modal>
</template>
<script>
    import Lightbox from './Lightbox.vue';
    import ViewExtractedTextModal from './ViewExtractedTextModal.vue';
    import formatBytes from '@/lib/files';
    import { isValidArray, isValidString, isValidObject } from '@/common';
    export default {
        name: 'AudienceCollectionInvitationModal',
        props: ['visible', 'invitation'],
        components: {
            Lightbox,
            ViewExtractedTextModal
        },
        data() {
            return {
                selectedAttachment: null,
                
                lightboxVisible: false,
                lightboxFile: null,

                viewExtractedTextMedia: null,
                viewExtractedTextModalVisible: false,

                formatBytes,
                isValidArray,
                isValidObject,
                isValidString
            }
        },
        methods: {
            toggleAttachment(attachment){
                if(this.isSelected(attachment)){
                    return this.selectedAttachment = null;
                }
                this.selectedAttachment = attachment.guid;
            },
            isSelected(attachment){
                return this.selectedAttachment == attachment.guid;
            },
            onLightboxClose(){
                this.lightboxFile = null;
                this.lightboxVisible = false;
            },
            onImageClick(attachment){
                this.lightboxFile = {
                    url: attachment.signed_url,
                    name: attachment.name
                };
                this.lightboxVisible = true;
            },
            openInNewTab(attachment){
                const route = attachment.signed_url;
                window.open(route, '_blank'); 
            },
            viewExtractedText(media){
                this.viewExtractedTextMedia = media;
                this.viewExtractedTextModalVisible = true;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .audience-collection{
        > .media-list{
            @apply w-full;
            @apply flex flex-col gap-y-4;
            > .media-item{
                @apply w-full;
                @apply border border-gray-300 rounded-lg;
                > .content{
                    @apply py-3 px-4;
                    @apply flex items-center gap-x-4;
                    @apply cursor-pointer transition-opacity duration-300;
                    &:hover{
                        @apply opacity-50;
                    }
                    > .left{
                        @apply flex-grow-0 flex-shrink-0;
                        @apply flex justify-center items-center;
                    }
                    > .right{
                        @apply flex-grow flex-shrink;
                        @apply flex justify-between items-center;
                        > .info{
                            @apply flex flex-col;
                            > .name{
                                @apply font-bold;
                            }
                            > .size{
                                @apply text-pxs;
                            }
                        }
                    }
                }
                > .preview{
                    @apply mt-3;
                    @apply py-3 px-4;
                    > .header{
                        @apply w-full flex justify-end items-center gap-x-8 py-6;
                    } 
                    > .content{
                        @apply flex gap-x-4 justify-center;
                        > .column{
                            @apply w-2/3 flex flex-col gap-y-4;
                            > .title{
                                @apply w-full text-center text-h4 font-bold;
                            }
                            > .image{
                                @apply w-full object-contain p-4;
                                @apply border border-gray-500 rounded-lg;
                                cursor: zoom-in;
                                aspect-ratio: 1 / 1;
                            }
                            > .empty-state{
                                @apply text-center;
                            }
                        }
                    }
                }
                
            }
        }
    }
    .footer{
        @apply w-full mt-20 flex justify-between items-center;
    }
</style>
