<template>
    <div class="facebook-login-button-wrapper">
        <!-- button will only appear when the facebook library is successfully initialized -->
        <base-button
            v-if="fb.initialized"
            @action="handleAction()"
            icon="facebook"
            icon-color="#3B5998"
            :class="classes"
            :label="label"
            bold
            :disabled="disabled || loggingIn || !fb.initialized"
            type="secondary"
            theme="dark"
            :border="true"
        />

        <!-- "logging in" loader -->
        <div class="logging-in-loader" v-if="loggingIn">
            <base-icon name="loader" />
        </div>

        <!-- change handle button -->
        <div
            class="change-handle-button"
            :class="{ 'disabled': disabled || loggingIn }" 
            v-if="isSocialLoginView && isValidString(handle)"
            @click="(disabled || loggingIn) ? null : requestHandleChange()"
        >
            Is <b>{{ handle }}</b> not your handle ?
        </div>

        <!-- modals section -->
        <no-account-modal
            :visible="noAccountModalVisible"
            :handle="handle"
            :current-connection-info="currentConnectionInfo"
            @close="noAccountModalVisible = false"
            @reconnect="reconnect()"
            @request-handle-change="requestHandleChange()"
            @continue-without-access="continueWithoutAccess()"
        />

        <account-mismatch-modal
            :visible="accountMismatchModalVisible"
            :handle="handle"
            :current-connection-info="currentConnectionInfo"
            @close="accountMismatchModalVisible = false"
            @reconnect="reconnect()"
            @request-handle-change="requestHandleChange()"
            @continue-without-access="continueWithoutAccess()"
        />

        <request-handle-change-modal
            :visible="requestHandleChangeModalVisible"
            :handle="handle"
            @close="requestHandleChangeModalVisible = false"
        />

    </div>
</template>
<script>
import { isValidArray, isValidObject, isValidString } from '@/common';
import { checkFacebookStatus, facebookLogin, requestInstagramPermissions } from '@/lib/facebook';
import NoAccountModal from './NoAccountModal.vue';
import AccountMismatchModal from './AccountMismatchModal.vue';
import RequestHandleChangeModal from './RequestHandleChangeModal.vue';
import ApiLogger from '../../lib/apiLogger';
import { mapGetters } from 'vuex';
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Sign in with Facebook'
        },
        // Defines what action will be executed when the button is clicked
        // can be "login" || "request-instagram-permissions"
        action: {
            type: String,
            default: 'login'
        },
        classes: {
            type: String,
            require: false,
            default: 'mt-6 w-full'
        },
        handle: {
            type: String,
            required: false,
            default: null 
        }
    },

    components: {
        NoAccountModal,
        AccountMismatchModal,
        RequestHandleChangeModal
    },

    data(){
        return {
            currentConnectionInfo: null,
            
            noAccountModalVisible: false,
            accountMismatchModalVisible: false,
            requestHandleChangeModalVisible: false,

            loggingIn: false,
            
            isValidString
        }
    },

    watch: {
        // [deprecated]
        // Currently not using this watcher
        // '$store.state.fb.status': {
        //     deep: true,
        //     handler: function (val, old) {
        //         if(val != old){
        //             // console.log('FB status changed to: ', val)
        //         }
        //     }
        // },

        // Watches when facebook is initialized to set the current status for the client on the vuex store.
        // When user is connected, state would be something like: { "authResponse": null, "status": "unknown", "loginSource": "facebook" } 
        '$store.state.fb.initialized': {
            handler: function (val, old) {
                if(val != old && val == true){
                    // console.log('Facebook initialized', val);
                    checkFacebookStatus();
                }
            }
        }
    },

    computed: {
        ...mapGetters(['fb']),
        isSocialLoginView(){
            return isValidObject(this.$route) && isValidString(this.$route.name) && this.$route.name == 'social-login';
        }
    },

    methods: {
        handleAction(){
            if(this.action === 'request-instagram-permissions'){
                // Executes "window.FB.login" function with the following scopes:
                // email,public_profile,business_management,pages_show_list,instagram_basic,instagram_manage_insights,instagram_manage_comments,pages_read_engagement
                this.requestIgPermissions();
            }else{
                // Executes "window.FB.login" function with the following scopes:
                // instagram_basic, instagram_manage_insights
                this.login();
            }
        },
        // Executes "window.FB.login" function with the following scopes:
        // email,public_profile,business_management,pages_show_list,instagram_basic,instagram_manage_insights,instagram_manage_comments,pages_read_engagement
        login(){

            // We will log this process on the API
            const logger = new ApiLogger();

            this.loggingIn = true;
            
            if(isValidString(this.handle)){
                logger.addLog(`[connections]: A creator has initialized the FB login flow for the handle: ${this.handle}`);
            }else{
                logger.addLog("[connections]: A user has initialized the FB login flow");
            }

            facebookLogin().then(async res => {
                
                logger.addLog("[connections]: Finished connection flow.");

                const { object, accounts, areAccountsValid } = this.formatFacebookResponse(res);

                logger.addLog(object);

                // We save this info for when we use the "<no-account-modal/>" 
                this.currentConnectionInfo = { object, accounts, areAccountsValid };
                if(areAccountsValid){
                    // if there is no target handle or there are no valid instagram accounts, we return true 
                    const hasTargetHandle = this.hasTargetHandle(accounts);
                    if(hasTargetHandle){
                        this.$emit('success', object);
                    }else{
                        // has accounts but they do not match the target handle
                        this.accountMismatchModalVisible = true;
                    }
                }else{
                    this.noAccountModalVisible = true;    
                }
            }).catch(err => {

                logger.addLog("[connections]: An error ocurred while connecting");
                logger.addLog(err);

                console.log('Facebook login error', err);
                
                this.$notify({ 
                    title: 'Facebook Login canceled', 
                    text: `Click the login button below to try again, or contact support if you need help.`, 
                    type: 'warn', 
                    duration: 7500 
                });

            }).finally(async () => {
                this.loggingIn = false;
                logger.addLog("[connections]: Finished connection flow.");
                await logger.sendLogs();
            });
        },
        // Executes "window.FB.login" function with the following scopes:
        // instagram_basic, instagram_manage_insights
        requestIgPermissions(){
            this.loggingIn = true;
            requestInstagramPermissions().then(res => {
                const { object, accounts, areAccountsValid } = this.formatFacebookResponse(res);
                // We save this info in case we need it
                this.currentConnectionInfo = { object, accounts, areAccountsValid };
                // TODO maybe here we could execute "checkPermissions"
                // ...
                this.$emit('success', object);
            }).catch(err => {
                console.log('Request Instagram permissions error', err);
                this.$notify({ 
                    title: 'Facebook Login canceled', 
                    text: `Click the login button below to try again, or contact support if you need help.`, 
                    type: 'warn', 
                    duration: 7500 
                });
            }).finally(() => {
                this.loggingIn = false;
            });
        },

        formatFacebookResponse(res){
            // object
            const { email, first_name, last_name, picture, userId, id, permissions, accounts, /*accessToken,*/ longLivedAccessToken } = res;
            return {
                object: {
                    email,
                    first_name,
                    last_name,
                    picture: picture.data.url,
                    userId: userId || id,
                    accessToken: longLivedAccessToken,
                    networkId: 6,
                    permissions,
                    accounts: this.mapAccounts(accounts)
                },
                accounts: this.mapAccounts(accounts),
                areAccountsValid: this.validateInstagramAccounts(accounts)
            };
        },
        // Simplifies the accounts array so it only has the handles. Ex:
        // [ { data: { instagram_business_account: { username: 'handle' } }, paging: {} } ] => ['handle']
        mapAccounts(accounts){
            if(!accounts || !isValidArray(accounts.data)) return [];
            // Filter out accounts that dont have "instagram_business_account" or "username" objects
            return accounts.data.filter(account => {
                return account.instagram_business_account && account.instagram_business_account.username;
            }).map(element => {
                return element.instagram_business_account.username;
            });
        },
        // Searches the accounts array for an account that has the "instagram_business_account", returns true or false
        validateInstagramAccounts(accounts){
            let found = false;
            if(isValidObject(accounts) && isValidArray(accounts.data)){
                for (const account of accounts.data) {
                    if(account.instagram_business_account){
                        found = true;
                    }
                }
            }
            return found;
        },

        // Match connected instagram accounts with the target instagram handle. If there is no match, shows a message.
        hasTargetHandle(accounts){
            // if there is no target handle or there are no valid instagram accounts, we return true 
            if(!isValidString(this.handle) || !isValidArray(accounts)) return true;
            let found = false;
            for (const account of accounts) {  
                if(account === this.handle){
                    found = true;
                }
            }
            return found;
        },

        // [actions]
        reconnect(){
            this.currentConnectionInfo = null;
            this.noAccountModalVisible = false;
            this.accountMismatchModalVisible = false;
            this.handleAction();
        },

        requestHandleChange(){
            this.noAccountModalVisible = false;
            this.accountMismatchModalVisible = false;
            this.requestHandleChangeModalVisible = true;
        },
        
        // We are currently only using this function when we 
        // are not on the social login view. When we ARE in
        // the social login view we dont want creators to be
        // able to continue the flow without connecting the 
        // right page.
        continueWithoutAccess(){
            this.noAccountModalVisible = false;
            this.accountMismatchModalVisible = false;
            this.$emit('success', this.currentConnectionInfo.object);
        }

        // [deprecated]
        // Currently not using this method
        // checkPermissions(){
            // check if they declined a permission, we can check this later because the promt
            // will always ask if there are missing permisions, what we do have to check
            // is if they connected an instagram account.
            // if(permissions){
            //     let igBasic = permissions.find(element => {
            //         return element.permission == 'instagram_basic'
            //     })
            //     let igManageInsights = permissions.find(element => {
            //         return element.permission == 'instagram_manage_insights'
            //     })
            //     if((!igBasic || !igManageInsights) || (igBasic.status === 'declined' || igManageInsights.status === 'declined')){
            //         // cta to log in again, or dismiss and continue
            //     }
            // }
        // }
    }
}
</script>
<style lang="scss" scoped>
    .facebook-login-button-wrapper{
        @apply w-full;
        > .logging-in-loader{
            @apply w-full flex justify-center;
        }
        > .change-handle-button{
            @apply w-full cursor-pointer rounded-lg mt-3 text-center transition-opacity duration-300;
            &:hover{
                @apply opacity-50;
            }
            &.disabled{
                @apply text-gray-400 cursor-not-allowed; 
            }
        }
    }
</style>
