<template>
    <div class="wrapper">
        
        <div class="loader" v-if="loading">
            <base-icon size="8" name="loader" />
            <div class="title">Generating audience data...</div>
            <div class="subtitle">Please wait</div>
        </div>

        <template v-else>

            <div class="header">
                <tabs
                    :tabs="[{ name: 'Stats', active: true }, { name: 'Collection requests', active: true }]"
                    v-model="tab"
                />
            </div>

            <div class="content">
                
                <tab :visible="tab == 'Stats'">

                    <!-- actions header -->
                    <!-- Will show if the user has audience data, TikTok or IG -->
                    <div 
                        class="actions"
                        v-if="hasAudienceData()"
                    >
                        <base-button
                            v-if="hasIgAudienceData"
                            label="Refresh Instagram"
                            size="xlg"
                            class="font-bold"
                            :disabled="loading"
                            @action="generateAudienceData(2)"
                        />
                        <base-button
                            v-if="hasTikTokAudienceData"
                            label="Refresh TikTok"
                            size="xlg"
                            class="font-bold"
                            :disabled="loading"
                            @action="generateAudienceData(14)"
                        />
                    </div>

                    <!-- content -->
                    <report-user-card-stats
                        v-if="hasAudienceData()" 
                        :user="user"
                        :is-admin="isAdminOrHigher"
                    />
                    
                    <!-- does not have audience data -->
                    <div v-if="!hasAudienceData()">

                        <!-- if has ig network but no social login invite to IG -->
                        <div class="empty-state" v-if="hasIgNetworkUser && !hasIgSocialLogin">
                            Invite the user to log in with instagram to get audience data.
                        </div>

                        <!-- if has tiktok network but no social login invite to TikTok -->
                        <div class="empty-state" v-if="hasTikTokNetworkUser && !hasTikTokSocialLogin">
                            Invite the user to log in with tiktok to get audience data.
                        </div>

                        <div class="empty-state" v-else>
                            <div class="title">No audience data yet.</div>
                            <base-button
                                label="Generate IG data"
                                size="xlg"
                                class="font-bold"
                                :disabled="loading"
                                @action="generateAudienceData(2)"
                            />
                            <base-button
                                label="Generate TikTok data"
                                size="xlg"
                                class="font-bold"
                                :disabled="loading"
                                @action="generateAudienceData(14)"
                            />
                        </div>
                        
                    </div>
                </tab>
                
                <tab :visible="tab == 'Collection requests'">
                    <!-- actions header -->
                    <div class="actions">
                        <base-button
                            label="Request screenshot"
                            size="xlg"
                            class="font-bold"
                            :disabled="loading"
                            @action="createAudienceCollectionInvitationModalVisible = true"
                        />
                    </div>
                    <!-- content -->
                    <div class="invitations-wrapper">

                        <base-icon 
                            name="loader"
                            v-if="invitationsLoading"
                        />

                        <div 
                            class="empty-state" 
                            v-else-if="!isValidArray(invitations)"
                        >
                            No audience collection invitations found
                        </div>

                        <div
                            class="content"
                            v-else
                        >
                            
                            <div class="title">Audience collection invitations</div>
                            
                            <base-table 
                                :data="invitations"
                                :columns="[
                                    {
                                        name: 'requested-by',
                                        label: 'Requested by',
                                        type: 'avatar-list',
                                        value: (element) => {
                                            return [element.requested_by_user]
                                        }
                                    },
                                    {
                                        name: 'network',
                                        label: 'Platform',
                                        type: 'network',
                                        value: (element) => {                        
                                            return element.network
                                        }
                                    },
                                    {
                                        name: 'created-at',
                                        label: 'Created',
                                        type: 'date',
                                        value: (element) => {                        
                                            return element.created_at
                                        }
                                    },
                                    {
                                        name: 'status',
                                        label: 'Status',
                                        value: (element) => {
                                            return isValidObject(element.status_data) ? `${element.status_data.title} - ${element.status_data.description}` : ''
                                        }
                                    },
                                    // [disabled]
                                    // disabled for now as we are now using the status column, which explains
                                    // the current invitation status better
                                    // {
                                    //     name: 'uploads-active',
                                    //     label: 'Uploads active',
                                    //     type: 'active-status',
                                    //     value: (element) => {
                                    //         return element.active ? 'Active' : 'Not active'
                                    //     },
                                    //     color: (element) => {
                                    //         return element.active ? 'bg-green-500' : 'bg-gray-300';
                                    //     } 
                                    // },
                                    {
                                        name: 'files',
                                        label: 'Files',
                                        type: 'invitation-media-count',
                                        value: (element) => {
                                            return !isValidArray(element.media) ? 0 : element.media.length 
                                        }
                                    },
                                    {
                                        name: 'view-audience-data',
                                        label: 'View Results',
                                        type: 'button',
                                        disabled: (element) => {
                                            return !isValidObject(element.merged_data); 
                                        },
                                        value: (/*element*/) => {
                                            return 'View Results'
                                        },
                                        onClick: onViewAudienceDataClick
                                    }
                                ]"
                                :search="false"
                                :rowActions="[
                                    {

                                        label: 'View Invite',
                                        event: 'view-invite',
                                        disabled: (invitation) => {
                                            return !invitation.active;
                                        },
                                        handler: (invitation) => {
                                            return this.onViewInviteClick(invitation);
                                        }
                                    },
                                    {
                                        label: 'View Uploads',
                                        event: 'view-uploads',
                                        disabled: (invitation) => {
                                            return !isValidArray(invitation.media);
                                        },
                                        handler: (invitation) => {
                                            return this.onSelectInvitation(invitation)
                                        }
                                    },
                                    {
                                        label: 'Execute OCR',
                                        event: 'execute-ocr',
                                        handler: (invitation) => {
                                            return this.executeOcrIntent(invitation);
                                        }
                                    },
                                    {
                                        label: 'Delete',
                                        event: 'delete',
                                        handler: (invitation) => {
                                            return this.deleteInvitationIntent(invitation);
                                        }
                                    }
                                ]"
                            />

                        </div>
                    </div>
                    <!-- ocr loader -->
                    <ocr-loader v-if="ocrLoading" />
                </tab>

            </div>

        </template>
        
        <!-- modals section -->
        <create-audience-collection-invitation-modal
            :visible="createAudienceCollectionInvitationModalVisible"
            :user="user"
            @created="getAudienceCollectionInvitations()"
            @close="createAudienceCollectionInvitationModalVisible = false"
        />

        <audience-collection-invitation-modal
            :visible="audienceCollectionInvitationModalVisible"
            :invitation="selectedInvitation"
            @close="onInvitationModalClose()"
        />

        <view-audience-data-modal
            :visible="viewAudienceDataModalVisible"
            :invitation="viewAudienceDataModalSelectedInvitation"
            :user="user"
            @reload="$emit('reload')"
            @close="onViewAudienceDataModalClose()"
        />

    </div>
</template>
<script>
import ReportUserCardStats from '@/components/ReportUserCardStats.vue';
import CreateAudienceCollectionInvitationModal from '@/components/CreateAudienceCollectionInvitationModal.vue';
import AudienceCollectionInvitationModal from './AudienceCollectionInvitationModal';
import ViewAudienceDataModal from './ViewAudienceDataModal';
import OcrLoader from './OcrLoader.vue';
import moment from 'moment';
import { getObjectValue, isObject, isValidArray, isValidObject, isValidString, notifyCatchError } from '@/common.js';
import { mapGetters } from 'vuex'
export default {
    props: ['user'],
    components: {
        ReportUserCardStats,
        CreateAudienceCollectionInvitationModal,
        AudienceCollectionInvitationModal,
        ViewAudienceDataModal,
        OcrLoader
    },
    data(){
        return {
            tab: 'Stats',

            loading: false,
            tries: 0,

            invitationsLoading: false,
            ocrLoading: false,
            invitations: [],
            selectedInvitation: null,

            createAudienceCollectionInvitationModalVisible: false,
            audienceCollectionInvitationModalVisible: false,

            viewAudienceDataModalVisible: false,
            viewAudienceDataModalSelectedInvitation: null,

            isValidArray,
            isValidObject
        }
    },
    mounted(){
        this.checkUser();
        this.getAudienceCollectionInvitations();
        if (isValidString(this.$route.query.subtab)) {
            const options = ['Stats', 'Collection requests'];
            if(options.includes(this.$route.query.subtab)){
                this.tab = this.$route.query.subtab;
            }
        }
    },
    watch: {
        user(/*val*/){
            this.checkUser()
        },
        tab(val){ 
            this.$router.push({ query: { ...this.$route.query, subtab: val }});
        }
    },
    computed: {
        ...mapGetters(['isAdminOrHigher']),
        isUserReady(){
            const user = this.user
            if(user && isObject(user) && user.social_logins){
                return true;
            }else{
                return false;
            }
        },
        // ig
        hasIgSocialLogin(){
            if(!this.isUserReady) return false;
            return !!this.user.social_logins.find(element => element.network_id == 6); // fb
        },
        hasIgAudienceData(){
            if(!this.isUserReady) return false;
            return !!this.user.audience_data.find(element => element.network_id == 2); // ig
        },
        hasIgNetworkUser(){
            if(!this.isUserReady) return false;
            return !!this.user.social_accounts.find(element => element.network_id == 2); // ig
        },

        // tiktok
        hasTikTokSocialLogin(){
            if(!this.isUserReady) return false;
            return !!this.user.social_logins.find(element => element.network_id == 14); // tiktok
        },
        hasTikTokAudienceData(){
            if(!this.isUserReady) return false;
            return !!this.user.audience_data.find(element => element.network_id == 14); // tiktok
        },
        hasTikTokNetworkUser(){
            if(!this.isUserReady) return false;
            return !!this.user.social_accounts.find(element => element.network_id == 14); // tiktok
        },
    },
    methods: {
        // check if the user does not have audience data, but has facebook social login 
        // in that case, generate the audience data automatically
        checkUser(){
            if(this.tries > 0) return;
            if(this.isUserReady){
                // check for ig
                if(!this.hasIgAudienceData && this.hasIgSocialLogin && this.hasIgNetworkUser){
                    this.tries++;
                    this.generateAudienceData(2);
                }
                // check for tiktok
                if(!this.hasTikTokAudienceData && this.hasTikTokSocialLogin && this.hasTikTokNetworkUser){
                    this.tries++;
                    this.generateAudienceData(14);
                }
            }
        },
        // Will generate audience data for both IG and TikTok
        async generateAudienceData(networkId){
            try{
                this.loading = true;
                const result = await this.$store.dispatch('fb/generateAudienceData', { 
                    userId: this.user.id,
                    networkId,
                    forceUpdate: true,
                    // for this we wont force getting top posts
                    getTopPosts: false 
                });                
                if(result){
                    this.$notify({ title: 'Success', text: 'Audience data generated', type: 'success' });
                    this.$emit('reload');
                }
            }catch(err){
                console.log('generateAudienceData error', err)
            }finally{
                this.loading = false;
            }
        },
        deleteInvitationIntent(invitation){
            this.$swal.fire({
                title: 'Are you sure you want to delete this audience collection invite?',
                text: 'The invitation will be permanently deleted, and its associated media will be deleted too.',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.deleteInvitation(invitation);
                }
            });
        },

        deleteInvitation(invitation){
            this.invitationsLoading = true;
            this.$http.delete(`/api/audience-collection-invitations/${invitation.id}`).then(({ data }) => {
                if(data){
                    this.$notify({ title: 'Success', text: 'Invitation deleted successfully', type: 'success' });
                    // refresh
                    this.getAudienceCollectionInvitations();
                }
            }).catch(err => {
                console.log('generateAudienceData error', err)
                notifyCatchError(err, this.$notify, 'Error while deleting invitation, try again later.');
            }).finally(() => {
                this.invitationsLoading = false;
            });
        },

        executeOcrIntent(invitation){
            this.$swal.fire({
                title: 'Are you sure you want to process all images in this invitation?',
                text: 'Previous OCR results will be overwritten',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Process',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.executeOcr(invitation);
                }
            });
        },

        executeOcr(invitation){
            this.ocrLoading = true;
            this.$http.post('/api/audience-collection-invitations/ocr', {
                id: invitation.id,
                token: invitation.token
            }).then(({ data }) => {
                if(data){
                    this.$notify({ title: 'Success', text: 'OCR executed successfully', type: 'success' });
                    // refresh
                    this.getAudienceCollectionInvitations();
                }
            }).catch(err => {
                console.log('executeOcr error', err)
                notifyCatchError(err, this.$notify, 'Error while executing ocr on the invitation, try again later.');
            }).finally(() => {
                this.ocrLoading = false;
            });
        },

        // has IG or TikTok audience data
        hasAudienceData(){
            const audienceData = this.user.audience_data
            if(audienceData && Array.isArray(audienceData) && audienceData.length){
                const filteredAudienceData = audienceData.filter(element => {
                    return [2, 4, 14].includes(element.network_id)
                })
                let hasPayload = false
                for (const data of filteredAudienceData) {
                    const audience_followers = getObjectValue(data, 'payload.audience_followers')
                    const user_profile = getObjectValue(data, 'payload.user_profile')
                    if(audience_followers || user_profile){
                        hasPayload = true
                    }
                }
                return hasPayload
            }else{
                return false
            }
        },

        getAudienceCollectionInvitations(){
            this.invitationsLoading = true;
            this.$http.get(`/api/audience-collection-invitations?user_id=${this.user.id}`).then(({ data }) => {
                if(isValidArray(data)){
                    this.invitations = data;
                } 
            }).catch((err) => {
                console.log(err);
                notifyCatchError(err, this.$notify, 'An error ocurred while getting audience collection invitations. Try again later.');
            }).finally(() => {
                this.invitationsLoading = false;
            });
        },

        onSelectInvitation(invitation){
            this.selectedInvitation = invitation; 
            this.audienceCollectionInvitationModalVisible = true;
        },

        onInvitationModalClose(){
            this.selectedInvitation = null; 
            this.audienceCollectionInvitationModalVisible = false;
        },

        formatDate(date){
            return moment(date).format('LL');
        },

        onViewInviteClick(invitation){
            window.open(`/audience-collection?t=${invitation.token}`, '_blank');
        },

        onViewAudienceDataClick(invitation){
            this.viewAudienceDataModalVisible = true;
            this.viewAudienceDataModalSelectedInvitation = invitation;
        },

        onViewAudienceDataModalClose(){
            this.viewAudienceDataModalVisible = false;
            this.viewAudienceDataModalSelectedInvitation = null;
        }
        
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply px-12 py-6;
        > .header{
            @apply py-4;
        }
    }
    .content{
        .actions{
            @apply w-full flex justify-end items-center gap-x-4;
        }
        .invitations-wrapper{
            @apply w-full items-center mb-28 overflow-x-auto;
            min-height: 600px;
            > .empty-state{
                @apply w-full text-center font-bold text-h5;
            }
            > .content{
                > .title{
                    @apply text-h4 font-bold mt-4 mb-8;
                }
                > .list{
                    @apply flex flex-col gap-y-2 mt-4;
                    > .item{
                        @apply flex items-center gap-x-10;
                        .admin{
                            @apply flex gap-x-2 items-center justify-center my-4;
                            > .name{
                                @apply font-bold;
                            }
                        }
                        .network{
                            @apply flex items-center gap-x-2;
                            > .name{
                                @apply font-bold;
                            }
                        }
                        .created-at{
                            @apply font-bold;
                        }
                        .active{
                            @apply font-bold;
                        }
                        .media-count{
                            @apply font-bold pr-10;
                        }
                    }
                }
            }
        }
    }
    .empty-state{
        @apply w-full mt-10 flex flex-col items-center gap-y-4;
    }
    .loader{
        @apply flex flex-col items-center;
        > .title{
            @apply mt-4 text-h3 font-bold;
        }
        > .subtitle{
            @apply mt-2 font-bold;
        }
    }
    .title{
        @apply text-h3 font-bold;
    }
</style>
