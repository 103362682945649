<template>
    <div class="ocr-loader">
        <div class="overlay" />
        <div class="wrapper">
            <div class="_container">
                <div class="box">
                    <base-icon :size="16" name="loader" />
                    <div class="title">
                        Processing images...
                    </div>
                    <div class="description">
                        This may take a few seconds.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
    .ocr-loader{
        @apply relative;
        z-index: 70;
        > .overlay{
            @apply fixed inset-0 bg-black opacity-70;
        }
        > .wrapper{
            @apply fixed inset-0;
            > ._container{
                @apply w-full h-full;
                @apply flex justify-center items-center;
                > .box{
                    width: 500px;
                    @apply p-14 bg-white rounded-lg;
                    @apply flex flex-col gap-y-4 justify-center items-center;
                    > .title{
                        @apply text-h3 font-bold;
                    }
                    > .description{
                        @apply text-h6 font-bold;
                    }
                }
            }
        }
    }
</style>
