<template>
    <div class="connections">

        <div class="list">
            
            <base-icon name="loader" v-if="loading_networks || loading_current_connections" />

            <div 
                class="item"
                v-for="(network, index) in connectableNetworks" 
                :key="index"
                @click="connectNetworkIntent(network)"
            >

                <div class="box" :class="{ 'connected': isCurrentlyConnected(network) || isConnected(network) }">
                
                    <div class="status" :class="{ 'connected': isCurrentlyConnected(network) || isConnected(network) }">
                        <base-icon v-if="isCurrentlyConnected(network) || isConnected(network)" name="check" />
                        <base-icon v-else size="4" name="plus" />
                    </div>

                    <base-icon size="8" :name="network.name"/>

                </div>

                <div class="name">
                    {{ network.display_name }}
                </div>

                <component
                    class="hidden"
                    :id="network.name"
                    :is="components[network.name]"
                    :disabled="loading_networks || loading_current_connections"
                    @success="onSuccess"
                />

            </div>
        </div>

        <div class="actions">
            <base-button label="Next" class="font-bold" size="xl" @action="$emit('next')" />
        </div>

    </div>
</template>
<script>
const connectableNetworkIds = [
    10, // google
    6, // facebook
    2, // instagram
    // 7, // twitter
    14 // tiktok
]
import TiktokLogin from '@/components/TiktokLogin';
import GoogleLogin from '@/components/GoogleLogin';
import FacebookLogin from '@/components/FacebookLogin/index';
// import TwitterLogin from '@/components/TwitterLogin';
import { notifyCatchError, isValidArray, isValidHTMLCollection, isValidObject, isValidString } from '@/common';
import { mapGetters } from 'vuex';
const components = {
    'instagram': 'facebook-login', 
    'facebook': 'facebook-login' ,
    // 'twitter': 'twitter-login', 
    'ga': 'google-login',
    'tiktok': 'tiktok-login'
}
export default {
    props: ['value'],
    components: {
        TiktokLogin,
        GoogleLogin,
        FacebookLogin,
        // TwitterLogin
    },
    data(){
        return {
            val: this.value,
            connectableNetworks: [],
            currentConnections: [],
            loading_networks: false,
            loading_current_connections: false,

            connectableNetworkIds,
            components
        }
    },

    mounted(){
        this.getNetworks();
        this.getCurrentConnections();
    },
    computed: {
        ...mapGetters(['me'])
    },

    methods: {
        getNetworks() {
            this.loading_networks = true;
            this.$http.get('/api/userDeliverables/networks').then(({ data }) => {

                this.connectableNetworks = data.filter(network => {
                    return this.connectableNetworkIds.includes(network.id);
                });

                // This block makes sure that the hidden "button" elements exist in the
                // DOM, this is important so that when the user clicks on any of the 
                // buttons, the code is able to find that hidden button element
                setTimeout(() => {
                    for (const network of this.connectableNetworks) {
                        if(network && network.name){
                            const element = document.getElementById(network.name);
                            console.log("Mounted element:", element);
                        }
                    }
                }, 1000);

            }).catch(err => {
                notifyCatchError(err, this.$notify, 'An unexpected error ocurred, try again later.');
            }).finally(() => {
                this.loading_networks = false;
            });
        },
        getCurrentConnections(){
            this.loading_current_connections = true;
            this.$http.get('/api/me/connections').then(({ data }) => {
                if(isValidArray(data)){
                    this.currentConnections = data;
                } 
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'An unexpected error ocurred, try again later.');
            }).finally(() => {
                this.loading_current_connections = false
            });
        },
        onSuccess(data) {
            const { userId, email, accessToken, networkId } = data;

            this.loading_networks = true;
            this.$http.post(`/api/auth/register/link-account`, {
                network_id: networkId,
                profile_id: userId,
                email,
                ...(accessToken ? { access_token: accessToken } : {})
            }).then((/*response*/) => {

                this.$notify({ title: 'Success', text: `Account Linked Successfully`, type: 'success' });
                
                this.toggleConnected(networkId)

                this.$store.dispatch('auth/getProfileCompletion');
                
                if(networkId === 6){
                    this.$store.dispatch('fb/getLoginStatus');
                }
                if(this.me && this.me.id && networkId){
                    // [creator-connected] Onboarding section
                    this.$store.dispatch('fb/generateAudienceData', { userId: this.me.id, networkId });
                }

            }).catch((err) => {
                notifyCatchError(err, this.$notify);
                console.log('toggleSocialLogins error', err);
            }).finally(() => {
                this.loading_networks = false;
            });
        },
        connectNetworkIntent(network){
            try{
                if(!isValidObject(network)) throw new Error('Invalid network object');
                const { name } = network;
                if(!isValidString(name)) throw new Error('Unable to get network name');
                const element = document.getElementById(name);
                if(!element) throw new Error('Unable to find network element');
                // if the element is already a button then we click it
                if(element.tagName == 'BUTTON'){
                    element.click();
                }else{
                    // if not we will search for a button element inside the element
                    const buttons = element.getElementsByTagName('button');
                    if(!isValidHTMLCollection(buttons)) throw new Error('Unable to find the button element for this network')
                    const firstButton = buttons[0];
                    firstButton.click();
                }
            }catch(err){
                console.log('Connect network intent error: ', err);
            }
        },
        toggleConnected(networkId){
            const network = this.connectableNetworks.find(element => element.id == networkId)
            if(network){
                this.val.push(network);
                this.$emit('input', this.val);
            }
        },
        isCurrentlyConnected(network){
            const found = this.currentConnections.find(element => element.network_id == network.id);
            return !!found;
        },
        isConnected(network){
            const found = this.val.find(element => element.id == network.id);
            return !!found;
        }
    }
}
</script>
<style lang="scss" scoped>
    .connections{
        @apply h-full flex flex-col justify-start items-center;
        .list{
            @apply flex flex-row flex-wrap justify-center gap-x-6;
            .item{
                @apply flex flex-col gap-y-4 justify-start items-center;
                @apply cursor-pointer;
                .box{
                    @apply relative;
                    @apply flex justify-center items-center;
                    @apply border border-2 border-gray-300 rounded-lg;
                    @apply transition-colors duration-200;
                    width: 100px;
                    height: 100px;
                    &.connected{
                        @apply border-purple-m-main;
                    }
                    .status{
                        @apply absolute -top-4 -right-4;
                        @apply w-8 h-8 rounded-full border border-gray-400 bg-white;
                        @apply flex justify-center items-center;
                        @apply transition-colors duration-200;
                        &.connected{
                            @apply bg-green-m-main;
                        }
                    }
                }
                &:hover{
                    .box{
                        @apply bg-gray-100;
                    }
                }
                .name{
                    @apply flex justify-center items-center;
                    @apply font-bold;
                }
            }
        }
        .actions{
            @apply mt-10 flex flex-col items-center gap-y-6;
        }
    }
</style>
