<template>
  <!-- id is being used for pdf generation -->
  <div id="report-top" class="report-top">

    <div class="left">
      <div class="container">

        <p class="title">

          <!-- Project name - We will show it if it's a default report and the project's name is set on the report -->
          <template v-if="report.is_default == true && isValidString(report.project_name)">

            <!-- Clickable link, only for admins and project's slug must be set -->
            <a 
              v-if="isAdmin && isValidString(report.project_slug)" 
              target="_blank"
              :href="`/projects/${report.project_slug}`"
            >
              {{ report.project_name }} -
            </a>

            <!-- Otherwise just show the text -->
            <template v-else>
              {{ report.project_name }} -
            </template>

          </template>

          <!-- Report name -->
          {{ report.name }}
          
          <!-- Is default label -->
          <template v-if="isAdmin && report.is_default">
            <br/>
            <span>This is a default report</span>
          </template>

        </p>

        <div class="info">
          <div class="info-container">
            <div class="description-wrapper">
              <div class="description" v-html="report.description"></div>
            </div>
            <tags class="mt-2" :tags="report.report_tags" :limit="-1" />
          </div>
        </div>
      </div>

    </div>

    <div class="right" v-if="!printView">
      <div class="actions">
        <base-button 
          label="Edit" 
          v-if="allowEdit"
          :link="$route.path + '/edit'" 
          :disabled="refreshing" 
          icon="pencil" 
          bold 
        />
        <base-button 
          label="Refresh"
          v-if="allowRefresh"
          @action="$emit('refresh')"
          :disabled="refreshButtonDisabled"
          v-tooltip="refreshButtonTooltip"
          icon="refresh"
          bold 
        />
        <dropdown :options="downloadOptions" @changed="(option) => {$emit('export-button-handler', option)}" v-if="allowExport">
          <base-button icon="document-export" label="Export Data" bold :disabled="refreshing" :outline="false" size="lg"/>
        </dropdown>
      </div>
      <div class="last-updated-at" v-if="isAdmin">Last updated on <span>{{ lastUpdated }}</span></div>
    </div>
  </div>
</template>

<script>
import Tags from '@/components/Tags';
import moment from 'moment';
import { mapGetters } from 'vuex'
import { isRefreshed } from '@/lib/post';
import { isValidString } from '@/common';
export default {
  components: { 
    Tags 
  },
  props: {
    report: {
      type: Object,
      default: function () {
        return {};
      }
    },
    user: {
      type: Object,
      default: function () {
        return {
          roles: [],
          companies: []
        };
      }
    },
    printView: {
      type: Boolean,
      default: false
    },
    refreshing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downloadOptions: [
        { label: 'PDF', value: 'pdf' },
        { label: 'CSV', value: 'csv' }
      ],
      isValidString
    }
  },

  computed: {
    ...mapGetters(['isAdminOrHigher', 'isProjectManager']),
    // determines if all posts on the report were updated
    // correctly, according to the post update frequencies
    allPostsUpdated(){
      let allUpdated = true;
      for (const post of this.report.posts) { 
        if(!isRefreshed(post)){
          allUpdated = false;
        }
      }
      return allUpdated;
    },
    reportUpdatedToday(){
      const updatedAt = moment(this.report.updated_at);
      const today = moment();
      return updatedAt.isSame(today, 'day');
    },
    lastUpdated() {
      let date = new Date(this.report.updated_at);
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    refreshButtonDisabled(){
      if(this.refreshing) return true;
      if(this.allPostsUpdated){
        if(this.reportUpdatedToday){
          return true;
        }
      }
      return false;
    },
    refreshButtonTooltip(){
      if(this.isAdminOrHigher || this.isProjectManager){
        if(this.allPostsUpdated){
          if(this.reportUpdatedToday){
            return { 
              content: 'This report has already been refreshed today. If you still want to refresh the report, you can go to the edit page.', 
              classes: 'v-tooltip-black' 
            };
          }
        }
      }
      return {};
    },
    allowRefresh() {
      return this.user ? !this.user.roles.includes('client') : false;
    },
    isAdmin() {
      return this.user ? (this.user.roles.includes('admin') || this.user.roles.includes('superAdmin')) : false;
    },
    isInCompany() {
      return this.report.company_id ? this.user.companies.includes(this.report.company_id) : false;
    },
    allowEdit() {
      return this.user ? this.isAdmin && this.isInCompany : false;
    },
    allowExport() {
      return true;
    },
  }
}
</script>

<style lang="scss" scoped>
  .report-top{
    @apply justify-between items-center; 
    @media (min-width: 768px) {
      @apply flex;
    }
    > .left{
      @apply text-center;
      @media (min-width: 768px) {
        @apply flex;
      }
      > .container{
        @apply text-center flex flex-col;
        @media (min-width: 768px) {
          @apply text-left;
        }
        > p.title{
          @apply text-2xl font-bold text-black;
          > span{
            @apply text-sm font-normal italic;
          }
        }
        > .info{
          @apply text-sm font-medium text-gray-m-disable; 
          @media (min-width: 768px) {
            @apply flex;
          }
          > .info-container{
            @apply mx-2 flex flex-col gap-2;
            @media (min-width: 768px) {
              @apply mx-0;
            }
            > .description-wrapper{
              @apply flex items-center mr-4 w-full;
              > .description{
                @apply text-center;
                @media (min-width: 768px) {
                  @apply text-left;
                }
              }
            }
          }
        }
      }
    }
    > .right{
      @apply mx-2 mb-4;
      @media (min-width: 768px) {
        @apply mx-0;
      }
      @media (min-width: 1024px) {
        @apply flex-shrink-0;
      }
      > .actions{
        @apply flex flex-wrap gap-3 justify-center;
        @media (min-width: 768px) {
          @apply inline-flex flex-no-wrap;
        } 
      }
      > .last-updated-at{
        @apply text-sm text-right mt-2;
        > span{
          @apply font-bold;
        }
      }
    }
  }
</style>
