<template>
    <div class="audience-collection-wrapper">

        <div class="audience-collection-container">
            
            <!-- loading -->
            <base-icon v-if="loading" name="loader" />
            <!-- no token -->
            <div v-else-if="!isValidString(token)" class="empty-state">Invalid token!</div>
            <!-- no invitation -->
            <div v-else-if="!isValidObject(invitation)" class="empty-state">Invitation not found!</div>    
            
            <div v-else class="audience-collection-content">
                
                <!-- header -->
                <div class="header">
                    <img class="logo" src="../../assets/images/logo.svg" />
                    <div class="admin">
                        <avatar :user="invitation.requested_by_user" />
                        <div class="name"> {{ invitation.requested_by_user.first_name }} {{ invitation.requested_by_user.last_name }} </div>
                    </div>
                    <h2 class="title">
                        <span class="text-green-m-main">{{ companyName }}</span> has invited you to submit your <span class="text-green-m-main">{{ invitation.network.display_name }}</span> audience data for the following profile:
                    </h2>
                    <div class="creator">
                        <avatar :user="invitation.for_user" />
                        <div class="name"> {{ invitation.for_user.first_name }} {{ invitation.for_user.last_name }} </div>
                    </div>
                    <!-- network list -->
                    <div class="network-list">
                        <!-- if no social accounts are found, we show the expected network from the invitation -->
                        <div 
                            class="network-item"
                            v-if="!isValidArray(invitation.for_user.social_accounts)"
                        >
                            <div class="box">
                                <base-icon size="8" :name="invitation.network.name"/>
                                <div class="name">
                                    {{ invitation.network.display_name }}
                                </div> 
                            </div>
                        </div>
                        <template v-else>
                            <!-- else show social account list -->
                            <div 
                                class="network-item"
                                v-for="(network_user, index) in invitation.for_user.social_accounts"
                                :key="index"
                            >
                                <div class="box">
                                    <base-icon size="8" :name="network_user.network.name"/>
                                    <div class="handle">
                                        @{{ network_user.handle }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <!-- Not necesary -->
                    <!-- <div class="host">{{host}}</div> -->

                </div>

                <!-- form wrapper -->
                <div class="form-wrapper">

                    <!-- form container -->
                    <div class="form-container">

                        <dropzone
                            v-if="!done"
                            :invitation="invitation"
                            @uploading-files-change="(val) => uploadingFiles = val"
                            @done="onFilesUploaded()"
                        />

                        <div class="done" v-else>
                            <base-icon 
                                class="text-green-m-main" 
                                name="check-circle"
                                :size="16" 
                            />
                            <div class="title">Done!</div>
                            <div class="subtitle">Your files have been uploaded successfully!</div>
                            <div class="description">The admin who sent the invitation has been notified and will be in touch with you soon. Thank you!</div>
                            <base-button
                                label="Go Home"
                                bold
                                @action="$router.push('/home')"
                            />
                        </div>

                    </div>
                </div>
                
                <div class="back-button-container">
                    <div 
                        class="back-button"
                        :class="{ 'disabled': loading || uploadingFiles }" 
                        @click="onBack"
                    >
                        <base-icon name="arrow-narrow-left"/>
                        <div>Go back</div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import Dropzone from './Dropzone.vue';
import { isValidArray, isValidObject, isValidString, notifyCatchError } from '../../common';
export default {
    components: {
        Dropzone
    },
    data(){
        return {
            invitation: null,
            done: false,
            loading: false,
            uploadingFiles: false,

            isValidObject,
            isValidString,
            isValidArray
        }
    },
    mounted(){
        this.getInvitation();
    },
    computed: {
        token(){
            return (this.$route.query && isValidString(this.$route.query.t)) ? this.$route.query.t : '';
        },
        host(){
            return window.location.host;
        },
        companyName(){
            return (this.invitation && this.invitation.company && isValidString(this.invitation.company.name)) ? this.invitation.company.name : 'Markerly'
        }
    },
    methods: {
        getInvitation(){
            
            if(!isValidString(this.token)) return this.$notify({ title: 'Invalid token', text: 'The invitation token was not found', type: 'warn' });

            this.loading = true;
            this.$http.get(`/api/audience-collection-invitations/${this.token}`).then(({ data }) => {
                this.invitation = data;
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'There was an error while getting the invitation. Please try again later.');
            }).finally(() => {
                this.loading = false;
            });

        },
        onFilesUploaded(){
            this.done = true;
        },
        onBack(){
            this.$router.back();
        }
    }

}
</script>
<style lang="scss" scoped>
    .audience-collection-wrapper{
        @apply min-h-screen bg-purple-m-main overflow-y-auto;
        @apply flex flex-col;
        padding-top: 100px;
        padding-bottom: 100px;
        .audience-collection-container{
            > .empty-state{
                @apply w-full;
                height: calc(100vh - 200px);
                @apply text-white text-h4 font-bold;
                @apply flex justify-center items-center;
            }
            .audience-collection-content{
                
                .header {
                    @apply mx-auto w-full max-w-md;
                    .logo {
                        @apply mx-auto h-12 w-auto;
                    }
                    .title {
                        @apply mt-6 text-center text-2xl leading-9 font-extrabold text-white;
                    }
                    .creator, .admin{
                        @apply flex gap-x-2 items-center justify-center my-4;
                        > .name{
                            @apply font-bold text-white;
                        }
                    }
                    .network-list{
                        @apply flex flex-row flex-wrap justify-center gap-x-6;
                        .network-item{
                            @apply flex flex-col gap-y-4 justify-start items-center;
                            .box{
                                @apply px-4 py-2;
                                @apply border border-2 border-gray-300 rounded-lg;
                                @apply flex justify-center items-center gap-x-4;
                                @apply text-white;
                                .name{
                                    @apply text-white font-bold;
                                }
                                .handle{
                                    @apply text-white font-bold;   
                                }
                            }
                        }
                    }
                    .host {
                        @apply flex justify-center mt-2 text-sm text-gray-m;
                    }
                }
                .form {
                    @apply flex flex-col items-center;
                }

                .form-wrapper{
                    @apply mt-8 mx-auto w-full max-w-md;
                    .form-container{
                        @apply bg-white py-8 px-4 shadow rounded-lg px-10;
                        @apply flex flex-col justify-center items-center;
                        > .done{
                            @apply flex flex-col items-center text-center;
                            > .title{
                                @apply text-h4 font-bold;
                            }
                            > .subtitle{
                                @apply mt-2 text-h5 font-bold;

                            }
                            > .description{
                                @apply mt-2 mb-10;
                            }
                        }
                    }
                }

                .back-button-container{
                    @apply w-full my-5 flex justify-center;
                    .back-button{
                        @apply py-3 px-7 rounded-full;
                        @apply border border-transparent;
                        @apply flex gap-x-2 items-center;
                        @apply text-white;
                        @apply cursor-pointer transition-colors duration-300;
                        &:hover{
                            @apply border-white text-gray-500;
                        }
                        &.disabled{
                            @apply pointer-events-none text-gray-700 border-gray-700;
                        }
                    }
                }

            }
        }

    }
</style>