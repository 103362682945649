<template>
    <div class="drop-zone-wrapper">
        <div class="loader" v-if="uploadingFiles">
            <base-icon :size="10" name="loader"/>
            <div class="title">Uploading files...</div>
            <div class="description">Please wait</div>
            <div class="progress-bar-wrapper">
                <div 
                    class="progress-bar" 
                    :style="`transform: scaleX(${(processedFiles * 1) / uploadableFiles});`"
                /> 
            </div>
        </div>
        <template v-else>
            <div 
                class="drop-zone" 
                :class="{'dragged-over': draggedOver}" 
                @dragover.prevent="draggedOver = true" 
                @dragleave="draggedOver = false"
                @drop.stop.prevent="dropHandler"
                @click="openFileExplorer()"
            >
                <div class="content">
                    <!-- plus icon -->
                    <div class="icon">
                        <base-icon name="plus" :size="5" />
                    </div>
                    <!-- text -->
                    <div class="text">
                        {{ draggedOver ? 'Drop' : 'Drag' }} files {{ draggedOver ? 'here ' : '' }}to upload
                    </div>
                    <!-- description -->
                    <div class="description">or click here</div>
                    <!-- supported extensions -->
                    <div class="supported-extensions">{{ supportedExtensions.join(' ') }}</div>
                </div>
                <!-- actual input -->
                <form id="file-form" class="hidden-input">
                    <!-- will only show supported extensions in the file explorer -->
                    <FormulateInput 
                        type="file" 
                        name="file" 
                        multiple
                        :uploader="fileExplorerHandler"
                        :accept="supportedExtensions.join(', ')" 
                    />
                </form>
            </div>
            <div class="actions">
                <base-button 
                    label="Upload"
                    icon="upload"
                    class="w-full"
                    :disabled="!isValidArray(files)"
                    bold
                    @action="uploadFiles" 
                />
            </div>
            <div class="file-count" v-if="isValidArray(files)">
                {{ files.length }} file{{ files.length == 1 ? '' : 's' }}
            </div>
            <div class="file-list">
                <div 
                    class="file-item"
                    v-for="(file, index) in files"
                    :key="index"
                    @click="previewFile(file)"
                >
                    <!-- icon -->
                    <div class="left">
                        <!-- <base-icon v-if="loading" name="loader" :size="5" /> -->
                        <!-- v-else -->
                        <base-icon class="text-purple-m-main" name="photo" :size="5" />
                    </div>
                    <!-- right -->
                    <div class="right">
                        <div class="info">
                            <div class="name">{{file.name}}</div>
                            <div class="size" v-if="file.size">{{formatBytes(file.size)}}</div>
                        </div>
                        <div 
                            class="remove-file-icon"
                            @click.stop="removeFile(file)"
                        >
                            <base-icon name="x" :size="4" />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Lightbox -->
        <lightbox
            :visible="lightboxVisible"
            :file="selectedFileForPreview"
            @close="closeLightbox()"
        />

    </div>
</template>
<script>
import formatBytes, { getFileExtension } from '@/lib/files';
import { isValidArray, isValidObject, isValidString, notifyCatchError, sleep } from '../../common';
import Lightbox from './Lightbox.vue';
const maxFileLength = 10;
const supportedExtensions = [
    '.png',
    '.jpg',
    '.jpeg',
    '.webp'
];
export default {
    props: ['invitation'],
    components: {
        Lightbox
    },
    data(){
        return {
            files: [],
            draggedOver: false,
            uploadingFiles: false,
            
            // file upload progress vars
            uploadableFiles: 0,
            processedFiles: 0,

            lightboxVisible: false,
            selectedFileForPreview: null,

            maxFileLength,
            supportedExtensions,

            formatBytes,
            isValidArray
        }
    },
    watch: {
        files(val){
            if(isValidArray(val) && val.length > maxFileLength){
                const firstElements = val.slice(0, maxFileLength);
                this.files = firstElements;
                this.$notify({ title: `Oops! You can only upload up to ${maxFileLength} files.`, text: 'Please remove some and try again.', type: 'warn' });
            }
        }
    },
    methods: {
        async dropHandler(event){

            console.log('File(s) dropped');
            
            const files = event.dataTransfer.files;
                
            // Validate files object
            if (!isValidObject(files)) return this.$notify({ title: 'No files dropped', type: 'warn' });
            // Validate max file length
            if(files.length > maxFileLength) return this.$notify({ title: `Oops! You can only upload up to ${maxFileLength} files.`, text: 'Please remove some and try again.', type: 'warn' });
            
            // Validate and add files to array
            let errors = [];
            for (const key in files) {
                const file = files[key];
                if(typeof file == 'object'){
                    const extension = getFileExtension(file);
                    const isValidExtension = this.isValidExtension(extension);
                    const hasBasicData = this.hasBasicData(file);
                    if(!isValidExtension || !hasBasicData){
                        errors.push(file);
                    }else{
                        this.files.push(file);
                    }
                }
            }
            
            // notify if there were errors
            if(isValidArray(errors)){
                this.$notify({ title: 'Some of the files you uploaded are not supported.', text: 'Please upload only PNG, JPG, or WEBP images.', type: 'warn' });
                console.log(errors);
            }
            
            this.draggedOver = false;

        },
        // triggered when clicking the dropzone
        openFileExplorer(){
            let items = document.getElementsByClassName('formulate-input');
            for (let i in items) {
                if (items[i].dataset && items[i].dataset.type === 'file') {
                    let descendants = items[i].querySelectorAll("*");
                    for (let j in descendants) {
                        if (descendants[j].name && descendants[j].name === 'file') {
                            descendants[j].click();
                        }
                    }
                }
            }
        },
        // file explorer files
        // * in the case of multiple files, this function will be executed multiple
        // times with just 1 file as the param.
        async fileExplorerHandler(file /*, progress, error, option*/) {

            // Validate max file length
            if(isValidArray(this.files) && this.files.length >= maxFileLength) return this.$notify({ title: `Oops! You can only upload up to ${maxFileLength} files.`, text: 'Please remove some and try again.', type: 'warn' });

            // Validate and add file to array
            let error = false;
            if(typeof file == 'object'){
                const extension = getFileExtension(file);
                const isValidExtension = this.isValidExtension(extension);
                const hasBasicData = this.hasBasicData(file);
                if(!isValidExtension || !hasBasicData){
                    error = true;
                }else{
                    this.files.push(file);
                }
            }

            // notify if there were errors
            if(error == true){
                this.$notify({ title: 'The file you uploaded is not supported.', text: 'Please upload only PNG, JPG, or WEBP images.', type: 'warn' });
                console.log('Error while adding the following file', file);
            }

        },

        removeFile(file){
            this.files = this.files.filter(e => e !== file); 
        },

        previewFile(file){
            this.lightboxVisible = true;
            this.selectedFileForPreview = file;
        },

        closeLightbox(){
            this.lightboxVisible = false;
            this.selectedFileForPreview = null;
        },

        resetForm(){
            let form = document.getElementById('file-form');
            if (form) {
                form.reset();
            }
        },
        
        hasBasicData(file){
            if(typeof file !== 'object') return false;
            const { name, type, size } = file; 
            if(isValidString(name) && isValidString(type) && size){
                return true;
            }
            return false;
        },

        isValidExtension(extension){
            if(!isValidString(extension)) return false;
            let _extension = extension.trim().toLowerCase();
            if(supportedExtensions.includes(_extension)){
                return true;
            }
            return false;
        },
        
        async uploadFiles() {

            if(!isValidArray(this.files)){
                return this.$notify({ title: 'Invalid files array.', type: 'warn' });
            }

            if(!this.invitation || !this.invitation.id || !this.invitation.token){
                return this.$notify({ title: 'Unable to get invitation details.', type: 'warn' });
            }

            let count = this.files.length;
            let uploaded = 0;
            let errors = 0;

            this.uploadingFiles = true;
            this.$emit('uploading-files-change', true);
            this.uploadableFiles = count;
            this.processedFiles = 0;
            for (const file of this.files) {
                try{
                    const body = new FormData();
                    body.append('file', file);
                    body.append('id', this.invitation.id);
                    body.append('token', this.invitation.token);
                    const { data } = await this.$http.post('/api/audience-collection-invitations/file-upload', body);
                    if(data){
                        uploaded++;
                    }else{
                        errors++;
                    }
                }catch(err){
                    errors++;
                    console.log('An error ocurred while uploading this file', file, err);
                }finally{
                    this.processedFiles++;
                    await sleep(1000);
                }
            }

            if(uploaded){
                if(uploaded == count){
                    this.$notify({ title: 'Success', text: 'All files uploaded successfully.', type: 'success' });
                }else{
                    this.$notify({ title: 'Success', text: `${uploaded} file${uploaded == 1 ? '' : 's'} uploaded. ${errors} file${errors == 1 ? '' : 's'} were not able to be uploaded.`, type: 'success' });
                }
                // Send notifications (mail and in-app notification)
                try{
                    const { data } = await this.$http.post('/api/audience-collection-invitations/send-notifications', {
                        id: this.invitation.id,
                        token: this.invitation.token
                    });
                    if(data){
                        this.$notify({ title: 'Success', text: 'Notifications sent successfully.', type: 'success' });
                    }
                }catch(err){
                    notifyCatchError(err, this.$notify, 'Error while sending notifications')
                }
                
                // emit done
                this.$emit('done');

                // Execute OCR, we dont need to notify the user of this request's success
                try{
                    const { data } = await this.$http.post('/api/audience-collection-invitations/ocr', {
                        id: this.invitation.id,
                        token: this.invitation.token
                    });
                    if(data){
                        // this.$notify({ title: 'Success', text: 'OCR Executed successfully.', type: 'success' });
                    }
                }catch(err){
                    console.log('OCR error', err);
                    // notifyCatchError(err, this.$notify, 'Error while sending notifications');
                }
            }else{
                this.$notify({ title: 'Success', text: 'No files were uploaded. Try again later.', type: 'success' });
            }

            this.uploadingFiles = false;
            this.$emit('uploading-files-change', false);
            
        }

    }
}
</script>
<style lang="scss" scoped>
    .drop-zone-wrapper{
        @apply w-full;
        @apply flex flex-col gap-y-6;
        > .loader{
            @apply flex flex-col items-center;
            > .title{
                @apply mt-4 font-bold text-h5;
            }
            > .description{
                @apply text-gray-600 text-sm;
            }
            > .progress-bar-wrapper{
                @apply w-full h-4 mt-6 rounded-lg bg-gray-200 overflow-hidden flex;
                > .progress-bar{
                    @apply w-full h-4 rounded-lg bg-green-m-main;
                    @apply transition-transform origin-left duration-200;
                }
            }
        }
        > .drop-zone{
            @apply w-full z-10 bg-white;
            @apply border-2 border-dashed border-gray-400;
            @apply cursor-pointer transition-colors duration-300;
            height: 11rem;
            &:hover{
                @apply bg-gray-m-light border-purple-m-main;
                > .content{
                    > .icon{
                        @apply text-purple-m-main border-purple-m-main;
                    }
                }
            }
            &.dragged-over{
                @apply bg-gray-m-light border-purple-m-main;
                > .content{
                    > .icon{
                        @apply text-purple-m-main border-purple-m-main;
                    }
                }
            }
            
            
            > .content{
                @apply h-full p-4 pointer-events-none;
                @apply flex flex-col justify-center items-center;
                > .icon{
                    @apply w-15 h-15 rounded-full bg-transparent;
                    @apply border border-gray-400;
                    @apply flex items-center justify-center;
                    @apply text-gray-400;
                    @apply transition-colors duration-300;
                }
                > .text{
                    @apply pt-4;
                    @apply text-purple-m-secondary text-h5 font-bold text-center;
                }
                > .description{
                    @apply text-purple-m-secondary text-ps;
                }
                > .supported-extensions{
                    @apply text-pxs text-gray-500;
                }
            }
            > .hidden-input{
                @apply h-0 overflow-hidden pointer-events-none;
            }
        }
        > .file-count{
            @apply w-full flex justify-end;
            @apply text-sm text-gray-400;
        }
        > .file-list{
            @apply w-full;
            @apply flex flex-col gap-y-4;
            > .file-item{
                @apply w-full py-3 px-4 bg-white;
                @apply border border-gray-300 rounded-lg;
                @apply flex items-center gap-x-4;
                @apply cursor-pointer transition-colors duration-300;
                &:hover{
                    @apply bg-gray-200 border-gray-400;
                    cursor: zoom-in;
                }
                > .left{
                    @apply flex-grow-0 flex-shrink-0;
                    @apply flex justify-center items-center;
                }
                > .right{
                    @apply flex-grow flex-shrink;
                    @apply flex justify-between items-center;
                    > .info{
                        @apply flex-grow flex-shrink;
                        @apply flex flex-col gap-y-1;
                        > .name{
                            @apply font-bold;
                        }
                        > .size{
                            @apply text-pxs;
                        }
                    }
                    > .remove-file-icon{
                        @apply flex-grow-0 flex-shrink-0;
                        @apply h-12 w-12 rounded-full bg-transparent;
                        @apply flex justify-center items-center;
                        @apply cursor-pointer transition-colors duration-300;
                        &:hover{
                            @apply bg-gray-300 text-purple-m-main;
                        }
                    }
                }
            }
        }
    }
</style>