import { render, staticRenderFns } from "./AssignCreatorModal.vue?vue&type=template&id=46be543a&scoped=true&"
import script from "./AssignCreatorModal.vue?vue&type=script&lang=js&"
export * from "./AssignCreatorModal.vue?vue&type=script&lang=js&"
import style0 from "./AssignCreatorModal.vue?vue&type=style&index=0&id=46be543a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46be543a",
  null
  
)

export default component.exports