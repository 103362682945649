<template>
    <base-modal 
        title="Raw Data" 
        :visible="visible"
        @close="$emit('close')"
        styles="max-height: 90vh; overflow-y: auto; min-width: 80vw; width: 80vw;"
    >
        <div class="view-raw-data">
            <div v-if="!isValidObject(data)">Invalid raw data</div>
            <template v-else>
                {{ data }}
            </template>
        </div>
    </base-modal>
</template>
<script>
    import { isValidObject } from '@/common';
    export default {
        name: 'ViewRawDataModal',
        props: ['visible', 'data'],
        data() {
            return {
                isValidObject
            }
        }
    }
</script>
<style lang="scss" scoped>
    .view-raw-data{
        @apply relative;
    }
</style>
