<template>
    <div class="step-2">
        <div v-if="!checkInfo.ready">{{ checkInfo.message }}</div>
        <div 
            class="content"
            v-else
        >
            <!-- creator header -->
            <div class="creator">
                <avatar :user="invitation.for_user" />
                <div class="name"> {{ invitation.for_user.first_name }} {{ invitation.for_user.last_name }} </div>
            </div>
            <!-- section -->
            <div
                class="section" 
                v-if="!isValidObject(existingAudienceData)"
            >
                <div class="title">This creator does not have basic audience data for this network yet!</div>
                <div class="description">Create a basic audience data for the creator first.</div>
            </div>
            <div
                class="section" 
                v-else-if="isValidObject(existingFollowerData)"
            >
                <div class="title">This creator already has audience data.</div> 
                <div class="description">The existing audience data values will be overwritten.</div>
            </div>
            <div
                class="section"
                v-else
            >
                <div class="title">This creator does not have follower data yet.</div>
                <div class="description">A new follower data set will be created.</div>
            </div>
        </div>
        <div class="footer">
            <div class="content">
                <base-button
                    label="Back"
                    type="secondary"
                    @action="$emit('back')"
                />
                <base-button
                    v-if="!isValidObject(existingAudienceData)"  
                    label="Generate audience data"
                    :disabled="loading"
                    size="xlg"
                    class="font-bold"
                    @action="generateAudienceData(true)"
                />
                <base-button
                    v-if="isValidObject(existingAudienceData) && isValidObject(existingFollowerData)"
                    label="Overwrite audience data"
                    :disabled="loading"
                    size="xlg"
                    class="font-bold"
                    @action="mergeAudienceData()"
                />
                <base-button
                    v-if="isValidObject(existingAudienceData) && !isValidObject(existingFollowerData)"
                    label="Create audience data"
                    :disabled="loading"
                    size="xlg"
                    class="font-bold"
                    @action="mergeAudienceData()"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import { getAudienceData } from '@/lib/user';
    import { isValidArray, isValidString, isValidObject, getObjectValue, notifyCatchError } from '@/common';
    export default {
        name: 'Step2',
        props: ['visible', 'invitation', 'user'],
        data() {
            return {
                loading: false,
                isValidArray,
                isValidString,
                isValidObject
            }
        },
        computed: {
            checkInfo(){
                let ready = true;
                let message = '';
                try{
                    if(!isValidObject(this.user)) throw new Error('User not found');
                    if(!isValidObject(this.invitation)) throw new Error('Invitation not found');
                    if(!this.invitation.id) throw new Error('Invitation ID not found');
                    if(!isValidObject(this.invitation.network)) throw new Error('Invitation network not found');
                    if(!isValidString(this.invitation.network.name)) throw new Error('Invitation network not found');
                    return { ready, message }
                }catch(err){
                    console.log('Error while trying to get info', err);
                    ready = false;
                    message = err;
                    return { ready, message }
                }
            },
            existingAudienceData(){
                const audience_data = getAudienceData(this.user, this.invitation.network.name);
                if(isValidObject(audience_data)){
                    return audience_data
                }
                return null;
            },
            existingFollowerData(){
                if(isValidObject(this.existingAudienceData)){
                    const follower_data = getObjectValue(this.existingAudienceData, 'payload.audience_followers.data', null);
                    if(isValidObject(follower_data)){
                        return follower_data;
                    }
                }
                return null;
            }
        },
        methods: {
            generateAudienceData(){
                this.loading = true;
                this.$http.post('/users/generate-audience-data-v2', {
                    userId: this.user.id,
                    networkId: this.invitation.network.id
                }).then(({ data }) => {
                    if(data){
                        this.$notify({ title: 'Success', text: 'Audience data generated', type: 'success' });
                        this.$emit('reload');
                    }
                }).catch(err => {
                    console.log('generateAudienceData error', err);
                    notifyCatchError(err, this.$notify, 'Error while generating audience data. Try again later.');
                }).finally(() => {
                    this.loading = false;
                });
            },
            mergeAudienceData(){
                this.loading = true;
                this.$http.post('/api/audience-collection-invitations/merge-audience-data', {
                    id: this.invitation.id
                }).then(({ data }) => {
                    if(data){
                        this.$notify({ title: 'Success', text: 'Audience data merged successfully', type: 'success' });
                        this.$emit('reload');
                        this.$emit('done');
                    }
                }).catch(err => {
                    console.log('mergeAudienceData error', err);
                    notifyCatchError(err, this.$notify, 'Error while merging audience data. Try again later.');
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .step-2{
        > .content{
            @apply flex flex-col items-start gap-y-2;
            .creator{
                @apply flex gap-x-2 items-center justify-center my-4;
                > .name{
                    @apply font-bold;
                }
            }
            > .section{
                @apply flex flex-col gap-y-2;
                > .title{
                    @apply font-bold text-h4;
                }
                > .description{
                    @apply text-h5;
                }
            }
        }
        > .footer{
            @apply sticky bottom-0;
            > .content{
                @apply w-full bg-tan-m;
                @apply py-6 mt-20;
                @apply flex justify-between items-center;
            }
        }
    }
</style>
