<template>
    <base-modal
        :visible="visible"
        :title="title"
        persistent
        styles="max-height: 90vh; min-width: 90vw; width: 90vw; overflow-y: auto; background-color: white;"
        noPadding
        container-class="flex justify-center"
        @close="$emit('close')"
    >
        <div class="custom-test z-10 container pt-5 px-14 flex-grow flex-shrink">
            
            <!-- user select list -->
            <div v-if="step === 'list'">
                <base-table
                    :columns="[
                        {
                            name: 'creator-name',
                            label: 'Creator Name',
                            type: 'avatar-list',
                            value: (element) => {
                                return [element]
                            }
                        },
                        {
                            name: 'creator-name-searchable',
                            label: 'Creator Name Searchable',
                            type: 'text',
                            value: (element) => {
                                return `${element.first_name} ${element.last_name}`
                            },
                            hidden: true
                        },
                        {
                            name: 'user_tags',
                            label: 'Tags',
                            type: 'user-tags',
                            searchBy: customUserTagsSearchBy,
                            filter: {
                                options: tags.map(tag => {return { label: tag.name, value: tag.slug }}),
                                placeholder: 'Tags',
                                type: 'select',
                                allSelectedPlaceholder: 'All Tags'
                            },
                        },
                        {
                            name: 'active-projects',
                            label: 'Active Projects',
                            type: 'text',
                            value: customActiveProjectsValue,
                        },
                        {
                            name: 'creator',
                            label: 'View Creator',
                            type: 'button',
                            secondaryIcon: 'arrow-right',
                            onClick: handleViewCreatorClick,
                            value: (element) => {
                                return 'View Creator'
                            },
                            disabled: (element) => {
                                return !element.user_projects.length
                            },
                        }
                    ]"
                    :data="currentCreators"
                    :empty-text="''"
                    :initialSelectedElements="computedInitialSelectedElements"
                    :toggled-element="toggledUser"
                    :bulkActions="bulkActions"
                    @select-element="toggleElements"
                    @changePage="changePage"
                    :loading="loading"
                    :page="currentTab === 'all' ? currentPage : undefined"
                    :internal-pagination="currentTab === 'all'"
                    :hide-headers="currentTab === 'new' && !newItems.length"
                    :total="currentCreators.length"
                    :key="creatorsKey"
                    :per-page="10"
                >
                    
                    <!-- tabs & new creator button -->
                    <template v-if="!hideCreate" v-slot:tabs-section>
                        <div class="flex justify-between">
                            <tabs :tabs="['all', 'new']" v-model="currentTab" capitalize custom-class="ml-12 sm:ml-8"/>
                            <base-button v-if="currentTab !== 'new'" label="New Creator" @action="currentTab = 'new'"/>
                        </div>
                    </template>
                    
                    <!-- new influencer form -->
                    <template v-slot:table-footer v-if="!hideCreate && currentTab === 'new'">
                        <div class="w-2/3 mt-6">
                            <create-influencer-form
                                :tags="mappedTags"
                                inline
                                assigningToProject
                                :projectId="projectSlug ? projectSlug : null"
                                @created-influencer="reloadInfluencers"
                                @filling="fillingCreate"
                                @cancel-form="cancelCreate"
                                @toggle-existent="toggleExistent"
                            />
                        </div>
                    </template>

                </base-table>
            </div>

            <!-- overview step -->
            <div v-if="step === 'resume'">
                <div>The following users will be assigned to the project.</div>
                <base-table
                    :columns="[
                        {
                            name: 'creator-name',
                            label: 'Creator Name',
                            type: 'avatar-list',
                            value: (element) => {
                                return [element]
                            }
                        },
                        {
                            name: 'creator-name-searchable',
                            label: 'Creator Name Searchable',
                            type: 'text',
                            value: (element) => {
                                return `${element.first_name} ${element.last_name}`
                            },
                            hidden: true
                        },
                        {
                            name: 'creator',
                            label: 'View Creator',
                            type: 'button',
                            secondaryIcon: 'arrow-right',
                            onClick: handleViewCreatorClick,
                            value: (element) => {
                                return 'View Creator'
                            },
                            disabled: (element) => {
                                return !element.user_projects.length
                            },
                        }
                    ]"
                    :bulkActions="[]"
                    :data="selectedCreators"
                    :initialSelectedElements="[]"
                    :empty-text="'There are no selected users'"
                    @changePage="changePage"
                    :loading="false"
                    :search="false"
                    :filtering="false"
                    :hide-headers="true"
                    :key="creatorsKey"
                />
            </div>
            
            <!-- footer -->
            <div class="footer">
                <base-button 
                    @action="cancel" 
                    type="secondary" 
                    :disabled="disabledActions" 
                    class="bg-tan-m" 
                    size="sm" 
                    :label="step === 'resume' ? 'Back' : 'Cancel'"
                />
                <base-button 
                    @action="confirm"
                    :disabled="disabledActions || !selectedCreators.length"
                    :label="step === 'resume' ? 'Confirm' : 'Continue'"
                />
            </div>

        </div>

    </base-modal>
</template>

<script>
import CreateInfluencerForm from './CreateInfluencerForm';

export default {
    props: {
        visible: {},
        title: {},
        loading: {},
        creators: {},
        initialSelectedElements: {},
        projectSlug: {},
        tags: {},
        hideCreate: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CreateInfluencerForm
    },
    data() {
        return {
            selectedCreators: [],
            newItems: [],
            currentTab: 'all',
            step: 'list',
            currentPage: 1,
            toggledUser: null,
            creatorsKey: false,
            isCreating: false
        }
    },
    watch: {
        visible(val){
            if(val == true && this.creators.length <= 0){
                this.$emit('reload-creators')
            }
        }
    },
    computed: {
        mappedTags() {
            return this.tags.map(tag => {
                return {
                    value: tag.id,
                    label: tag.name
                }
            })
        },
        currentCreators() {
            if (this.currentTab === 'all') {
                return this.creators;
            }

            return this.newItems;
        },
        bulkActions() {
            return this.loading ? [] : [{ name: 'select' }];
        },
        computedInitialSelectedElements() {
            return this.initialSelectedElements.concat(this.selectedCreators.map(e => e.id));
        },
        disabledActions() {
            return this.currentTab === 'new' && this.isCreating;
        }
    },
    methods: {
        toggleExistent(user) {
            let item = this.selectedCreators.find(i => i.id === user.id);

            let current = this.creators.find(c => c.id === user.id);
            if (!item && current) {
                this.toggledUser = current;
                setTimeout(() => {
                    this.toggledUser = null;
                }, 150);
            }
            this.currentTab = 'all';
        },
        fillingCreate(status) {
            this.isCreating = status;
        },
        cancelCreate() {
            this.currentTab = 'all';
            this.isCreating = false;
        },
        changePage(page) {
            this.currentPage = page;
        },
        toggleElements(selectedElements) {

            let elements = []
            elements = selectedElements;

            // reset new items deselected state
            this.newItems = this.newItems.map(element => {
                element.deselected = false;
                return element;
            });

            if(this.currentTab === 'new'){
                
                // newItems elements that are not on selectedElements will be tagged with "deselected"
                for (const newItem of this.newItems) {
                    const found = selectedElements.find(element => element.id == newItem.id)
                    if(!found){
                        const index = this.newItems.indexOf(newItem)
                        this.newItems[index].deselected = true
                    }
                }

                // add creators from "all", dont include element from newItems
                for (const selectedCreator of this.selectedCreators.filter(element => !!this.newItems.find(el => el.id !== element.id))) {
                    elements.push(selectedCreator)
                }

            }else{
                // in "all" tab add newItems that have actually been selected
                for (const newItem of this.newItems) {
                    if(!newItem.deselected){
                        elements.push(newItem)
                    }
                }
            }

            let items = [];
            // prevent duplicates
            for (let element of elements) {
                let item = items.find(i => i.id === element.id);
                if (!item) {
                    items.push(element);
                }
            }

            this.selectedCreators = items;
        },
        reloadInfluencers(user, newItem = true) {
            if(newItem) this.newItems.push(user)
            this.selectedCreators.push(user);
            this.isCreating = false;
            this.creatorsKey = !this.creatorsKey;
        },
        customActiveProjectsValue(element){
            let user_projects = element.user_projects
            if(user_projects && user_projects.length > 0){
                return user_projects.filter(user_project => {
                    let project = user_project.project
                    if(project){
                        let status = project.project_status
                        if(status){
                            return status.name.toLowerCase() != 'completed'
                        } 
                    }
                    return false
                }).length
            }else{
                return 0
            }
        },
        handleViewCreatorClick(element){
            if(element.user_projects.length){
                let found = element.user_projects.find(element => {
                    return (element.project && element.project.slug) 
                })
                if(found){
                    let route = this.$router.resolve({path: `/projects/${found.project.slug}/creator/${element.id}`});
                    window.open(route.href, '_blank'); 
                } 
            }
        },
        customUserTagsSearchBy(element){
            const {user_tags = []} = element
            let str = ``
            user_tags.forEach(user_tag => {
                str = str + user_tag.tag.slug + ' '
            }); 
            return str            
        },
        cancel() {
            if (this.step === 'resume') {
                this.step = 'list';
            } else {
                this.$emit('close');
            }
        },
        async confirm() {
            if (this.step === 'resume') {
                this.$emit('confirm-selected-creators', this.selectedCreators);
                this.step = 'list';
                this.$emit('close');
            } else {
                this.step = 'resume';
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
    }
    .footer{
        @apply sticky bottom-0 bg-white py-3 mt-4 flex gap-3 justify-end;
    }
</style>