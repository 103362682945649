var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user && _vm.profileCompletion && !_vm.profileCompletion.completed)?_c('div',{staticClass:"profile-completion"},[_c('div',{staticClass:"left"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v("This creator's profile is incomplete.")]),_c('div',{staticClass:"message"},[_vm._v(_vm._s(_vm.profileCompletion.adminMessage))]),_c('div',{staticClass:"message"},[_vm._v("You can send them an invite to complete their account.")])]),(_vm.profileCompletion.redirect)?_c('base-button',{attrs:{"label":"Send Invite","size":"lg"},on:{"action":function($event){return _vm.sendInvite()}}}):_vm._e()],1),_c('div',{staticClass:"right"},[(_vm.isValidString(_vm.profileCompletion.percentage))?[_c('div',{staticClass:"label"},[_c('div',{staticClass:"percentage"},[_vm._v(_vm._s(_vm.profileCompletion.percentage))]),_c('div',{staticClass:"text"},[_vm._v("Completed")])]),_c('div',{staticClass:"chart"},[_c('doughnut',{attrs:{"chartData":{
                        labels: ['Female', 'Male'],
                        datasets: [
                                {
                                    data: [
                                        parseInt(_vm.profileCompletion.percentage.replace('%','')), 
                                        100 - parseInt(_vm.profileCompletion.percentage.replace('%',''))
                                    ],
                                    backgroundColor: [
                                        '#70F880',
                                        '#FFFFFF'
                                    ]
                                }
                            ]
                        },"options":{
                            legend: { 
                                display: false 
                            },
                            tooltips: {
                                enabled: false
                            },
                            cutoutPercentage: 50
                        }}})],1)]:_vm._e()],2)]):_vm._e(),_c('send-invite-modal',{attrs:{"visible":_vm.sendInviteModalVisible,"network-id":_vm.inviteNetworkId,"user":_vm.user},on:{"close":function($event){_vm.sendInviteModalVisible = false}}}),_c('complete-profile-invite-modal',{attrs:{"visible":_vm.completeProfileInviteModalVisible,"user":_vm.user,"profileCompletion":_vm.profileCompletion},on:{"close":function($event){_vm.completeProfileInviteModalVisible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }