<template>
   <div class="w-full text-sm" :class="{'border rounded-2xl pt-6 pb-3': showProject && project.name !== undefined, 'pb-6': showProject && project.name && !filteredRows.length, 'pb-3': showProject && project.name && filteredRows.length}"> <!-- style="min-height: 550px"> -->
        <div class="flex justify-between items-center px-6" v-if="showProject && project.name !== undefined">
            <base-button
                class="col-span-4"
                type="label"
                justify="start"
                @action="(!disableProjectRedirect && !isGuest && !isClient) ? $router.push(`/projects/${project.slug}`) : null"
            >
               <div class="font-bold text-purple-m-secondary">{{`${ project.name } - ${ project.brand }`}}</div>
            </base-button>
            <div class="flex items-center gap-12 justify-end">
                <div class="flex gap-2">
                    <base-icon name="user"/>
                    <span>{{ filteredRows.length }}</span>
                    <span>Creator{{ filteredRows.length !== 1 ? 's' : '' }}</span>
                </div>
                <div class="flex gap-2">
                    <base-icon name="deliverable-alt"/>
                    <span>
                        {{ 
                          filteredRows.reduce((accumulator, currentRow) => {
                            if (userDeliverables(currentRow)) {
                              return accumulator + userDeliverables(currentRow).length;
                            } else {
                              return accumulator;
                            }
                          }, 0) 
                        }}
                    </span>
                    <span>Deliverables</span>
                </div>
                <base-icon :size="4" v-if="!project.loadingColumns" @action="$emit('collapse-toggle', project)" :name="!project.collapsed ? 'chevron-down' : 'chevron-right'" class="cursor-pointer"/>
                <base-icon name="loader" v-if="project.loadingColumns" />
            </div>
        </div>
        <!-- Filters -->
        <div class="pb-4 flex lg:gap-4 sm:gap-1 sm:items-center sm:justify-between" :class="searchClass" v-if="data.length || hasExternalSrc" :key="refreshKey">
            
            <!-- searchbar  -->
            <div class="flex-shrink w-full" :style="searchbarStyles" v-if="search">
                <div class="relative flex w-full">
                    <div class="absolute w-16 h-full flex items-center justify-center pointer-events-none">
                        <base-icon name="search" class="text-purple-m-secondary" />
                    </div>
                    <FormulateInput
                        type="text"
                        v-model="filters.search.selected"
                        v-if="filters && filters.search"
                        placeholder="Search"
                        outer-class="w-full"
                        element-class="w-full"
                        :disabled="loading"
                        wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                        v-debounce:1000="setFilter"
                    />
                </div>
            </div>
            
            <!-- all other filters -->
            <template v-for="(filter, index) in filters">
                <div v-if="filter.type === 'select'" :key="filter.type + '_' + index" class="flex-grown w-full">
                    <custom-select
                        class="w-full"
                        :label="filter.placeholder"
                        :placeholder="filter.placeholder"
                        :options="filter.options"
                        v-model="filter.selected"
                        prevent-mounted-emit
                        :key="'c_s_' + index"
                        @input="setFilter"
                    />
                </div>
            </template>

            <div class="flex justify-between w-full" v-if="filters.length <= 1 && (filtering || search)">
                <base-button @action="cleanFilters()" type="label" label="Clear"/>
            </div>

        </div>
        
        <slot name="table-header"></slot>

        <slot name="filters-footer"></slot>

        <slot name="tabs-section"></slot>

        <div v-if="(showProject && !(!!project.collapsed)) || !showProject" :id="containerId" class="relative" :style="containerStyles">
            <loader :visible="reload" fixed :opacity="50"/>
            <!-- Loading State -->
            <table class="bg-white" :class="tableClass" v-if="loading">
                <tbody>
                    <tr v-for="i in 10" :key="'sk' + i" class="h-15 border-b border-gray-m-light">
                        <td>
                            <div class="grid justify-items-center">
                                <skeleton :width="5"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td>
                            <div class="grid justify-items-center">
                                <skeleton type="circle" :width="5"/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <draggable class="bg-white" :class="tableClass" tag="table" :id="tableId" :disabled="!isDraggable" @start="onDragStart()" handle=".handle" v-model="filteredRows" v-else>
                <!-- body from loader -->
                <tbody v-for="(element, index) in filteredRows" :key="'frb_' + index" class="base-table-body">
                    <tr
                        v-if="element.target_column && typeof element.target_column.target != 'undefined' && element.target_column.target == 'pricing_data'"
                        class="bg-blue-100 h-10 border-l-4 border-blue-500 text-blue-700 p-4"
                      >
                        <td class="pl-5" :colspan="columns.length + 1">
                            <p><i><b>{{element.target_column.label}}</b> is a smart field which can organize your pricing data and add it to your CRM accordingly. If something doesn't look right, you might need to adjust your naming to conform with our <a>standardized structure</a>.</i></p>
                        </td>
                    </tr>

                    <tr v-if="element.target_column && invalidRows.has(element.target_column.name) && (element.target_column.target == 'email' || element.target_column.type == 'INT')" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                      <td :colspan="columns.length + 1">
                        <div class="py-6 px-6"><i class=""><b>{{element.target_column.label}}</b> must be a{{element.target_column.type == 'INT' ? ' number' : 'n email and it is required'}}.</i></div>
                      </td>
                    </tr>

                    <tr
                        :key="element.id || 'frr' + index"
                        :id="`tr-${element.id}`"
                        :class="rowClass(element) + ` ${index < (filteredRows.length - 1) || !!element.showUserDeliverables ? 'border-b' : ''} focus:bg-green-500 ${onRowClick ? `cursor-pointer hover:bg-gray-m-extra-light transition duration-300` : ``}`"
                        @click="onRowClick ? onRowClick(element) : null"
                    >

                        <!-- checkbox -->
                        <td v-if="bulkActionsEnabled">
                            <div class="flex justify-center items-center h-6 w-6 mx-3" style="margin-top: 3px" >
                                <FormulateInput
                                    :options="[{value: element.id}]"
                                    type="checkbox"
                                    v-model="selectedElements"
                                />
                            </div>
                        </td>

                        <!-- pinned -->
                        <td v-if="pinningEnabled">
                            <base-button
                                class="ml-auto"
                                @action="$emit('on-toggle-pinned', element.id, element.isPinned)"
                                type="label"
                                icon="flagged"
                                :iconFill="element.isPinned ? true : null"
                            />
                        </td>

                        <td
                            v-for="(column, c_index) in columns"
                            :key="'c_i_td_' + c_index"
                            :class="[
                                'py-6',
                                ['thumbnail'].includes(column.type) ? 'w-12' : (Array.isArray(getValue(column, element) && ['number-format'].includes(column.type)) ? 'px-0' : 'px-6'),
                                column.options && column.options.cellClasses ? column.options.cellClasses : '',
                                column.hidden ? 'hidden' : '',
                                column.rowHelp ? 'flex gap-2 items-center' : '',
                                column.bold && column.bold(element) ? 'font-bold' : ''
                            ]"
                            :style="`${column.options && column.options.cellStyles ? column.options.cellStyles : ``}`"
                            @click="(e) => (column.options && column.options.stopPropagation) ? e.stopPropagation() : null"
                        >


                            <!-- avatar -->
                            <template v-if="column.type == 'avatar'">
                                <avatar
                                    class="cursor-pointer max-w-none h-6 w-6 rounded-full text-white shadow-solid"
                                    v-tooltip="{ content: `${getValue(column, element).first_name} ${getValue(column, element).last_name}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                    :user="getValue(column, element).user"
                                    size="small"
                                />
                            </template>

                            <!-- tooltip -->
                            <template v-if="column.type == 'tooltip'">
                                <div :class="column.containerClass" v-if="column.visible ? column.visible(element) : true">
                                    <base-icon
                                        :name="column.icon"
                                        :class="column.iconClass"
                                        v-tooltip="{ content: column.text, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                        @action="column.onClick ? column.onClick(element) : null"
                                    />
                                </div>
                            </template>

                            <!-- avatar list -->
                            <template v-else-if="column.type == 'avatar-list'">
                                <div class="flex gap-4 items-center" style="max-width: 200px">
                                    <base-icon name="sorter" class="cursor-move handle" v-if="isDraggable"/>
                                    <div v-if="!isArray(getValue(column, element))" class="inline-flex gap-2 items-center">
                                        <span :class="['inline-flex items-center justify-center sm h-6 w-6 rounded-full bg-gray-m']">
                                            <span :class="['text-white text-2xs']">UA</span>
                                        </span>
                                        <span class="text-sm text-gray-m-disable">Unassigned</span>
                                    </div>

                                    <avatar-list v-else
                                        :class="column.extraClasses ? column.extraClasses : ''"
                                        :users="getValue(column, element)"
                                        :showAmount="5"
                                        :showExtra="true"
                                    />
                                </div>
                            </template>

                            <!-- avatar list -->
                            <template v-else-if="column.type == 'thumbnail'">
                                <img :src="getValue(column, element)" class="h-10 w-10 rounded-full object-cover" @error="setAltImg"/>
                            </template>

                            <!-- project status -->
                            <template v-else-if="column.type == 'status'">
                                <div class="flex flex-wrap justify-center w-44">
                                    <crud-dropdown
                                        :value="{
                                            name: element.statusLabel,
                                            id: element.status
                                        }"
                                        :statuses="statuses"
                                        :create="{
                                            route: '/api/statuses',
                                            success: 'Status created successfully',
                                            error: 'Failed to create new status',
                                            warning: 'Status name required'
                                        }"
                                        :select="{
                                            route: `/api/projects/${element.id}/status`,
                                            success: 'Status updated successfully',
                                        }"
                                        :edit="{
                                            route: '/api/statuses/',
                                            success: 'Status updated successfully',
                                            error: 'Failed to edit status',
                                            warning: 'Status name required',
                                        }"
                                        :delete="{
                                            deleteLabel: 'status',
                                            route: '/api/statuses/',
                                            success: 'Status deleted successfully',
                                            error: 'Failed to delete status'
                                        }"
                                        :disabled="statusesLoading"
                                        @done="$emit('refresh-data')"
                                        @toggle-dropdown="runScroll"
                                    />
                                </div>
                            </template>

                            <!-- price -->
                            <template v-else-if="column.type == 'price'">
                                <div
                                    class="text-purple-m-secondary text-ps"
                                    @click="column.onClick ? column.onClick(element) : null"
                                >
                                    {{ getValue(column, element) | numeral('$0,0.00') }}
                                </div>
                            </template>

                            <!-- link -->
                            <template v-else-if="column.type == 'link'">
                                <div
                                    class="cursor-pointer truncate hover:text-purple-m-secondary text-purple-m-main font-bold text-h6"
                                    @click="column.onClick ? column.onClick(element) : null"
                                    v-tooltip="{ content: getValue(column, element), classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 600, hide: 50 } }"
                                >
                                    {{ getValue(column, element) }}
                                </div>
                            </template>

                            <!-- button -->
                            <template v-else-if="column.type == 'button'">
                                <base-button 
                                    @action="column.onClick ? column.onClick(element) : null" 
                                    :disabled="!column.disabled ? false : typeof column.disabled == 'boolean' ? column.disabled : column.disabled(element)"
                                    :secondaryIcon="column.secondaryIcon" 
                                    type="secondary"
                                    theme="dark" 
                                    size="lg" 
                                    class="font-bold" 
                                    padding="p-2"
                                >
                                    {{ getValue(column, element) }}
                                </base-button>
                            </template>

                            <div class="flex flex-actions" v-else-if="column.type == 'actions'">
                                <base-button
                                    v-for="(action, act_key) in column.actions"
                                    :key="act_key"
                                    size="md"
                                    type="secondary"
                                    theme="dark"
                                    text-class="text-xs font-bold"
                                    :label="action.label"
                                    @action="action.onClick ? action.onClick(element) : null"
                                    :disabled="!action.disabled ? false : typeof action.disabled == 'boolean' ? action.disabled : action.disabled(element)"
                                />
                            </div>

                            <!-- share project assign button -->
                            <template v-else-if="column.type == 'assign-button'">
                                <base-button 
                                    @action="column.onClick ? column.onClick(element, getValue(column, element)) : null"
                                    :type="getValue(column, element) ? 'secondary' : 'main'" 
                                    :theme="getValue(column, element) ? 'dark' : 'light'"
                                    size="md"
                                    :disabled="column.disabled ? column.disabled : false"
                                    class="font-bold float-right" 
                                    padding="p-2"
                                >
                                    {{ getValue(column, element) ? 'Remove' : 'Add' }}
                                </base-button>
                            </template>

                            <!-- icon -->
                            <template v-else-if="column.type == 'single-icon'">
                                <div @click="column.onClick ? column.onClick(element) : null" :class="column.onClick ? 'cursor-pointer' : ''">
                                    <base-icon :name="column.icon"/>
                                </div>
                            </template>


                            <!-- role list -->
                            <template v-else-if="column.type == 'role-list'">
                                <div class="flex gap-x-2 items-center justify-start">
                                    <div 
                                        v-for="(role, r_index) in getValue(column, element)" 
                                        :key="'role-list' + r_index" 
                                        class="bg-cool-gray-100 px-3 py-1 rounded overflow-hidden whitespace-no-wrap"
                                        :style="`${role.color ? `background-color: ${role.color}` : ``}`"
                                    >
                                        {{ role.display_name }}
                                    </div>
                                </div>
                            </template>

                            <template v-else-if="column.type === 'single-select'">
                                <div :style="`${column.width ? `width: ${column.width}` : ``}`">
                                    <custom-select
                                        :value="typeof column.options === 'function' ? column.value(element, column) : element[column.name][0]"
                                        :options="typeof column.options === 'function' ? column.options(element, column) : column.options"
                                        :placeholder="column.placeholder"
                                        :multiple="false"
                                        v-tooltip="( column.disabled && column.disabledTooltip ) ? { content: `${column.disabledTooltip}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"
                                        :disabled="!column.disabled ? false : typeof column.disabled == 'boolean' ? column.disabled : column.disabled(element)"
                                        :key="element.id + '_cs_' + c_index"
                                        size="sm"
                                        :type="column.style ? column.style : 'select'"
                                        @changed="(val) => {
                                            return selectCheck(element, column, val)
                                        }"
                                    />
                                </div>
                            </template>

                            <!-- date -->
                            <template v-else-if="column.type == 'date'">
                                <div>
                                    <span v-if="getValue(column, element) !== null">{{ getValue(column, element) | moment('MMM D, Y') }}</span>
                                    <span v-else>-</span>
                                </div>
                            </template>

                            <template v-else-if="column.type === 'number'">
                                <div v-if="helpIsNumber(getValue(column, element))">{{ getValue(column, element) | numeral('0,0') }}</div>
                                <div v-else>-</div>
                            </template>

                            <template v-else-if="column.type === 'number-format'">
                                <!-- we have a wrapper for this column type because we have the boosted-stat-breakdown-tooltip, which requires -->
                                <!-- a wrapper that has the "relative" class -->
                                <div 
                                    class="number-format" 
                                    :class="{ 'cursor-pointer': typeof column.isBoosted === 'function' && column.isBoosted(element) }"
                                >
                                    <!-- boosted number functionality -->
                                    <template v-if="typeof column.isBoosted === 'function' ? column.isBoosted(element) : false">
                                        <div class="absolute" style="top: -12px;left: -10px;">
                                            <base-icon
                                                size="3"
                                                class="text-green-500"
                                                name="boosted"
                                            />
                                        </div>
                                        <!-- this class is used to hide or show the tooltip -->
                                        <div
                                            class="boosted-stat-breakdown-tooltip-container"
                                            v-if="typeof column.breakdown === 'function' && column.breakdown(element)"
                                        >
                                            <boosted-stat-breakdown-tooltip
                                                :value="column.breakdown(element)"
                                            />
                                        </div>
                                    </template>
                                    
                                    <div class="comparition-table-item flex items-center gap-2" v-if="Array.isArray(getValue(column, element))">
                                        <div
                                            v-for="(val, v_key) in getValue(column, element)"
                                            @click="column.onClick ? column.onClick(element) : null"
                                            :key="v_key"
                                            class="comp-item uppercase flex items-center"
                                            :class="{'cursor-pointer': column.onClick}"
                                        >{{ val | numeral('0.0a') }}</div>
                                    </div>
                                    <template v-else>
                                        <div v-if="helpIsNumber(getValue(column, element))" class="uppercase">{{ getValue(column, element) | numeral('0.0a') }}</div>
                                        <div v-else>-</div>
                                    </template>
                                </div>
                            </template>

                            <!-- user tags -->
                            <template v-else-if="column.type == 'user-tags'">
                                <tags :tags="getValue(column, element)" :limit="column.limit !== undefined ? column.limit : 1"/>
                            </template>

                            <!-- checkbox -->
                            <template v-else-if="column.type == 'checkbox'">
                                <FormulateInput
                                    type="checkbox"
                                    :decorator-class="['cursor-pointer']"
                                    :checked="getValue(column, element)"
                                    @click.stop="toggleCheckbox"
                                />
                            </template>

                            <!-- reports -->
                            <template v-else-if="column.type == 'reports'">
                                <div v-if="!isArray(getValue(column, element))">-</div>
                                <div 
                                    v-else 
                                    v-tooltip="{ 
                                        html: true,
                                        content: `<div>${getValue(column, element).map( report => report.name ).join('<br>')}</div>`, 
                                        classes: 'bg-white rounded py-1 px-2 text-xs' 
                                    }"
                                    class="flex items-center"
                                >
                                    <div>{{getValue(column, element).length}}</div>
                                </div>
                            </template>

                            <!-- locations -->
                            <template v-else-if="column.type == 'audience-data-location'">
                                <div v-if="!isArray(getValue(column, element))">-</div>
                                <div 
                                    v-else 
                                    v-tooltip="{ 
                                        html: true,
                                        content: `<div>${getValue(column, element).map( element => `${element.network} - ${element.value}` ).join('<br>')}</div>`, 
                                        classes: 'bg-white rounded py-1 px-2 text-xs' 
                                    }"
                                    class="flex items-center"
                                >
                                    <div>{{getValue(column, element)[0].value}}</div>
                                    <div v-if="getValue(column, element).length > 1" class="flex items-center ml-1">
                                        <base-icon name="plus" :size="3"/>
                                        <div>{{getValue(column, element).length - 1 }}</div>
                                    </div>
                                </div>
                            </template>

                            <!-- networks -->
                            <template v-else-if="column.type == 'networks'">
                                <networks
                                    v-if="Array.isArray(getValue(column, element))"
                                    :selector="column.selector"
                                    :currentValues="column.currentValues ? column.currentValues : []"
                                    :currentAccounts="column.currentAccounts ? column.currentAccounts(element) : []"
                                    :user="column.user ? column.user(element) : null"
                                    size="small"
                                    :accounts="getValue(column, element)"
                                    :check-connection="(column.options && column.options.checkNetworkConnection) ? column.options.checkNetworkConnection : false"
                                    @set-accounts="(accounts) => { $emit('set-accounts', element, accounts) }"
                                />
                                <p v-else>{{ getValue(column, element) }} 
                                    <base-icon
                                        v-if="getValue(column, element) == 'Queued...'"
                                        name="info-circle"
                                        class="ml-4"                                          
                                        v-tooltip="{ 
                                            content: `If its taking more than 5 minutes to get the data, consider inviting the user to authenticate with the platform or manually adding the posts.`, 
                                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                            delay: { show: 100, hide: 50 } 
                                        }" 
                                    /> 
                                </p>
                            </template>

                            <!-- network-selector -->
                            <template v-else-if="column.type == 'network-selector'">
                                <base-table-column-network-selector
                                    :value="getValue(column, element)"
                                    :social-accounts="column.socialAccounts ? column.socialAccounts(element) : []"
                                    :user="column.user ? column.user(element) : null"
                                    :readonly="column.onUpdateValue ? false : true"
                                    @update:value="val => column.onUpdateValue ? column.onUpdateValue(column, element, val) : null"
                                />
                            </template>

                             <!-- deliverables -->
                            <template v-else-if="column.type == 'deliverables'">
                                <div class="inline-flex h-4 justify-start gap-4">
                                    <div
                                        v-if="element.hasOwnProperty('showUserDeliverables')"
                                        v-tooltip="{ 
                                            content: `${element.showUserDeliverables ? 'Hide deliverables' : 'Show deliverables'}`, 
                                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                            delay: { show: 100, hide: 50 } }"
                                        @click="(e)=> toggleShowUserDeliverables(element, e)"
                                    >
                                        <base-icon :size="4" :name="element.showUserDeliverables ? 'collapse' : 'expand'" />
                                    </div>
                                    <base-icon v-else name="deliverable-alt" />
                                    {{ getValue(column, element) }}
                                </div>
                            </template>

                            <!-- rating -->
                            <template v-else-if="column.type == 'rating'">
                                <div>
                                    <div v-if="!getValue(column, element)">No ratings</div>
                                    <star-rating
                                        v-else
                                        :rating="getValue(column, element)" 
                                        :increment="0.5"
                                        :star-size="20"
                                        :read-only="true"
                                        text-class="font-bold"
                                    />
                                </div>
                            </template>

                            <template v-else-if="column.type == 'toggle-open'">
                                <div @click="$emit('toggle-open', element, index)" class="cursor-pointer">
                                    <base-icon :name="getValue(column, element) ? 'chevron-up' : 'chevron-down'"/>
                                </div>
                            </template>

                            <template v-else-if="column.type === 'email'">
                                <div class="flex gap-2 items-center">
                                    <div :class="[{'cursor-pointer': column.onClick !== undefined}, column.columnClass !== undefined ? column.columnClass(element) : 'text-purple-m-secondary text-ps']" @click="column.onClick ? column.onClick(element) : null">
                                        {{ getValue(column, element) }}
                                    </div>
                                    <base-icon name="clipboard-copy" v-if="column.copy"
                                       @action="copyText(getValue(column, element), 'Email copied to clipboard')"
                                       v-tooltip="{ content: `Copy email to clipboard`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                    />
                                </div>
                            </template>

                            <template v-else-if="column.type === 'html'">
                                <div class="enriched-format" v-html="getValue(column, element)"/>
                            </template>

                            <!-- single network -->
                            <!-- this column type is only being used in /views/CreatorDetail/AudienceTab -->
                            <template v-else-if="column.type == 'network'">
                                <div class="flex items-center gap-x-4">
                                    <!-- icon -->
                                    <base-icon size="8" :name="getValue(column, element).name"/>
                                    <!-- name -->
                                    <div class="font-bold">
                                        {{ getValue(column, element).display_name }}
                                    </div>
                                </div> 
                            </template>

                            <!-- active status -->
                            <!-- this column type is only being used in /views/CreatorDetail/AudienceTab -->
                            <template v-else-if="column.type == 'active-status'">
                                <div style="width: 88px" class="flex items-center gap-x-2">
                                    <!-- dot -->
                                    <div
                                        v-if="typeof column.color === 'function'"
                                        size="8" 
                                        :class="`w-3 h-3 rounded-full ${ isValidString(column.color(element)) ? column.color(element) : `` }`" 
                                    />
                                    <!-- text -->
                                    <div class="font-bold">
                                        {{ getValue(column, element) }}
                                    </div>
                                </div> 
                            </template>

                            <!-- invitation media count -->
                            <!-- this column type is only being used in /views/CreatorDetail/AudienceTab -->
                            <template v-else-if="column.type == 'invitation-media-count'">
                                <div class="flex items-center gap-x-4">
                                    <!-- icon -->
                                    <base-icon
                                        v-if="getValue(column, element) !== 0"
                                        size="8" 
                                        name="photo" 
                                    />
                                    <!-- count -->
                                    <div class="font-bold">
                                        {{ getValue(column, element) !== 0 ? `${getValue(column, element)} file${getValue(column, element) == 1 ? '' : 's'} uploaded` : 'No files uploaded yet' }}
                                    </div>
                                </div> 
                            </template>

                            <!-- this column type is only being used in the ReportImport view, CreatorList / Overview steps -->  
                            <base-table-column-handle
                                v-else-if="column.type === 'handle'" 
                                :value="getValue(column, element)"
                                :network="element.target_network"
                                :verify-handle="column.verifyHandle"
                            />

                            <!-- this column type is only being used in the ReportImport view, CreatorList / Overview steps -->  
                            <base-table-column-long-text
                                v-else-if="column.type === 'long-text'"
                                :value="getValue(column, element)"
                                :label="column.label"
                            />

                            <!-- this column type is specific for the ReportImport view, CreatorList / Overview steps-->  
                            <base-table-column-user-importable-status
                                v-else-if="column.type === 'user-importable-status'"
                                :value="getValue(column, element)"
                                :creator="element"
                                @update:user="val => column.onUpdateUser ? column.onUpdateUser(column, element, val) : null"
                            />

                            <!-- this column type is specific for the ReportImport view, CreatorList / Overview steps -->
                            <!-- the "handle" property is expected to be set in the element object -->
                            <!-- the "target_network" property is also expected to be set in the element object -->
                            <base-table-column-featured-posts
                                v-else-if="column.type === 'featured-posts'" 
                                :value="getValue(column, element)"
                                :handle="element.handle"
                                :network="element.target_network"
                                :readonly="column.onUpdateValue ? false : true"
                                @update:value="val => column.onUpdateValue ? column.onUpdateValue(column, element, val) : null"
                            />

                            <!-- this column type is specific for the ReportImport view, CreatorList / Overview steps -->
                            <base-table-column-additional-info
                                v-else-if="column.type === 'additional-info'" 
                                :value="getValue(column, element)"
                                :readonly="column.onUpdateValue ? false : true"
                                @update:value="val => column.onUpdateValue ? column.onUpdateValue(column, element, val) : null"
                            />

                            <!-- this column type is specific for the ReportCreate view -->
                            <base-table-column-post-show-type
                                v-else-if="column.type === 'post-show-type'" 
                                :value="getValue(column, element)"
                                :readonly="column.onUpdateValue ? false : true"
                                @update:value="val => column.onUpdateValue ? column.onUpdateValue(column, element, val) : null"
                            />

                            <div v-else-if="column.type === 'report-type'">
                                <div v-if="getValue(column, element) && isNumber(getValue(column, element))">
                                    <div v-if="getValue(column, element) == 1">Creators report</div>
                                    <div v-else-if="getValue(column, element) == 2">Content report</div>
                                    <div v-else-if="getValue(column, element) == 3">Brand report</div>
                                    <div v-else>-</div>
                                </div>
                                <div v-else> - </div>
                            </div>

                            <template v-else-if="typeof getValue(column, element) === 'object'">
                                <code class="language-js" v-html="formatData(getValue(column, element))"></code>
                            </template>

                            <span v-else-if="column.name === 'sample' && element.target_column && element.target_column.name && Array.isArray(loadingRows) && loadingRows.includes(element.target_column.name)">Loading...</span>

                            <!-- OTHER TYPES ... -->

                            <!-- default string -->
                            <template v-else>
                                <div :class="[column.span !== undefined ? 'flex flex-col gap-1' : '', {'cursor-pointer': column.onClick !== undefined}, column.columnClass !== undefined ? column.columnClass(element) : 'text-purple-m-secondary text-ps']" @click="column.onClick ? column.onClick(element) : null">
                                    {{ getValue(column, element) }}
                                    <span @click="column.span.action ? column.span.action(element) : null" v-if="column.span && ((column.span.visible ? column.span.visible(element) : true))" :class="column.span.class">{{ column.span.text }}</span>
                                </div>
                            </template>

                            <template v-if="column.rowHelp && column.rowHelp(element)">
                                <base-icon
                                    class="text-gray-600"
                                    v-tooltip="{ content: column.rowHelp(element), classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                    name="info-circle"
                                />
                            </template>

                        </td>                        
                        <!-- project columns -->

                        <!-- project.project_users vs project.user_projects THIS IS A NAMING MISMATCH FROM WHAT ELASTIC IS CURRENTLY RETURNING WHICH ISN'T GREAT
                        SHOULD PROBABLY FIX THIS TO ELIMINATE CONFUSION -->
                        <template v-if="project && project.project_users && project.project_user_column_names">
                            <td v-for="(columnName, i) in project.project_user_column_names" :key="'coln_' + i" class="py-6 h-full" @click="(e) => e.stopPropagation()">
                                <div class="flex flex-wrap">
                                    <div class="w-full mb-2">
                                        
                                        <div v-if="element && element.project_user_column_values && element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ).edit">
                                            <FormulateInput
                                                type="text"
                                                outer-class="w-full"
                                                :id="`input-${element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ).id}-${project.id}`"
                                                element-class="w-full"
                                                wrapper-class="border rounded-md py-2 px-3 bg-white h-16 flex items-center"
                                                input-class="w-full focus:outline-none text-purple-m-secondary resize-none bg-transparent h-full"
                                                placeholder="empty"
                                                v-model="element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ).data"
                                                @keyup.enter="$event.target.blur()"
                                                @blur="$emit('edit-user-column-value', element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ), project)"
                                                
                                            />
                                        </div>
                                        <base-button
                                            v-else
                                            @action="focusOnInput(element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ), project)"
                                            type="label"
                                            justify="start"
                                            rounded="lg"
                                            class="w-full text-left h-full px-6 hover:bg-tan-m button justify-start items-center gap-3 rounded-lg text-h6 font-base border focus:outline-none transition duration-150 ease-in-out flex w-36 w-auto border-transparent text-purple-m-main hover:text-purple-m-secondary focus:text-purple-m-secondary"
                                        >

                                            {{element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ).data != "" ? element.project_user_column_values.find( value => value.project_user_column_name_id == columnName.id ).data : "-" }}

                                        </base-button>
                                    </div>
                                </div>
                            </td>
                        </template>
                        
                        <!-- Show Hide Deliverables Button -->
                        <!-- <td 
                            v-if="element.hasOwnProperty('showUserDeliverables')" 
                            @click="(e) => e.stopPropagation()"
                            :class="`w-12 ${fixedRowActions ? `sticky-table-cell right-20 pl-1` : ``}`"
                            :style="`${fixedRowActions ? `z-index: ${filteredRows.length - (index+1)}` : ``}`"
                        >
                            <base-button
                                @action="toggleShowUserDeliverables(element)"
                                :label="`${element.showUserDeliverables ? 'Hide' : 'View'} deliverables`"
                                type="secondary"
                                theme="dark"
                                border
                                bold
                                size="lg"
                                class="mr-2"
                            />
                        </td> -->

                        <!-- row actions -->
                        <td 
                            @click.stop="()=>null" 
                            v-if="rowActionsEnabled"
                            :class="`px-6 py-3 ${fixedRowActions ? `sticky-table-cell right-0` : ``}`"
                            :style="`${fixedRowActions ? `z-index: ${filteredRows.length - (index+1)}` : ``}`"
                        >
                            <div class="relative flex justify-center items-center">
                                <options-dropdown
                                    :options="[...filteredRowActions(element)]"
                                    :external-status="element.isOpen"
                                    v-on="setRowActionsEvents(element)"
                                    :top="actionsPosition === 'top'"
                                    @toggle-status="$emit('toggle-status', element)"
                                />
                            </div>
                        </td>

                        <!-- toggle show deliverables -->
                        <td>
                            <div class="flex h-4 justify-start gap-4">
                                <div
                                    v-if="element.hasOwnProperty('showUserDeliverables')"
                                    v-tooltip="{ 
                                        content: `${element.showUserDeliverables ? 'Hide deliverables' : 'Show deliverables'}`, 
                                        classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                        delay: { show: 100, hide: 50 } }"
                                    @click="(e) => toggleShowUserDeliverables(element, e)"
                                >
                                    <base-icon :size="4" :name="element.showUserDeliverables ? 'chevron-down' : 'chevron-right'" />
                                </div>
                            </div>
                        </td>
                    </tr>
                    
                    <!-- 
                        -------------------------
                            Deliverables table 
                        -------------------------
                    -->
                    <tr v-if="!!element.showUserDeliverables" :key="`${index}-detail`" class="border-l-4 border-purple-m-main">
                        <td :colspan="columns.length + 1 + (project && project.project_users.length && project.project_users && project.project_user_column_names && project.project_user_column_names.length ? project.project_user_column_names.length : 0)">
                            <table class="w-full">
                                <thead class="border-b border-gray-m-light">
                                    <tr class="text-gray-headers text-h6 font-bold h-16">
                                        <th class="w-56 px-6 text-left">Deliverable</th>
                                        <th class="w-56 px-6 text-left">Type</th>
                                        <th class="w-56 px-6 text-left">Stage</th>
                                        <th class="w-56 px-6 text-left">Linked Posts</th>
                                        <th v-if="isAdminOrHigher || isProjectManager" class="w-56 px-6 text-left">Payment</th>
                                        <th class="w-56 px-6 text-left">Due Date</th>

                                        <!-- !!!!!!!!!!!!!!!!!!BEGINNING!!!!!!!!!!!!!!!!!!!!!! -->
                                    <!-- This is the area for dynamic columns within deliverables -->
                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

                                       <!--  <template v-if="element.project_user_deliverables">
                                            <template v-for="(projectUserDeliverablesColumnName, ind) in element.project_user_deliverables">
                                                <template v-if="projectUserDeliverablesColumnName.project_user_deliverable_column_names">
                                                    <template v-for="(ColumnName, innerInd) in projectUserDeliverablesColumnName.project_user_deliverable_column_names">
                                                        <th :key="'spc_' + ind + '_' + innerInd" class="w-56 px-6 text-left">
                                                            <div class="inline-flex gap-2 items-center justify-start w-full">
                                                                {{ColumnName.name}}

                                                            </div>
                                                        </th>
                                                    </template>
                                                </template>
                                            </template>
                                        </template> -->


                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                                    <!-- This is the area for dynamic columns within deliverables -->
                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                                        <!-- !!!!!!!!!!!!!!!!!!ENDING!!!!!!!!!!!!!!!!!!!!!! -->
                                        

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="(userDeliverable, i) in userDeliverables(element)" 
                                        :key="'ud_' + i"
                                        :class="`text-purple-m-secondary text-h6 h-20 border-b border-gray-m-light cursor-pointer hover:bg-gray-m-extra-light transition duration-300`"
                                        @click="!disableDeliverableRedirect ? $router.push(`/deliverables/${userDeliverable.id}`) : null"
                                    >

                                        <!-- deliverable name -->
                                        <td class="px-6">
                                            <base-button
                                                @action="!disableDeliverableRedirect ? $router.push(`/deliverables/${userDeliverable.id}`) : null"
                                                type="label" 
                                                class="w-full" 
                                                justify="start"
                                            >
                                                {{ isValidString(userDeliverable.name) ? userDeliverable.name : (userDeliverable.deliverable && userDeliverable.deliverable.name) ? `${userDeliverable.deliverable.name} Deliverable` : '-' }}
                                            </base-button>
                                        </td>

                                        <!-- deliverable type -->
                                        <td class="px-6">{{ (userDeliverable.deliverables_type && userDeliverable.deliverables_type.label) ? userDeliverable.deliverables_type.label : '-' }}</td>
                                        
                                        <!-- deliverable stage -->
                                        <td class="px-6" @click="(e)=>e.stopPropagation()">
                                            <template v-if="userDeliverable && userDeliverable.phases_progress && userDeliverable.phases_progress.code != null">
                                                <base-button 
                                                    size="sm" 
                                                    :icon-size="3" 
                                                    @action="element.hasOwnProperty('request-post') ? $emit('request-post', userDeliverable, project) : null" 
                                                    type="label"
                                                    bold 
                                                    :icon="element.hasOwnProperty('request-post') ? (userDeliverable.project_user_deliverable_posts.length == userDeliverable.posts_needed) || userDeliverable.phases_progress.code != 30 ? '' : 'plus' : null"
                                                    :label="getUserDeliverableAction(userDeliverable)"
                                                    :label-completion="calculateCompletion(userDeliverable)"
                                                    v-tooltip="userDeliverable.phases_progress.code == 30 && userDeliverable.project_user_deliverable_posts.filter(n => n.post !== null).length >= userDeliverable.posts_needed ? { 
                                                        content: 
                                                            `${userDeliverable.posts_needed == 0 ? `This deliverable doesn't require any linked posts` : 
                                                            `${userDeliverable.project_user_deliverable_posts.length}/${userDeliverable.posts_needed} posts have been linked for this deliverable`}`,
                                                        classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                                        delay: { 
                                                            show: 100, hide: 50 
                                                        } 
                                                    } : {}"
                                                />
                                            </template>
                                        </td>
                                        
                                        <!-- deliverable linked posts -->
                                        <td class="px-6 flex h-inherit items-center gap-2" @click="(e)=>e.stopPropagation()">
                                            <!-- make sure that the linked posts is an array and that the post properties are valid objects -->
                                            <networks
                                                v-if="
                                                    isValidArray(userDeliverable.project_user_deliverable_posts) &&
                                                    isValidArray(userDeliverable.project_user_deliverable_posts.filter(n => isValidObject(n.post)))
                                                "
                                                :currentValues="networks"
                                                :currentAccounts="userDeliverable.project_user_deliverable_posts.map(n => {
                                                    return n.post ? n.post.network_id : 0
                                                })"
                                                individual-action
                                                individual-action-icon="minus-circle"
                                                @individual-action="(account) => $emit('individual-network', userDeliverable, account)"
                                                size="small"
                                                :accounts="userDeliverable.project_user_deliverable_posts.filter(n => {
                                                    return n.post !== null;
                                                }).map(network => {
                                                    return {
                                                        network_id: network.post ? network.post.network_id : 0,
                                                        direct: true,
                                                        handle: network.post ? network.post.profile_item.username : undefined,
                                                        social_url: network.post ? network.post.url : ''
                                                    }
                                                })"
                                            />
                                            <span v-else><small><i>No posts linked</i></small></span>
                                        </td>

                                        <!-- deliverable payment -->
                                        <template v-if="isAdminOrHigher || isProjectManager">
                                            <td class="px-6 font-bold" v-if="!!element.fixed_amount">{{ (element.fixed_amount / userDeliverables(element).length) | numeral('$0,0') }}</td>
                                            <td class="px-6" v-else-if="userDeliverable.budget">{{ userDeliverable.budget | numeral('$0,0') }}</td>
                                            <td class="px-6" v-else ><small><i>No budget yet</i></small></td>
                                        </template>

                                        <!-- deliverable due date -->
                                        <td class="px-6" v-if="!!userDeliverable.end_date"><time datetime="2020-11-18">{{ userDeliverable.end_date | moment('M/D/Y') }}</time></td>
                                        <td class="px-6" v-else><small><i>No due date yet</i></small></td>
                                        
                                        <!-- !!!!!!!!!!!!!!!!!!BEGINNING!!!!!!!!!!!!!!!!!!!!!! -->
                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                                                        <!-- deliverables columns -->
                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

                                        <!-- <template v-if="showProjectColumns && project && project.project_users && project.project_users.length > 0">
                                            <template v-if="element.project_user_deliverables && element.project_user_deliverables.length">
                                                <td class="py-6 h-full" v-for="(deliverable, i) in element.project_user_deliverables" :key="'pud' + i" @click="(e) => e.stopPropagation()">
                                                    <template v-if="deliverable.project_user_deliverable_column_names && deliverable.project_user_deliverable_column_names.length">
                                                        <div v-for="(columnName, o) in deliverable.project_user_deliverable_column_names" :key="'pudcn' + o">
                                                            <template v-if="columnName.project_user_deliverable_column_value && columnName.project_user_deliverable_column_value.data">
                                                                <div class="flex flex-wrap">
                                                                    <div class="w-full mb-2">
                                                                        <div v-if="columnName.project_user_deliverable_column_value.edit">

                                                                            <FormulateInput
                                                                                type="text"
                                                                                outer-class="w-full"
                                                                                :id="`input-${columnName.id}-${project.id}-${columnName.project_user_deliverable_column_value.id}`"
                                                                                element-class="w-full"
                                                                                wrapper-class="border rounded-md py-2 px-3 bg-white h-16 flex items-center"
                                                                                input-class="w-full focus:outline-none text-purple-m-secondary resize-none bg-transparent h-full"
                                                                                placeholder="empty"
                                                                                v-model="columnName.project_user_deliverable_column_value.data"
                                                                                @keyup.enter="$event.target.blur()"
                                                                                @blur="$emit('edit-user-column-value', columnName, project, true)"
                                                                            />

                                                                        </div>
                                                                        <base-button
                                                                            v-else
                                                                            @action="focusOnInput(columnName, project, true)"
                                                                            type="label"
                                                                            justify="start"
                                                                            rounded="lg"
                                                                            class="w-full text-left h-full px-6 hover:bg-tan-m button justify-start items-center gap-3 rounded-lg text-h6 font-base border focus:outline-none transition duration-150 ease-in-out flex w-36 w-auto border-transparent text-purple-m-main hover:text-purple-m-secondary focus:text-purple-m-secondary"
                                                                        >
                                                                            {{ columnName.project_user_deliverable_column_value.data != "" ? columnName.project_user_deliverable_column_value.data : "-" }}
                                                                        </base-button>

                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </td>
                                            </template>
                                        </template>
 -->
                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                                                        <!-- deliverables columns -->
                                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                                        <!-- !!!!!!!!!!!!!!!!!!ENDING!!!!!!!!!!!!!!!!!!!!!! -->


                                        <th class="w-12" @click="(e)=>e.stopPropagation()">
                                            <options-dropdown
                                                :options="[
                                                    {label: 'Edit', icon: 'pencil-alt', event:'edit', disabled: !isUserAdminOrHigher, disabledTooltip: tooltip},
                                                    {label: 'Duplicate', icon: 'duplicate', event: 'duplicate', separator: true, disabled: !isUserAdminOrHigher, disabledTooltip: tooltip},
                                                    {label: 'Delete', icon: 'trash', event: 'delete', theme: 'cancel', disabled: !isUserAdminOrHigher, disabledTooltip: tooltip},
                                                ]"
                                                @edit="$emit('user-deliverable-option', userDeliverable, 'edit', !!element.fixed_amount, element)"
                                                @duplicate="$emit('user-deliverable-option', userDeliverable, 'duplicate', null, element)"
                                                @delete="$emit('user-deliverable-option', userDeliverable, 'delete', null, element)"
                                                v-if="!hideDeliverableActions && isUserAdminOrHigher && !disabledProject"
                                            />
                                        </th>
                                    </tr>
                                    <tr class="h-16" v-if="!hideDeliverableActions && isUserAdminOrHigher">
                                        <td class="px-6">
                                            <base-button
                                                v-tooltip="!isUserAdminOrHigher ? tooltip : null"
                                                :disabled="!isUserAdminOrHigher"
                                                @action="$emit('add-deliverable', project ? project.id : null, element.id, !!element.fixed_amount, element)"
                                                type="label"
                                                icon="plus"
                                                label="Add Deliverable"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>

                    </tr>
                </tbody>
                <!--</tbody>-->

                <!-- Head -->
                <thead slot="header" v-if="!hideHeaders">
                <!-- table head row -->
                <tr 
                    class="border-b border-gray-m-light text-gray-headers bg-white" 
                    :class="`${stickyHeader ? 'sticky z-80 top-0' : ''}${headClass ? headClass : ``}`"
                >

                    <!-- selection checkbox -->
                    <th v-if="bulkActionsEnabled" class="">
                        <div class="flex justify-center items-center h-6 w-6 mx-3" style="margin-top: 3px">

                            <!-- This type of checkbox only appears when there is one or more elements selected
                            but not all filtered rows are selected yet -->
                            <base-button
                                v-if="(selectedElements.length > 0) && !allFilteredRowsSelected"
                                @action="addAllToSelectedElements"
                                type="label"
                                icon="minus-sm"
                                class="bg-gray-400"
                                style="color: white"
                            />

                            <!-- The normal checkbox appears when there are no elements selected or when all
                            filtered rows are selected, and its only checked when all filtered rows are selected  -->
                            <div class="">
                                <!-- <input
                                    v-if="(selectedElements.length == 0) || allFilteredRowsSelected"
                                    class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300"
                                    type="checkbox"
                                    :checked="allFilteredRowsSelected"
                                    @click="toggleSelectedElements"
                                > -->
                                <FormulateInput
                                    type="checkbox"
                                    v-if="(selectedElements.length == 0) || allFilteredRowsSelected"
                                    :checked="allFilteredRowsSelected"
                                    @click="toggleSelectedElements"
                                />
                            </div>

                        </div>
                    </th>

                    <!-- bulkActions for selected elements -->
                    <template v-if="selectedElements.length > 0">

                        <th class="font font-medium text-gray-headers px-6 py-6 text-left">
                            {{selectedElements.length}} selected
                        </th>

                        <!-- bulkActions empty state -->
                        <div v-if="!bulkActions.length" class="p-4 w-full text-md font-semibold">
                            No bulk actions to run
                        </div>

                        <!-- bulkActions list -->
                        <th v-else :colspan="columns.length">

                            <div v-for="(action, index) in bulkActions" :key="'ba_' + index" class="w-full h-full">

                                <!-- assign to board action -->
                                <div v-if="action.name == 'boardAssign'" class="flex gap-x-4">

                                    <!-- board selector -->

                                    <div class="w-56">
                                        <!-- 
                                        not using boards functionality right now
                                        if we ever want to reactivate this functionlaity, create a new component for this
                                        we used to use crud-dropdown but not that component is only used for project statuses
                                        <crud-dropdown
                                            :value="{
                                                name: (selectedAssignableBoard && selectedAssignableBoard.name) ? selectedAssignableBoard.name : 'No Selected Board',
                                            }"
                                            :create="{
                                                route: '/api/boards',
                                                success: 'Board created successfully',
                                                error: 'Failed to create new board',
                                                warning: 'Board name required'
                                            }"
                                            :edit="{
                                                route: '/api/boards/',
                                                success: 'Board updated successfully',
                                                error: 'Failed to edit board',
                                                warning: 'Board name required',
                                                overrideEmit: true
                                            }"
                                            :delete="{
                                                deleteLabel: 'board',
                                                route: '/api/boards/',
                                                success: 'Board deleted successfully',
                                                error: 'Failed to delete board',
                                                overrideEmit: true
                                            }"
                                            :statuses="boards"
                                            @select="setSelectedAssignableBoard"
                                            @edit="postEditBoard"
                                            @delete="postDeleteBoard"
                                            @done="$emit('refresh-data')"
                                        >
                                        </crud-dropdown> -->
                                    </div>

                                    <!-- buttons -->
                                    <base-button
                                        @action="action.addToBoard(selectedAssignableBoard, selectedElements); selectedElements = []; selectedAssignableBoard = null"
                                        :disabled="!selectedAssignableBoard || selectedElements.length <= 0"
                                        size="xl"
                                        label="Add to selected board"
                                    />

                                    <base-button v-if="selectedBoard && selectedBoard.name"
                                        @action="action.removeFromBoard(selectedBoard, selectedElements); selectedElements = []; selectedAssignableBoard = null;"
                                        size="xl"
                                        type="secondary"
                                        label="Remove from current board"
                                    />

                                </div>

                                <!-- just show the select checkboxes, and handle the value elsewhere -->
                                <div v-if="action.name == 'select'" class="flex gap-x-4" />

                            </div>
                        </th>
                    </template>

                    <!-- normal state shows all column names -->
                    <template v-else>
                        <!-- pin column -->
                        <th
                            v-if="pinningEnabled"
                            class="px-6 py-6"
                        />

                        <!-- max-w-0 w-1/2 for columns that should grow -->
                        <th
                            v-for="(column, index) in columns"
                            :key="'col_' + index"
                            :class="`${!isDraggable && sorting && !(column.sort == false) ? 'cursor-pointer' : ''} px-6 py-6 ${column.hidden == true || column.header_hidden == true ? `hidden` : ``}`"
                            :style="`${column.options && column.options.thStyles ? column.options.thStyles : ``} `"
                            @click="() => !(column.sort == false) && !isDraggable ? setSort(column) : null"
                        >
                            <div class="flex items-center gap-x-2">
                                <div class="text-left font-bold text-h6 overflow-hidden whitespace-no-wrap">
                                    {{ column.label }}
                                    <span class="text-xs font-normal" v-if="column.help">{{ column.help }}</span>
                                </div>

                                <Sort
                                    v-if="!(column.sort == false) && column.label !== '' && !isDraggable && sorting"
                                    :sort="sort"
                                    :col="column.name"
                                />
                            </div>
                        </th>
                        
                        <!-- 

                        project columns
                        we save the column names at the project_user level so we can just grab the first one
                        we nest them that way because the values are stored that way and I want to retain how sequelize
                        sends the data rather having to restructure it because I am eventually going to rewrite base table
                        it's really shitty code and not at all what I had envisioned. 
                        I just don't have time to rewrite the whole entire thing right now
                        We will especially need to rewrite a lot of this if we ever want things like 
                        draggable columns. I don't think it's a huge priority though as I'm more focused 
                        on just getting things non-glitchy and functional
                        
                        -->
                        <template v-if="showProjectColumns && project.project_user_column_names && project.project_user_column_names.length > 0 ">
                            <th class="w-56 px-6 text-left" v-for="(column_name, i) in project.project_user_column_names" :key="'spc_' + i">
                                <div class="inline-flex gap-2 items-center justify-start w-full">
                                    {{column_name.name}}
                                    <options-dropdown
                                        theme="cancel"
                                        :options="[
                                            ...(!(i == project.project_user_column_names.length-1) ? [{label: 'Move right', icon: 'chevron-double-right', event: 'right'}] : []),
                                            ...(!(i == 0) ? [{label: 'Move left', icon: 'chevron-double-left', event: 'left'}] : []),
                                            {label: 'Edit', icon: 'pencil-alt', event: 'edit', separator: true},
                                            {label: 'Delete', icon: 'trash', event: 'delete', theme: 'cancel'}
                                        ]"
                                        @right="$emit('move-column', column_name, 'right', i)"
                                        @left="$emit('move-column', column_name, 'left', i)"
                                        @delete="$emit('delete-column', column_name, project)"
                                        @edit="$emit('edit-column', column_name)"
                                    />
                                </div>
                            </th>
                            
                        </template>

                        <th class="w-16 px-6 text-center">
                            <base-button @action="$emit('add-column', project)" type="label" :icon-size="4" icon="plus" v-if="(!isGuest && !isClient) && !disabledProject && addColumnEnabled"/>
                        </th>

                        <!--row actions -->
                        <th
                            v-if="rowActionsEnabled"
                            class="px-6 py-6 border-b border-gray-m-light"
                        >
                            <div></div>
                        </th>

                    </template>
                </tr>
                </thead>
            </draggable>

            <!-- Empty State -->
            <div v-if="(!data.length || !filteredRows.length) && !loading" class="p-4 w-full text-md text-center text-purple-m-main font-semibold">
                {{ emptyText }}
            </div>

            <div class="flex gap-2 mb-8 cursor-pointer font-bold justify-center" @click="$emit('add-row')" v-if="allowAdd && $listeners['add-row'] !== undefined">
                <base-icon name="plus"/>
                <div>{{ addRowLabel }}</div>
            </div>
       </div>

       <slot name="table-footer"></slot>

       <div v-if="(hasExternalSrc || internalPagination) && total > 0" class="flex justify-between items-center py-4 mx-12 sm:mx-8">
           <div v-if="!showingAllPaginated">Showing {{ start }} to {{ end }} of {{ total }} results.</div>
           <div v-else>Showing {{ total }} results</div>
           <div v-if="allowShowAll && (filteredRows.length !== total || showingAllPaginated)" class="cursor-pointer font-bold text-xs" @click="showingAllPaginated = !showingAllPaginated">{{  showingAllPaginated ? 'Collapse' : 'Show all' }}</div>
           <div class="flex justify-center">
                <base-button v-show="!showingAllPaginated" icon="chevron-left" size="xs" type="secondary" rounded="0" :disabled="page === 1" class="border border-r-0 border-gray-300 rounded-l-xl" @action="alterPage(-1)"/>
                <base-button v-show="!showingAllPaginated" icon="chevron-right" size="xs" type="secondary" rounded="0" :disabled="page === lastPage" class="border border-gray-300 rounded-r-xl" @action="alterPage(1)"/>
           </div>
       </div>
   </div>
</template>

<script>
import Sort from './Sort';
import AvatarList from './AvatarList';
import Tags from './Tags.vue';
import Networks from './Networks.vue';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import StarRating from 'vue-star-rating';
import BoostedStatBreakdownTooltip from './BoostedStatBreakdownTooltip.vue';
import { isArray, isValidArray, isValidObject, isValidString, isNumber } from '../common'; 
import BaseTableColumnFeaturedPosts from './BaseTableColumnFeaturedPosts.vue';
import BaseTableColumnAdditionalInfo from './BaseTableColumnAdditionalInfo.vue';
import BaseTableColumnNetworkSelector from './BaseTableColumnNetworkSelector.vue';
import BaseTableColumnUserImportableStatus from './BaseTableColumnUserImportableStatus.vue';
import BaseTableColumnLongText from './BaseTableColumnLongText.vue';
import BaseTableColumnHandle from './BaseTableColumnHandle.vue';
import BaseTableColumnPostShowType from './BaseTableColumnPostShowType.vue';
export default {
    props: {
        invalidRows: {
            type: Set,
            default: () => new Set(),
        },
        loadingRows: {
            type: Array
        },
        addColumnEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        onRowClick: {
            type: Function,
            required: false
        },
        rowActions: {
            type: Array,
            required: false
        },
        fixedRowActions: {
            type: Boolean,
            required: false,
            default: false
        },
        bulkActions: {
            type: Array,
            required: false
        },
        loading: {
            type: Boolean,
            required: false
        },
        reload: {
            type: Boolean,
            required: false
        },
        hideHeaders: {
            type: Boolean,
            required: false
        },
        hideDeliverableActions: {
            type: Boolean,
            required: false,
            default: false
        },
        disableProjectRedirect: {
            type: Boolean,
            required: false,
            default: false
        },
        disableDeliverableRedirect: {
            type: Boolean,
            required: false,
            default: false
        },
        hideChildHeaders: {
            type: Boolean,
            required: false
        },
        pinnedElements: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        initialSelectedElements: {
            type: Array,
            required: false,
        },
        options: {
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        searchbarStyles: {
            type: String,
            required: false
        },
        search: {
            type: Boolean,
            default: true
        },
        filtering: {
            type: Boolean,
            default: true
        },
        emptyText: {
            type: String,
            default: 'No results found'
        },
        childEmptyText: {
            type: String,
            default: 'No results found'
        },
        addRowLabel: {
            type: String,
            default: 'Add row'
        },
        addChildLabel: {
            type: String,
            default: 'Add row'
        },
        allowAdd: {
            type: Boolean,
            default: true
        },
        allowAddChild: {
            type: Boolean,
            default: true
        },
        page: {
            type: Number,
        },
        perPage: {
            type: Number,
        },
        total: {
            type: Number
        },
        defaultFilters: {
            type: Object,
            default: function () {
                return {};
            }
        },
        overflow: {
            type: Boolean,
            default: false
        },
        tableId: {
            type: String,
            default: 'base-table'
        },
        actionsPosition: {
            type: String,
            default: 'bottom'
        },
        containerId: {
            type: String,
            default: 'base-table-container'
        },
        containerStyles: {
            type: String,
            default: ''
        },
        tabs: {
            type: Array,
            default: function () {
                return [];
            }
        },
        networks: {
            type: Array,
            default: function () {
                return [];
            }
        },
        currentTab: {
            type: String
        },
        searchClass: {
            type: String,
            default: ''
        },
        tableClass: {
            type: String,
            default: 'min-w-full'
        },
        headClass: {
            type: String,
            default: ''
        },
        stickyHeader: {
            type: Boolean,
            default: false
        },
        childColumns: {
            type: Array,
            default: function () {
                return [];
            }
        },
        childAttribute: {
            type: String
        },
        project: {
            type: [Object, String]            
        },
        propSort: {
            type: Object,
            default: function () {
                return null;
            }
        },
        isDraggable: {
            type: Boolean,
            default: false
        },
        internalPagination: {
            type: Boolean,
            default: false
        },
        allowShowAll: {
            type: Boolean,
            default: false
        },
        showProject: {
            type: Boolean,
            default: false
        },
        disabledProject: {
            type: Boolean,
            default: false
        },
        sorting: {
            type: Boolean,
            default: true
        },
        pinnedUser: {
            type: Number,
            default: 0,
        },
        toggledElement: {
            type: Object,
            default: function () {
                return null;
            }
        }
    },
    components: {
        Sort,
        AvatarList,
        Tags,
        Networks,
        StarRating,
        BoostedStatBreakdownTooltip,
        BaseTableColumnFeaturedPosts,
        BaseTableColumnAdditionalInfo,
        draggable,
        BaseTableColumnNetworkSelector,
        BaseTableColumnUserImportableStatus,
        BaseTableColumnLongText,
        BaseTableColumnHandle,
        BaseTableColumnPostShowType
    },
    data() {
        return {

            selectedElements: [],

            selectedAssignableBoard: null,

            sort: {
                column: '',
                asc: true
            },

            filters: {
                search: {
                    type: 'string',
                    selected: ''
                },
            },

            showingAllPaginated: false,

            refreshKey: true,
            savingUserColumn: false,

            tooltip: {
                content: 'Collaborators are not allowed to perform this action.',
                classes: 'bg-black text-white rounded py-1 mb-2 mr-16 px-2 text-xs',
                delay: {
                    show: 100,
                    hide: 50
                }
            },

            rendered: false,

            isArray,
            isValidString,
            isValidArray,
            isValidObject,
            isNumber
        }
    },
    mounted(){
        this.setFilters();
        if (this.columns.length) {
            let column = this.columns[0].name;
            let asc = null;
            this.setSort(column, false, asc);
        }
        this.initialSelectedElements ? this.selectedElements = this.initialSelectedElements : null;
        this.runPinned();

    },
    computed: {
        ...mapGetters(['boards', 'selectedBoard', 'statuses', 'statusesLoading', 'isAdminOrHigher', 'isProjectManager', 'isUserAdminOrHigher', 'isGuest', 'isClient']),

        rowClass() {

          return (element) => {
            const invalidRowClass = element.target_column && this.invalidRows.has(element.target_column.name) ? 'bg-red-100 border-l-4 border-red-500 text-red-700 p-4' : '';
            return `draggable-item relative h-15 ${invalidRowClass}`;
          };
        },

        start() {
            if (this.hasExternalSrc || this.internalPagination) {
                return ((this.page - 1) * this.perPage) + 1;
            }
            return 1;
        },

        end() {
            if (this.hasExternalSrc || this.internalPagination) {
                let end = this.page * this.perPage;
                if (end > this.total) {
                    return this.total;
                }
                return end;
            }
            return 10;
        },

        lastPage() {
            let pages = this.total / this.perPage;
            return parseInt(Math.ceil(pages));
        },

        hasExternalSrc() {
            return this.perPage !== undefined && this.page !== undefined && this.total !== undefined && !this.internalPagination;
        },

        showProjectColumns(){
            return this.project !== undefined;
        },

        showDeliverableColumns(){
            return this.project !== undefined && this.project.columns !== undefined;
        },
        
        filteredRows: {
            get() {
                let start = this.internalPagination && this.page ? ((this.page - 1) * this.perPage) : 0;
                let end = this.internalPagination && this.page ? (start + this.perPage) : this.data.length;
                let data = this.data.map(element => {
                    
                    if(this.pinningEnabled){
                        element.isPinned = this.pinnedElements.includes(element.id)
                    }

                    return element;

                }).filter(element => {
                    if (this.hasExternalSrc || this.isDraggable) {
                        return true;
                    }
                    let errors = 0;

                    // iterate through all available filters to see if this element passes

                    for (const key in this.filters) {

                        const filter = this.filters[key]

                        let searchTerm = ''

                        // searchbar filter
                        // these checks on all available columns
                        if (filter.type === 'string') {

                            searchTerm = filter.selected.trim().toLowerCase();

                            let found = 0

                            // compare the term to each column's value
                            this.columns.forEach(column => {

                                let value = this.getValue(column, element)

                                if (value) {

                                    // if the column has property searchBy we use that value
                                    if (column.searchBy) {
                                        value = column.searchBy(element)
                                    } else {

                                        // if the value is a string, number or array of strings, we use that, anything else its ignored (doesnt apply the filter)
                                        if ((Array.isArray(value) && typeof value[0] === 'string') || typeof value === 'string' || typeof value === 'number') {
                                            value = value.toString().toLowerCase()
                                        } else {
                                            value = ''
                                        }

                                    }

                                    // check if the term was found on this column
                                    if ((value !== undefined && value.includes(searchTerm))) {
                                        found++;
                                    }

                                }

                            })
                            // if the term was not found on any column, this element doesnt pass
                            if (!found) {
                                errors++
                            }

                        }

                        // select filters,
                        // only checks on a single column
                        if (filter.type === 'select') {

                            searchTerm = filter.selected && filter.selected.value ? filter.selected.label.trim().toLowerCase() : ''

                            if (searchTerm !== '') {

                                // find this filter's column
                                let column = this.columns.find(column => column.name == key)

                                let value = this.getValue(column, element)

                                if (value) {

                                    // if the column has property searchBy we use that value
                                    if (column.searchBy) {
                                        value = column.searchBy(element)
                                    } else {

                                        // if the value is a string, number or array of strings, we use that, anything else its ignored (doesnt apply the filter)
                                        if ((Array.isArray(value) && typeof value[0] === 'string') || typeof value === 'string' || typeof value === 'number') {
                                            value = value.toString().toLowerCase()
                                        } else {
                                            value = ''
                                        }

                                    }

                                    // if the term was not found on this column, this element doesn't pass
                                    if (!(value !== undefined && value.includes(searchTerm))) {
                                        errors++;
                                    }

                                }

                            }
                        }

                    }

                    return !errors;

                }).sort((a, b) => {
                    if (this.hasExternalSrc || this.isDraggable || !this.sorting) {
                        return 0;
                    }

                    let columnName = this.sort.column;

                    let column = this.columns.find(column => column.name == columnName);

                    // if no column found, a = b
                    if (!column) {
                        return 0
                    }

                    if (a.isPinned === b.isPinned) {

                        // in the case of budget, sorting is in reverse
                        if (columnName === 'budget') {
                            if (this.sort.asc) {
                                return a[columnName] - b[columnName];
                            }
                            return b[columnName] - a[columnName];
                        }

                        let aValue = this.getValue(column, a, true);
                        let bValue = this.getValue(column, b, true);

                        if (this.sort.asc) {
                            return ('' + aValue).localeCompare(bValue)
                        }
                        return ('' + bValue).localeCompare(aValue)
                    }
                    return b.isPinned - a.isPinned;
                });
                
                if (this.showingAllPaginated) {
                    return data;
                }
                return data.slice(start, end);
            },
            set(value) {
                this.$emit('update-values', value);
            }
        },
        allFilteredRowsSelected(){
            let errors = 0;
            this.filteredRows.forEach(filteredRow => {
                if(!this.selectedElements.includes(filteredRow.id)){
                    errors++
                }
            });
            return !errors 
        },
        rowActionsEnabled(){
            return this.rowActions && this.rowActions.length
        },
        bulkActionsEnabled(){
            return this.bulkActions && this.bulkActions.length
        },
        pinningEnabled(){
            return !!this.pinnedElements.length;
        }        
    },
    methods: {

        onDragStart(){
            this.showingAllPaginated = true
        },

        focusOnInput(columnName, project, isDeliverableColumn = false) {
            this.$emit('toggle-project-user-column-value-edit', columnName, project, isDeliverableColumn);
            this.$nextTick(() => {

                let idName = `input-${columnName.id}-${project.id}`;
                idName += ( isDeliverableColumn ) ? `-${columnName.project_user_deliverable_column_value.id}` : '';

                console.log('idName', idName);
                
                // this is so the input focuses upon the click action
                // had to use raw javascript for it to work...
                const inputElement = document.getElementById(idName);
                if (inputElement) {
                    inputElement.focus();
                }

            });
        },

        formatData(data) {
            if (!data) {
                return data;
            }

            const platforms = Object.keys(data);
            let output = '<div style="display: flex; flex-wrap: wrap;">';

            platforms.forEach((platform, index) => {
                output += '<div style="margin-right: 10px;">';
                const types = Object.keys(data[platform]);
                types.forEach(type => {
                    output += `<p>${type.charAt(0).toUpperCase() + type.slice(1)}:</p>`;

                    const details = Object.keys(data[platform][type]);
                    details.forEach(detail => {
                        output += `<p>&nbsp;&nbsp;${detail.charAt(0).toUpperCase() + detail.slice(1)}: ${data[platform][type][detail]}</p>`;
                    });
                });
                output += '</div>';

                if (index < platforms.length - 1) {
                    output += '<div style="align-self: center; margin: 0 10px;">----</div>';
                }
            });

            output += '</div>';
            return output;
        },

        toggleCheckbox(element, column, val){
            this.$emit('toggle-checkbox', element, column, val);
        },

        selectCheck(element, column, val) {
            this.$emit('change-single-select', element, column, val);
        },

        isInteger(value) {
            return Number.isInteger(parseInt(value, 10));
        },

        calculateCompletion(deliverable) {
            let value = '';
            // This is a concept waiting for approval
            if ( deliverable.phases_progress.code < 12 ) {
                // console.log('concepts',deliverable.project_user_deliverable_versions);
                if(isValidArray(deliverable.project_user_deliverable_versions)){
                    value = deliverable.project_user_deliverable_versions.filter(obj => obj.type == 1).length + '/' + deliverable.posts_needed;
                }   

            // This is a draft waiting for approval
            } else if (deliverable.phases_progress.code < 30 && deliverable.phases_progress.code >= 12 ) {
                // console.log('drafts',deliverable.project_user_deliverable_versions);
                if(isValidArray(deliverable.project_user_deliverable_versions)){
                    value = deliverable.project_user_deliverable_versions.filter(obj => obj.type == 2).length + '/' + deliverable.posts_needed;
                }
            // This is means we're in the linking post phase
            } else if (deliverable.phases_progress.code == 30) {
                value = deliverable.project_user_deliverable_posts.length + '/' + deliverable.posts_needed

            } 

            return value;
        },

        copyText(string, message) {
            this.$copyToClipboard(this.$notify, string, message);
        },

        setFilter() {
            if (this.hasExternalSrc || this.internalPagination) {
                let filters = {};
                for (let i in this.filters) {
                    let filter = this.filters[i];
                    if (filter.selected !== null && filter.selected !== '') {
                        if (filter.selected.value !== undefined && filter.selected.value !== null) {
                            filters[i] = filter.selected.value;
                        } else {
                            if (filter.selected.value === undefined) {
                                filters[i] = filter.selected;
                            }
                        }
                    }
                }
                
                if(Object.keys(this.filters).length === 1 && this.filters.search){
                    this.$emit('search', this.filters.search.selected)
                }
                this.$emit('changePage', 1, false);
                this.$emit('reload-filters', filters);
            }
        },

        alterPage(add) {
            let page = this.page;
            if (this.page > 1 && add === -1) {
                page--;
            }

            if (this.page < this.lastPage && add === 1) {
                page++;
            }

            this.setPage(page);
        },

        setPage(page) {
            this.$emit('changePage', page);
        },

        runScroll() {
            setTimeout(() => {
                let hasScroll = false;
                let tbody = document.getElementById(this.tableId);
                if (tbody) {
                    hasScroll = tbody.scrollHeight > tbody.clientHeight;

                    if (hasScroll) {
                        let container = document.getElementById(this.containerId);
                        if (container) {
                            container.scroll(container.scrollLeft, tbody.scrollHeight - tbody.clientHeight);
                        }
                    }
                }
            }, 150);
        },

        setAltImg (e) {
            e.target.src = require('../assets/images/default-picture.png');
        },

        helpIsNumber(element) {
            return !window.isNaN(element);
        },
        setRowActionsEvents(element){
            let obj = {}
            this.rowActions.forEach(action => {
                obj[action.event] = () => {
                    action.handler(element)
                }
            })
            return obj
        },

        async setFilters(){

            let columnsWithFilters = await this.columns.filter(element => element.filter);

            let searchSelected = '';

            if (this.defaultFilters.search !== undefined) {
                searchSelected = this.defaultFilters.search;
            }
            
            let filters = {
                search: {
                    type: 'string',
                    selected: searchSelected
                }
            };
            
            // filter options must have value & label
            await columnsWithFilters.forEach(column => {
                let options = column.filter.options
                filters[column.name] = {
                    options: [
                        { 
                            value: null, 
                            label: column.filter.allSelectedPlaceholder 
                        },
                        ...((options && options.length > 0) ? options : [])
                    ],
                    selected: null,
                    placeholder: column.filter.placeholder,
                    type: column.filter.type
                }

            })

            this.filters = this.filtering ? filters : [];

        },

        setSort(col, emit = true, asc = null) {
            let column_name = col.name;
            let changeColumn = false;
            
            this.sort.asc = !this.sort.asc;
            this.sort.column = column_name;
            changeColumn = true;

            if (asc != null) {
                this.sort.asc = asc;
            }

            this.sort.sort_type = col.sort_type || null;
            this.sort.es_column_name = col.es_column_name || null;
            this.sort.es_custom_sort = col.es_custom_sort || null;

            if (emit) {
                this.$emit('set-sort', this.sort, changeColumn);
            }
        },

        toggleSelectedElements() {
            if (this.selectedElements.length) {
                this.selectedElements = [];
            } else {
                this.filteredRows.forEach(element => {
                    this.selectedElements.push(element.id);
                });
            }
        },

        addAllToSelectedElements() {
            this.filteredRows.forEach(element => {
                if (!this.selectedElements.includes(element.id)) {
                    this.selectedElements.push(element.id)
                }   
            })
        },

        getValue(column, element, searchable = false){

            let value = ''
            // if value function is not specified on the column, we will search by the column name
            if(!column.value){
                value = element[column.name];
            }else{
                value = column.value(element);
            }
            // if we are looking for the searchable value, and there is a searchBy property on the
            // column, we will use that
            if(searchable && column.searchBy){
                value = column.searchBy(element);
            }

            return value;
        },

        cleanFilters() {
            for (let i in this.filters) {
                this.filters[i].selected = this.filters[i].type === 'string' ? '' : null;
            }
            this.refreshKey = !this.refreshKey;
        },

        filteredRowActions(element) {
            return this.rowActions.filter(action => {
                let should_show = true;
                if(action.if && typeof action.if == 'function'){
                    should_show = action.if(element);
                }
                return should_show;
            }).map(action => {
                // we will set the disabled property for the options dropdown,
                // by default this will be false, if the function exists we 
                // will run it to determine the property value
                let disabled = false;
                if(action.disabled && typeof action.disabled == 'function'){
                    disabled = action.disabled(element);
                }
                action.disabled = disabled;
                return action;
            });
        },

        setSelectedAssignableBoard(board) {
            this.selectedAssignableBoard = board;
        },

        postEditBoard(board) {
            this.$store.dispatch('boards/getBoards');
            if (this.selectedBoard && this.selectedBoard.id === board.id) {
                this.selectedBoard.name = board.editName
            }
        },

        postDeleteBoard(board) {
            this.$store.dispatch('boards/getBoards');
            if (this.selectedBoard?.id === board.id) {
                this.$store.dispatch('boards/setSelectedBoard', null);
            }
        },
        userDeliverables(project_user) {

            if ( project_user.project_user_deliverables ) {
                return project_user.project_user_deliverables;
            }

            if (this.project !== undefined && this.project.project_users !== undefined) {
                let user = this.project.project_users.find(user => user.id === project_user.id);
                if (user) {                    
                    return user.userDeliverables;
                }
                return [];
            }

            

            return project_user.userDeliverables ? project_user.userDeliverables : [];
        },

        getUserDeliverableAction(deliverable) {
            let label = "Pending";
            if ( deliverable.phases_progress.code == 11 ) {
                label = 'Concept Submitted';
            } else if ( deliverable.phases_progress.code == 10 || deliverable.phases_progress.code == 10 ) { 
                label = 'Add Concept';
            } else if ( deliverable.phases_progress.code == 12 ) {
                label = 'Add Draft';
            } else if ( deliverable.phases_progress.code == 21 || deliverable.phases_progress.code == 20 ) { 
                label = 'Draft Submitted';
            } else if ( deliverable.phases_progress.code == 30 ) {
                label = 'Link Post';
            } else if ( deliverable.phases_progress.code == 40 ) {
                label = 'Completed';
            } 

            return label;

        },

        deliverablePhasesProgress(deliverable){
            if (deliverable.is_completed) {
                return 'Completed';
            } else {
                switch(deliverable.phases_progress.code){
                    case 0:
                        return 'Concept Phase';
                    case 10:
                        return 'Draft Phase';
                    case 20:
                        if(deliverable.posts_needed != null & deliverable.posts_needed > 0 && deliverable.project_user_deliverable_posts != null){
                            if(deliverable.project_user_deliverable_posts.length < deliverable.posts_needed)
                                return 'Waiting For Posts';
                            else
                                return 'Completed'
                        }
                        else
                            return 'Completed';
                    default:
                        return 'Default Phase';
                }
            }
        },

        runPinned() {
            if (this.pinnedUser) {
                for (let i in this.project.users) {
                    if (this.project.users[i] && this.project.users[i].project_user) {
                        let isOpen = this.project.users[i].id === this.pinnedUser;
                        this.project.users[i].project_user.showUserDeliverables = isOpen;

                        if (isOpen) {
                            window.location.hash = `#user-${this.pinnedUser}`;
                        }
                    }
                }
            }
        },

        toggleShowUserDeliverables(element, e){
            e.stopPropagation();
            element.showUserDeliverables = !element.showUserDeliverables; 
            this.$forceUpdate()

            // save to local storage
            const item = 'expanded_project_users'
            let expandedProjectUsers = JSON.parse(localStorage.getItem(item)) || []
            if (element.showUserDeliverables){
                expandedProjectUsers.push(element.id)
            } else {
                expandedProjectUsers = expandedProjectUsers.filter(el => parseInt(el) !== parseInt(element.id))
            }
            localStorage.setItem(item, JSON.stringify(expandedProjectUsers))
            
        }
    },

    updated(){
        const body = document.getElementsByClassName('base-table-body')[0]
        if(body && this.data){
            if(this.rendered == false && ((body.children && body.children.length) || (body.children.length == this.data.length && this.data.length == 0))){
                this.rendered = true
                this.$emit('rendered')
            }
        }
    },

    watch: {

        // fires every time you select an element, so you can manage the selected elements elsewhere
        selectedElements: {
            handler: function () {
                let numberElements = [...this.selectedElements].map(element => {
                    return isNaN(element) ? element : parseInt(element);
                });
                let elements = this.data.filter(element => {
                    return this.selectedElements.includes(element.id) || numberElements.includes(element.id);
                });
                this.$emit('select-element', elements);
            }
        },

        toggledElement(val) {
            if (val && val.id) {
                this.selectedElements.push(val.id);
            }
        },

        columns: {
            deep: true,
            handler: function(val, old) {
                if (JSON.stringify(old) !== JSON.stringify(val)) {
                    this.setFilters();
                }
            }
        },

        // These watchers belong to the assignToBoard action:
        // If the global selected board or the boards array changes
        // we should reset the assignableBoard and selectedElements
        // to their initial state
        '$store.state.boards.selectedBoard': function() {
            this.selectedAssignableBoard = null;
            this.selectedElements = [];
        },
        '$store.state.boards.boards': {
            deep: true,
            handler: function (val, old) {
                if(val != old){
                    this.selectedAssignableBoard = null;
                }
            }
        }

    }
}
</script>

<style lang="scss" scoped>

    .invalid-row {
      background-color: #ffcccc; /* or any other color to highlight the row */
    }

    .sticky-table-cell{
        @apply sticky top-0 bottom-0 table-cell text-center align-middle;
        background: rgb(255 255 255 / 86%)
    }

    .flex-actions button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none; /* Prevent double borders */
    }
    .flex-actions button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px; /* Prevent double borders */
    }
    .comparition-table-item .comp-item:last-child:before {
        content: url('data:image/svg+xml,<svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path></svg>');
        width: 20px;
        height: auto;
        display: inline-block;
        margin-top: 0.4em;
    }
    .number-format{
        @apply relative;
        &:not(:hover){
            > .boosted-stat-breakdown-tooltip-container{
                @apply hidden;
            }
        }
    }
</style>
