<template>
    <base-modal 
        title="View Audience Data" 
        :visible="visible"
        @close="close()"
        styles="max-height: 90vh; overflow-y: auto; min-width: 80vw; width: 80vw;"
    >
        <div class="view-audience-data">

            <div v-if="!isValidObject(invitation)">Invitation not found</div>
            <div v-else-if="!isValidObject(invitation.merged_data)">This invitation has no generated audience data</div>
            <template v-else>

                <tab :visible="step == 1">
                    <div class="header">
                        <base-button
                            label="View raw data"
                            bold
                            @action="viewRawData()"
                        />
                    </div>
                    <div class="list">
                        <div
                            class="item"
                            v-for="(item, index) in filteredItems"
                            :key="`item-${index}`" 
                        >

                            <div class="title">{{ item.title }}</div>

                            <div class="data">
                                <div class="column">
                                    <div class="title">Values</div>
                                    <base-table 
                                        :data="item.key == 'audience_geo' ? getValue(item.key)['cities'] : getValue(item.key)"
                                        :columns="[
                                            {
                                                name: 'name',
                                                label: 'Name',
                                                value: (element) => {
                                                    return element.name || element.code
                                                }
                                            },
                                            {
                                                name: 'weight',
                                                label: 'Weight'
                                            }
                                        ]"
                                        :search="false"
                                        :sorting="false"
                                        table-class="bg-tan-m w-full"
                                        head-class="bg-tan-m"
                                    />
                                </div>
                                <div class="column">
                                    <div class="title">Chart</div>
                                    <component
                                        :is="item.component"
                                        :data="getValue(item.key)"
                                        :hide-see-more="true"
                                    />
                                </div>
                            </div>

                        </div>   
                    </div>
                    <div class="footer">
                        <div class="content">
                            <base-button
                                label="Close"
                                type="secondary"
                                @action="close()"
                            />
                            <base-button
                                label="Use this data"
                                bold
                                @action="step++"
                            />
                        </div>
                    </div>
                </tab>
                
                <tab :visible="step == 2">
                    <step-2
                        :user="user"
                        :invitation="invitation"
                        @back="step--"
                        @done="step++"
                        @reload="$emit('reload')"
                    />
                </tab>

                <tab :visible="step == 3">
                    <div class="done">
                        <base-icon 
                            class="text-green-m-main" 
                            name="check-circle"
                            :size="16" 
                        />
                        <div class="title">Success!</div>
                        <div class="subtitle">The creator audience data has been created successfully!</div>
                        <div class="description">You can view the new audience data on the "Stats" tab or on this creator's reports!</div>
                    </div>
                    <div class="footer">
                        <div class="content">
                            <base-button
                                label="Close"
                                type="secondary"
                                @action="close()"
                            />
                            <base-button
                                label="Done"
                                bold
                                @action="close()"
                            />
                        </div>
                    </div>
                </tab>

                <view-raw-data-modal
                    :visible="viewRawDataModalVisible"
                    :data="invitation.merged_data"
                    @close="viewRawDataModalVisible = false"
                />

            </template>
        </div>
    </base-modal>
</template>
<script>
    import GenderChart from '@/components/_charts/GenderChart.vue';
    import AgeChart from '@/components/_charts/AgeChart.vue';
    import AudienceGeoChart from '@/components/_charts/AudienceGeoChart.vue';
    import ViewRawDataModal from './ViewRawDataModal.vue';
    import { isValidArray, isValidString, isValidObject } from '@/common';
    import Step2 from './Step2.vue';
    const items = [
        {
            title: 'Audience Geo',
            key: 'audience_geo',
            component: 'audience-geo-chart'
        },
        {
            title: 'Audience Ages',
            key: 'audience_ages',
            component: 'age-chart'
        },
        {
            title: 'Audience Genders',
            key: 'audience_genders',
            component: 'gender-chart'
        },
    ];
    export default {
        name: 'ViewAudienceDataModal',
        props: ['visible', 'invitation', 'user'],
        components:{
            GenderChart,
            AgeChart,
            AudienceGeoChart,
            ViewRawDataModal,
            Step2
        },
        data() {
            return {
                step: 1,
                viewRawDataModalVisible: false,
                items,
                isValidObject
            }
        },
        computed: {            
            data(){
                return this.invitation && isValidObject(this.invitation.merged_data) ? this.invitation.merged_data : {}; 
            },
            filteredItems(){
                return this.items.filter(item => {
                    return this.isSet(item.key);
                });
            }
        },
        methods: {
            // key = 'audience_geo' || 'audience_ages' || 'audience_genders';
            getValue(key){
                return this.data[key];
            },
            // key = 'audience_geo' || 'audience_ages' || 'audience_genders';
            isSet(key){
                const value = this.getValue(key);
                if(key == 'audience_ages' || key == 'audience_genders'){
                    return isValidArray(value);
                }
                if(key == 'audience_geo'){
                    if(isValidObject(value)){
                        const { cities, countries } = value;
                        return isValidArray(cities) || isValidArray(countries);
                    } 
                }
                return false;
            },
            viewRawData(){
                this.viewRawDataModalVisible = true;
            },
            close(){
                this.step = 1;
                this.$emit('close');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .view-audience-data{
        @apply relative;
        // not a direct child because of tab components
        .header{
            @apply w-full flex justify-end items-center;
        }
        .list{
            @apply flex flex-col gap-y-4;
            > .item{
                @apply flex flex-col gap-y-6;
                @apply pt-3 pb-10;
                > .title{
                    @apply font-bold text-h4;
                }
                > .data{
                    @apply flex gap-x-16;
                    > .column{
                        @apply w-1/2 flex flex-col gap-y-4;
                        > .title{
                            @apply font-bold text-h6;
                        }
                    }
                }
            }
            > .item:not(:last-child) {
                @apply border-b border-gray-300;
            }
        }
        .done{
            @apply flex flex-col items-start text-center;
            > .title{
                @apply text-h4 font-bold;
            }
            > .subtitle{
                @apply mt-2 text-h5 font-bold;

            }
            > .description{
                @apply mt-2 mb-10;
            }
        }
        .footer{
            @apply sticky bottom-0;
            > .content{
                @apply w-full bg-tan-m;
                @apply py-6 mt-20;
                @apply flex justify-between items-center;
            }
        }
    }
</style>
