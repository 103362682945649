var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"title":"View Audience Data","visible":_vm.visible,"styles":"max-height: 90vh; overflow-y: auto; min-width: 80vw; width: 80vw;"},on:{"close":function($event){return _vm.close()}}},[_c('div',{staticClass:"view-audience-data"},[(!_vm.isValidObject(_vm.invitation))?_c('div',[_vm._v("Invitation not found")]):(!_vm.isValidObject(_vm.invitation.merged_data))?_c('div',[_vm._v("This invitation has no generated audience data")]):[_c('tab',{attrs:{"visible":_vm.step == 1}},[_c('div',{staticClass:"header"},[_c('base-button',{attrs:{"label":"View raw data","bold":""},on:{"action":function($event){return _vm.viewRawData()}}})],1),_c('div',{staticClass:"list"},_vm._l((_vm.filteredItems),function(item,index){return _c('div',{key:("item-" + index),staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"data"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title"},[_vm._v("Values")]),_c('base-table',{attrs:{"data":item.key == 'audience_geo' ? _vm.getValue(item.key)['cities'] : _vm.getValue(item.key),"columns":[
                                        {
                                            name: 'name',
                                            label: 'Name',
                                            value: function (element) {
                                                return element.name || element.code
                                            }
                                        },
                                        {
                                            name: 'weight',
                                            label: 'Weight'
                                        }
                                    ],"search":false,"sorting":false,"table-class":"bg-tan-m w-full","head-class":"bg-tan-m"}})],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"title"},[_vm._v("Chart")]),_c(item.component,{tag:"component",attrs:{"data":_vm.getValue(item.key),"hide-see-more":true}})],1)])])}),0),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"content"},[_c('base-button',{attrs:{"label":"Close","type":"secondary"},on:{"action":function($event){return _vm.close()}}}),_c('base-button',{attrs:{"label":"Use this data","bold":""},on:{"action":function($event){_vm.step++}}})],1)])]),_c('tab',{attrs:{"visible":_vm.step == 2}},[_c('step-2',{attrs:{"user":_vm.user,"invitation":_vm.invitation},on:{"back":function($event){_vm.step--},"done":function($event){_vm.step++},"reload":function($event){return _vm.$emit('reload')}}})],1),_c('tab',{attrs:{"visible":_vm.step == 3}},[_c('div',{staticClass:"done"},[_c('base-icon',{staticClass:"text-green-m-main",attrs:{"name":"check-circle","size":16}}),_c('div',{staticClass:"title"},[_vm._v("Success!")]),_c('div',{staticClass:"subtitle"},[_vm._v("The creator audience data has been created successfully!")]),_c('div',{staticClass:"description"},[_vm._v("You can view the new audience data on the \"Stats\" tab or on this creator's reports!")])],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"content"},[_c('base-button',{attrs:{"label":"Close","type":"secondary"},on:{"action":function($event){return _vm.close()}}}),_c('base-button',{attrs:{"label":"Done","bold":""},on:{"action":function($event){return _vm.close()}}})],1)])]),_c('view-raw-data-modal',{attrs:{"visible":_vm.viewRawDataModalVisible,"data":_vm.invitation.merged_data},on:{"close":function($event){_vm.viewRawDataModalVisible = false}}})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }